import { faFilter } from "@fortawesome/free-solid-svg-icons";
import PopupU from '../../../../../components/ui/PopupU';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import InputLabeled from '../../../../../components/form/InputLabeled';

const Filter = ({...props}) => {
    const { t } = useTranslation();

    const [query, setQuery] = useState({});
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    useEffect(() => {
        setQuery(props.query);
    }, [props.query]);

    useEffect(() => {
        setStartDate(query?.filter?.createdAtLb);
        setEndDate(query?.filter?.createdAtUb);
    }, [query]);

    const clearFilter = () => {
        props.setQuery(prev => {
            return {...prev, 
                filter: {...prev.filter, 
                    createdAtLb: '',
                    createdAtUb: ''
                }}
        })
    }

    const handleSubmit = () => {
        props.setQuery(prev => {
            return { ...prev,
                filter: { ...prev.filter,
                    createdAtLb: startDate,
                    createdAtUb: endDate,
                }
            }
        })
    }

    return (
        <PopupU className={'bg-gradient border-dark font-13'} icon={faFilter}>
            <div className="d-flex flex-column px-2 py-1">
                <div className="d-flex flex-row align-items-center justify-content-between" style={{width: '300px'}}>
                    <span className="bold font-18 fg-dark">{t('FILTER')}</span>
                    <span onClick={clearFilter} className="fg-red-300 font-14 hover-cursor"><u>{t('CLEAR')}</u></span>
                </div>
                <div className="d-flex flex-column">
                    <span className="font-14 medium fg-dark mt-2">{t('REGISTRATION_DATE')}</span>
                    <div className="d-flex flex-row align-items-center">
                        <InputLabeled type={'date'} value={startDate} setValue={setStartDate}/>
                        <span className="mx-2">-</span>
                        <InputLabeled type={'date'} value={endDate} setValue={setEndDate}/>
                    </div>
                </div>
                <span onClick={handleSubmit}
                    className="btn button-u border border-primary medium font-14 fg-blue-500 mt-4">
                    {t('APPLY')}
                </span>
            </div>
        </PopupU>
    );
}

export default Filter;