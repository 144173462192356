import ReactApexChart from 'react-apexcharts';
import { useTranslation } from "react-i18next";

const InExAreaChart = ({...props}) => {
  const { t } = useTranslation();

  const series = [
    {
      name: t('INCOME'),
      data: props?.seriesTotals || []
    },
    {
      name: t('EXPENSE'),
      data: props?.seriesTotals2 || []
    }
  ]

  const options = {
    chart: {
      type: 'area',
      height: 350,
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    
    title: {
      text: t('INCOME_EXPENSE_BY_MONTH'),
      align: 'left',
      style: {
        color:  '#505050'
      },
    },
    subtitle: {
      text: `${t('TOTAL_DAILY_INCOME_EXPENSE')} (${t('QUANTITY')})`,
      align: 'left',
      style: {
        color: '#505050'
      },
    },
    labels: props?.seriesDates || [],
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      opposite: true
    },
    legend: {
      horizontalAlign: 'left'
    }
  };


  return (
    <div className={props.className} id="chart">
      <ReactApexChart options={options} series={series} type="area" height={300} className={'shadow-sm bg-white'}/>
    </div>
  );
}

export default InExAreaChart;