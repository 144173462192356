import AxiosHelper from '../api/AxiosHelper';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth, setReady, setIsAdmin } = useAuth();

    const refresh = () => {
        AxiosHelper.refresh().then((res) => {
            if (res.status == 200) {
                const auth = res.data;
                const role = auth.role;
                setAuth(prev => {
                    return {...prev, ...auth }
                });
                setIsAdmin(role.code == '1000');
                setReady(true);
            }
        }).catch((error) => {
            setAuth(prev => {
                return {...prev, token: 'Unauthorized' }
            });
            setReady(false);
        });
    }

    return refresh;
}

export default useRefreshToken;