import { Link, useLocation } from "react-router-dom"
import InputField from "../../../../../../components/form/InputField";
import { faArrowRotateRight, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Filter from './../components/Filter'
import ButtonU from "../../../../../../components/ui/ButtonU";

const StockProductsNavBar = ({...props}) => {
    const location = useLocation();
    const { t } = useTranslation();

    const isActive = (link) => {
        return location.pathname.split('/')[3] === link.split('/')[3];
    }

    return (
        <div className="nav-bar-stock-products" id="nav-bar-stock-products">
            <div className="nav-list">
                { props?.items.map((item) => {
                    return(
                        <Link to={item.link} className={"nav-item"} key={item.id}>
                            <span className={"nav-link " + (!isActive(item.link) && 'collapsed')} data-bs-target={`#${item.id}`} data-bs-toggle="collapse">{item.name}</span>
                        </Link>
                    )
                }) }
            </div>
            <span></span>
            <div className="d-flex flex-row align-items-center">
                <ButtonU onSubmit={props.refresh} fg={''} className={'border-dark fg-dark py-1 px-2'} icon={faArrowRotateRight}/>
                <InputField type={"text"} value={props.find} setValue={props.setFind} hint={`${t('PRODUCT')} ${t('NAME')}, ${t('BAR_CODE')}`} icon={faSearch} formClass="input-field-container font-13" inputClass="input-field-outline"/> 
                <Filter query={props.query} setQuery={props.setQuery} />
            </div>
        </div>
    );
}

export default StockProductsNavBar;