import { useTranslation } from "react-i18next";

const TableGeneralReport = ({...props}) => {
    const { t } = useTranslation();

    const rows = props.rows;
    const totals = props.totals;

    return (
        <div className="bg-gray-50 p-2">
            <div id="printSection" className="table-wrapper">
                <div style={{width: "800px"}}>
                    <div className="table-container">
                        <div className="d-flex flex-row align-items-center justify-content-around">
                            <div className="d-flex flex-column align-items-center">
                                <span className="bold font-12">{t('REPORTS').toUpperCase()}</span>
                                <span className="bold">{`${props?.year} - ${props?.months.get(props?.month)}`}</span>
                            </div>
                        </div>
                        <table className='table-classic'>
                            <thead>
                                <tr>
                                    <th rowSpan={2} className="text-center">No</th>
                                    <th rowSpan={2} className="text-center">{t('NAME')}</th>
                                    <th rowSpan={2} className="text-center">{t('UNIT')}</th>
                                    <th rowSpan={2} className="text-center">{t('UNIT_PRICE')}</th>
                                    <th colSpan={2} className="text-center">{t('REMAINDER')}</th>
                                    <th colSpan={2} className="text-center">{t('INCOME')}</th>
                                    <th colSpan={2} className="text-center">{t('EXPENSE')}</th>
                                    <th colSpan={2} className="text-center">{t('REMAINDER')}</th>
                                </tr>

                                <tr>
                                    <th className="text-center">{t('QUANTITY')}</th>
                                    <th className="text-center">{t('TOTAL')}</th>
                                    <th className="text-center">{t('QUANTITY')}</th>
                                    <th className="text-center">{t('TOTAL')}</th>
                                    <th className="text-center">{t('QUANTITY')}</th>
                                    <th className="text-center">{t('TOTAL')}</th>
                                    <th className="text-center">{t('QUANTITY')}</th>
                                    <th className="text-center">{t('TOTAL')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item?.product?.name}</td>
                                            <td>{item?.product?.unit?.shortName}</td>
                                            <td>{item?.unitPrice?.toFixed(2)}</td>
                                            <td>{item?.remainderStart}</td>
                                            <td>{item?.remainderStartTotalUnitPrice?.toFixed(2)}</td>
                                            <td>{item?.income}</td>
                                            <td>{item?.incomeTotalUnitPrice?.toFixed(2)}</td>
                                            <td>{item?.expense}</td>
                                            <td>{item?.expenseTotalUnitPrice?.toFixed(2)}</td>
                                            <td>{item?.remainderEnd}</td>
                                            <td>{item?.remainderEndTotalUnitPrice?.toFixed(2)}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                            <tfoot>
                                <tr className="bold">
                                    <td colSpan={4}>{t('TOTAL')}</td>
                                    <td>{totals?.remainderStart}</td>
                                    <td>{totals?.remainderStartTotalUnitPrice?.toFixed(2)}</td>
                                    <td>{totals?.income}</td>
                                    <td>{totals?.incomeTotalUnitPrice?.toFixed(2)}</td>
                                    <td>{totals?.expense}</td>
                                    <td>{totals?.expenseTotalUnitPrice?.toFixed(2)}</td>
                                    <td>{totals?.remainderEnd}</td>
                                    <td>{totals?.remainderEndTotalUnitPrice?.toFixed(2)}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TableGeneralReport;