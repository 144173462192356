import useAuth from './useAuth';
import useAxiosPrivate from './useAxiosPrivate';

const useCheckExistenceOfPreviousMonthRecord = () => {
    const {ready} = useAuth();
    const axiosPrivate = useAxiosPrivate();

    const checkExistenceOfPreviousMonthRecord = async() => {
        if (!ready) return;
        const res = await axiosPrivate.post('/monthly-products-reports/check-existence-of-previous-month-record');
        if (res.status == 200)
            return res.data.found;
    }

    return checkExistenceOfPreviousMonthRecord;
}

export default useCheckExistenceOfPreviousMonthRecord;