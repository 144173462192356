import { useState, useEffect } from "react";
import useAuth from "../../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import useCheckExistenceOfPreviousMonthRecord from "../../../../../../hooks/useCheckExistenceOfPreviousMonthRecord";
import { useNavigate } from "react-router-dom";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Utils from "../../../../../../utils";
import ReportsWrapper from "../../ReportsWrapper";
import ButtonU from "../../../../../../components/ui/ButtonU";
import { useTranslation } from "react-i18next";
import Months from "../../../../../../components/ui/Months";
import CardCount from "./components/CardCount";
import InputLabeled from "../../../../../../components/form/InputLabeled";
import InputAreaLabeled from "../../../../../../components/form/InputAreaLabeled";
import ToastU from "../../../../../../components/ui/ToastU";
import icCalendar from "./../../../../../../assets/svg/ic_calendar.svg";

const CloseMonthlyReport = () => {
    const { t } = useTranslation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const checkExistenceOfPreviousMonthRecord = useCheckExistenceOfPreviousMonthRecord();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const MONTHS = Months();
    const [isPreviousMonthRecordExist, setIisPreviousMonthRecordExist] = useState();

    const productClassifications = JSON.parse(localStorage.getItem('productClassifications'));

    const [previousMonthDate, setPreviousMonthDate] = useState('');
    const [countStockProducts, setCountStockProducts] = useState([]);
    
    const [number, setNumber] = useState(Utils.genIdByDateTime());
    const [note, setNote] = useState();

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('MAIN'), link: '/' },
        { title: t('REPORTS'), link: '/reports/general' } ,
        { title: t('GENERAL'), link: '/reports/general'},
        { title: t('CREATE'), active: true}
    ]

    useEffect(() => {
        const checkPreviousMonthRecord = async() => {
            setIisPreviousMonthRecordExist(await checkExistenceOfPreviousMonthRecord());
        };
        checkPreviousMonthRecord();
    }, [checkExistenceOfPreviousMonthRecord]);

    useEffect(() => {
        if (ready && !isPreviousMonthRecordExist) {
            const {year, month} = Utils.getTimestampPreviousMonthDate(new Date());
            setPreviousMonthDate(`${year}-${MONTHS.get(month)}`)
            getCountStockProducts()
        }
    }, [ready, isPreviousMonthRecordExist]);
    
    const getCountStockProducts = async() => {
        const countStockProducts = await Promise.all(productClassifications.map(async (item) => {
            const query = { filter: { productClassificationId: item.id }};
            const res = await axiosPrivate.get('/count/stock-products', { params: query });
            if (res.status === 200) 
                return { productClassification: item, count: res.data };
        }));
        
        setCountStockProducts(countStockProducts);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const requiredInputs = [
            { key: number, value: t('NUMBER_REQUIRED') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        setLoading(true);
        const data = { number };

        axiosPrivate.post('/monthly-products-reports/create', data).then((res) => {
            if (res.status == 200) {
                setToast({ content: res.data.msg, variant: 'success', title: t('SUCCESS'), show: true });
                navigate(-1);
            }
        }).catch((err) => {
            if (!err?.response) setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {setLoading(false)});
    }

    return (
        <ReportsWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <div className="d-flex flex-row align-items-center">
                    <img src={icCalendar} className="wh-20 me-2"/>
                    <span className="fs-5 bold fg-dark">{previousMonthDate}</span>
                </div>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={handleSubmit} className={'bg-blue-900 bg-gradient text-white'} icon={faCheck} text={t('CLOSE_MONTHLY_REPORT')} disabled={isPreviousMonthRecordExist}/>
                </div>
            </div>
            <div className="mt-3"></div>
            {!isPreviousMonthRecordExist && <div className="row mt-5 mx-3">
                <InputLabeled label={t('NUMBER')} type={'text'} value={number} setValue={setNumber} hint={t('NUMBER')} className={'col-md-3'} required={true} disabled={isPreviousMonthRecordExist}/>
                <div className="col-md-12 mt-3"></div>
                <span className="font-20 text-secondary bold">{t('REMAINING_FROM_PREVIOUS_MONTH')}</span>
                <div className="mt-2">
                    {countStockProducts?.map((item, index) => {
                        return ( <CardCount key={index} item={item}/>)
                    })}
                </div>
                <InputAreaLabeled label={t('NOTE')} rows={3} value={note} setValue={setNote} hint={t('NOTE')} className={'col-md-6 mt-4'}/>
            </div>}
            <ToastU toast={toast} setToast={setToast}/>
        </ReportsWrapper>
    );
}

export default CloseMonthlyReport;