import { useEffect, useState } from "react";
import useAuth from '../../../../../../../../hooks/useAuth';
import useAxiosPrivate from '../../../../../../../../hooks/useAxiosPrivate';
import InputLabeled from "../../../../../../../../components/form/InputLabeled";
import InputAreaLabeled from "../../../../../../../../components/form/InputAreaLabeled";
import ModalU from '../../../../../../../../components/ui/ModalU';
import Utils from "../../../../../../../../utils";
import ToastU from "../../../../../../../../components/ui/ToastU";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarcode } from "@fortawesome/free-solid-svg-icons";
import SelectStockProduct from "../../../../components/SelectStockProduct";

const Edit = ({...props}) => {
    const { t } = useTranslation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    const requestFormItem = props.item;

    const [stockProducts, setStockProducts] = useState([]);
    const [selectedStockProduct, setSelectedStockProduct] = useState({});
    const [selectedStockProductId, setSelectedStockProductId] = useState('');
    const [quantity, setQuantity] = useState();
    const [description, setDescription] = useState('');

    const [query, setQuery] = useState({
        order: [['createdAt', 'ASC']], filter: { stock: 0, productId: '' }
    });

    useEffect(() => {
        clearFields();
        setQuery({
            ...query,
            filter: {...query.filter, productId: requestFormItem.productId }
        });
    }, [requestFormItem]);

    useEffect(() => {
        if (ready) {
            getStockProducts();
        }
    }, [query]);

    useEffect(() => {
        setSelectedStockProductId(requestFormItem?.stockProductId);
        setQuantity(requestFormItem?.quantity);
        setDescription(requestFormItem?.description);
    }, [requestFormItem]);

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});
   
    const editRequestFormItem = () => {
        const requiredInputs = [
            { key: quantity, value: t('QUANTITY_REQUIRED') },
            { key: (quantity <= selectedStockProduct?.stock), value: t('QUANTITY_CANNOT_EXCEED_PRODUCT_QUANTITY') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        const updatedItems = props.items.filter(item => item.productId !== requestFormItem.productId)
        requestFormItem.stockProductId = selectedStockProductId;
        requestFormItem.quantity = quantity;
        requestFormItem.unitPrice = selectedStockProduct?.unitPrice;
        requestFormItem.description = description;
        props.setItems(
            [...updatedItems, requestFormItem]
        );

        clearFields();

        props.setModalShow(false);
    }

    const clearFields = () => {
        setQuantity('');
        setDescription('');
    }

    const getStockProducts = () => {
        axiosPrivate.get('/stock-products', { params: query }).then((res) => {
            if (res.status == 200) {
                const data = res.data.data;
                setStockProducts(data);
                setSelectedStockProduct(data.find(stockProduct => stockProduct.id == requestFormItem?.stockProductId));
            }
        });
    }

    return (
        <ModalU show={props.modalShow} size={''} title={`${t('PRODUCT')} [${t('EDIT')}]`}
            actionbtntext={t('SAVE')}
            actionbtnclassname={"bg-green-700 font-14 medium text-white"}
            onSubmit={editRequestFormItem} onHide={props.setModalShow}>
            <div className='row mx-1'>
                <div className="d-flex flex-row justify-content-between font-16 mb-2">
                    <span className="fg-dark">{requestFormItem?.product?.name}</span>
                    <span className="fg-dark"><FontAwesomeIcon icon={faBarcode} className="me-2"/>{requestFormItem?.product?.barcode}</span>
                </div>
                <SelectStockProduct stockProducts={stockProducts} inline={false} value={selectedStockProductId} setValue={setSelectedStockProductId} setObject={setSelectedStockProduct} type={'radio'}/>
                <InputLabeled label={<span>{t('REQUESTED')} ({requestFormItem?.product?.unit?.name})</span>} type={'number'} value={requestFormItem?.requestedQuantity} className={'col-md-6'} bg={'bg-gray-50'} disabled={true}/>
                <InputLabeled label={<span>{t('SPENT')} ({requestFormItem?.product?.unit?.name})</span>} type={'number'} value={quantity} setValue={setQuantity} hint={"3"} className={'col-md-6'} required={true} disabled={!selectedStockProductId}/>
                <InputAreaLabeled label={t('DEFINITION')} rows={2} value={description} setValue={setDescription} hint={t('DEFINITION')} className={'col-md-12'}/>
            </div>
            <ToastU toast={toast} setToast={setToast}/>
        </ModalU>
    );
}


export default Edit