import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";

const FailedLogin = ({failedCount, countDown, setCountDown}) => {
    const { t } = useTranslation();

    const [endTime, setEndTime] = useState('');
    
    useEffect(() => {
        const savedEndTime = localStorage.getItem('timerEnd');
        if (savedEndTime) {
            const remainingTime = Math.floor((new Date(savedEndTime) - new Date()) / 1000);
            if (remainingTime > 0) setCountDown(remainingTime);
            else localStorage.removeItem('timerEnd');
        }
    }, []);

    useEffect(() => {
        if (countDown > 0) {
            const timer = setInterval(() => {
                const savedEndTime = localStorage.getItem('timerEnd');
                if (savedEndTime) {
                    const remainingTime = Math.floor((new Date(savedEndTime) - new Date()) / 1000);
                    if (remainingTime > 0) setCountDown(remainingTime);
                    else {
                        setCountDown(null);
                        localStorage.removeItem('timerEnd');
                        clearInterval(timer);
                    }
                }
                else clearInterval(timer);
            }, 1000);
            return () => clearInterval(timer);
        } else if (countDown == 0 && !endTime) setEndTime(new Date().toLocaleTimeString());
    }, [countDown, endTime]);
    
    return (
        <div className="d-flex flex-column">
            {(countDown && countDown != 0) && <div className="d-flex flex-column bg-red-50 rounded-1 mb-2 py-2 px-3">
                <div className="d-flex flex-column">
                    <div className="d-flex flex-row align-items-center">
                        <FontAwesomeIcon icon={faCircleInfo} className="fg-red-300 me-2"/>
                        <span className="fg-red-300 font-14 medium">{t('TOO_MANY_FAILED_LOGIN_ATTEMPTS')}</span>
                    </div>
                    <span className="fg-red-300 font-13">{t('PLEASE_WAIT_THEN_TRY_AGAIN')} {t('REMAINING_TIME')}: {countDown}</span>
                </div>
            </div>}
            {failedCount && <div className="d-flex flex-column bg-yellow-100 rounded-1 mb-2 py-2 px-3">
                <div className="d-flex flex-column">
                    <div className="d-flex flex-row align-items-center">
                        <FontAwesomeIcon icon={faCircleInfo} className="fg-yellow-500 me-2"/>
                        <span className="fg-yellow-500 font-14 medium">{t('FAILED_LOGIN')}: {failedCount}/3</span>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default FailedLogin