import { FormCheck } from "react-bootstrap";
import { useTranslation } from "react-i18next";


const SelectStockProduct = ({...props}) => {
    const { t } = useTranslation();
    const stockProducts = props.stockProducts;

    const handleSelectedOption = (event) => {
        const id = event.target.value
        props.setValue(id);
        props.setObject(stockProducts.find(stockProduct => stockProduct.id == id));
    }

    return (
        <div className="d-flex flex-column mb-2">
            <span className="font-14fg-dark medium">{t('CHOOSE')}</span>
            <hr className="mt-1 mb-0"/>
            <div className="d-flex flex-row justify-content-between bg-blue-500 text-white medium font-14 px-2">
                <div className="d-flex flex-row">
                    <span className="ms-4">No</span>
                    <span className="ms-4">{t('UNIT_PRICE')}</span>
                </div>
                <span className="">{t('STOCK')}</span>
            </div>
            {stockProducts?.map((stockProduct, index) => {
                return (
                    <div key={index} className={`d-flex flex-row justify-content-between font-14 ${props.value === stockProduct.id ? 'bg-blue-50' : 'bg-light'} px-2 py-1`}>
                        <div className="d-flex flex-row">
                            <FormCheck
                                id={stockProduct.id}
                                label={stockProduct.name}
                                value={stockProduct.id}
                                name={'stock_products'}
                                type={props.type}
                                checked={props.value === stockProduct.id}
                                onChange={handleSelectedOption}/>
                            <span className={'fg-dark medium ms-3 me-4'}>{index + 1}</span>
                            <span className={'fg-dark'}>{stockProduct?.unitPrice?.toFixed(2)}</span>
                        </div>
                        <span className={'fg-dark'}>{stockProduct.stock}</span>
                    </div>
                )
            })}
        </div>
    );
}

export default SelectStockProduct;