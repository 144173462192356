import ModalU from '../../../../../../components/ui/ModalU';
import Utils from "../../../../../../utils";
import { useTranslation } from "react-i18next";
import Keys from '../../../../../../utils/constants';
import PrettyJSON from '../../../../../../components/ui/PrettyJSON';

const Show = ({...props}) => {
    const { t } = useTranslation();
    const item = props.item;

    return (
        <ModalU show={props.modalShow} size={'lg'} title={t('LOG')} onHide={props.setModalShow}>
            <div className='d-flex flex-column bg-dark mb-1 p-2'>
                <div className="d-flex flex-column mt-2">
                    <div className="d-flex flex-row">
                        <code className="fg-blue-300 me-2 bold">{t('DATE')}:</code>
                        <code className="fg-yellow-300 bold">{Utils.getTimestampDate(item?.createdAt)}</code>
                    </div>
                    <div className="d-flex flex-row">
                        <code className="fg-blue-300 me-2 bold">{t('USER')}:</code>
                        <code className="fg-yellow-300 bold">{item?.user?.username}</code>
                    </div>
                    <div className="d-flex flex-row">
                        <code className="fg-blue-300 me-2 bold">{t('FULLNAME')}:</code>
                        <code className="fg-yellow-300 bold">{item?.user?.fullName}</code>
                    </div>
                    <div className="d-flex flex-row">
                        <code className="fg-blue-300 me-2 bold">{t('EVENT')}:</code>
                        <code className="fg-yellow-300 bold">{Keys.MODULES.get(item?.eventType) || item?.eventType}</code>
                    </div>
                    <div className="d-flex flex-row">
                        <code className="fg-blue-300 me-2 bold">{t('ACTION')}:</code>
                        <code className="fg-yellow-300 bold">{item?.actionType}</code>
                    </div>
                </div>
                <hr className='my-2 text-white' style={{"border-top": "dashed 2px"}}/>
                <code className='text-white medium font-14'>{t('DATA')}</code>
            </div>
            <div style={{"overflow-y": "auto", "maxHeight": "350px"}}>
                <PrettyJSON data={item?.dataEntry}/>
            </div>
        </ModalU>
    );
}

export default Show