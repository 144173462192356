import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateRight, faDownload, faMagnifyingGlass, faPrint } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import html2pdf from 'html2pdf.js';
import useAuth from "../../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import Utils from "../../../../../../utils";
import ReportsWrapper from "../../ReportsWrapper";
import TableGeneralReport from './../../General/components/TableGeneralReport';
import Filter from "./Filter";
import ButtonU from "../../../../../../components/ui/ButtonU";
import ToastU from "../../../../../../components/ui/ToastU";
import Months from "../../../../../../components/ui/Months";
import SelectMonth from "../../components/SelectMonth";
import SelectItem from "../../components/SelectItem";
import SelectProductClassification from "../../../Warehouse/components/SelectProductClassification";

const MonthlyReport = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(false);
    const MONTHS = Months();
    
    const productClassifications = JSON.parse(localStorage.getItem('productClassifications'));

    const [years, setYears] = useState([]);

    const [monthlyProductsReport, setMonthlyProductsReport] = useState({});
    const [rows, setRows] = useState([]);
    const [totals, setTotals] = useState({});

    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');

    const {year: currentYear, month: currentMonth} = Utils.getTimestampPreviousMonthDate(new Date());

    const parsed = queryString.parse(location.search);
    const urlQuery = {
        limit: 1, page: 1, order: [['createdAt', 'ASC']],
        filter: {
            productClassificationId: parsed.pc || '1',
            year: parsed.year || currentYear,
            month: parsed.month || currentMonth,
        }
    }
    const [query, setQuery] = useState(urlQuery);

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('MAIN'), link: '/' },
        { title: t('REPORTS'), link: '/reports/general' },
        { title: t('MONTHLY_REPORTS'), link: '/reports/monthly-reports' },
        { title: t('REPORT'), active: true}
    ]

    useEffect(() => {
        if (ready) {
            getUpdatedUrl();
            getSystemYears();
            getMonthlyProductsReport();
        }
    }, [ready, query]);

    const getUpdatedUrl = () => {
        parsed.pc = query.filter.productClassificationId;
        parsed.year = query.filter.year;
        parsed.month = query.filter.month;
        window.history.replaceState({}, '', `${location.pathname}?${queryString.stringify(parsed)}`);
    }

    const getSystemYears = () => {
        setLoading(true);
        axiosPrivate.get('/system/years', { params: query }).then((res) => {
            if (res.status == 200) {
                setYears(res.data);
            }
        }).finally(() => {setLoading(false)});
    }
 
    const getMonthlyProductsReport = () => {
        setLoading(true);
        axiosPrivate.get('/report-last-month', { params: query }).then((res) => {
            if (res.status == 200) {
                setMonthlyProductsReport(res.data.data);
                setRows(res.data.data.monthlyProductsReportItems);
                setTotals(res.data.totals);
            }
        }).catch((err) => {
            setRows([]);
            setTotals([]);
            if (!err?.response) {
                setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
                return;
            }
            switch(err.response.status) {
                case 404: setToast({ content: t('NOT_FOUND'), variant: 'warning', title: t('NOT_FOUND'), show: true }); break;
                default : setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true }); break;
            }
        }).finally(() => {setLoading(false)});
    }

    const refresh = () => { /*getMonthlyProductsReport()*/ }
    
    const handleFilterApply = () => {
        setQuery(prev => {
            return { ...prev, filter: { ...prev.filter, year, month } }
        })
    }
        
    const handlePrint = () => {
        const printContents = document.getElementById('printSection').innerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;  
        window.location.reload();
    }
    
    const handleDownloadPdf  = () => {
        const printContents = document.getElementById('printSection').innerHTML;
        const opt = {
            filename: `${MONTHS.get(Utils.getTimestampMonth(new Date()))}.pdf`,
            html2canvas: { scale: 4, useCORS: true },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' },
            pagebreak: {mode: ['avoid-all', 'css', 'legacy'] } // Prevent page breaks within
        };
        html2pdf().from(printContents).set(opt).save();
    }

    return (
        <ReportsWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <span className="bold fs-5 fg-dark">{t('REPORT')} - {t('LAST_MONTHS')}</span>
                <div className="d-flex align-items-center">
                    <SelectProductClassification items={productClassifications} query={query} setQuery={setQuery}/>
                    <ButtonU onSubmit={refresh} className={'border-dark fg-dark'} icon={faArrowRotateRight}/>
                    <Filter
                        years={years}
                        months={MONTHS}
                        year={year}
                        setYear={setYear}
                        month={month}
                        setMonth={setMonth}
                        query={query}
                        setQuery={setQuery} 
                    />
                </div>
            </div>
            <div className="mt-3 bg-dark-700 px-4 py-3">
                <div className="d-flex flex-row align-items-center justify-content-between bg-gray-500 bg-gradient px-2 py-1 rounded-1 mt-4 mb-1">
                    <div className="d-flex flex-row align-items-center">
                        <div className="d-flex flex-row align-items-center me-1">
                            <span className="text-white font-13 medium mx-2">{t('YEAR')}</span>
                            <SelectItem items={years} value={year} setValue={setYear} className={''}/>
                        </div>
                        <div className="d-flex flex-row align-items-center">
                            <span className="text-white font-13 medium mx-2">{t('MONTH')}</span>
                            <SelectMonth items={MONTHS} value={month} setValue={setMonth} className={''}/>
                        </div>
                        <FontAwesomeIcon onClick={handleFilterApply } icon={faMagnifyingGlass} className="font-18 fg-hover ms-2"/>
                    </div>
                    <div className="d-flex flex-row">
                        <FontAwesomeIcon onClick={handleDownloadPdf } icon={faDownload} className="font-18 fg-hover mx-1"/>
                        <FontAwesomeIcon onClick={handlePrint } icon={faPrint} className="font-18 fg-hover mx-1"/>
                    </div>
                </div>
                <TableGeneralReport
                    rows={rows}
                    totals={totals}
                    months={MONTHS}
                    year={query?.filter?.year}
                    month={query?.filter?.month}
                />
            </div>
            <div className="mb-5"></div>
            <ToastU toast={toast} setToast={setToast}/>
        </ReportsWrapper>
    );
}

export default MonthlyReport;