import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const Option = ({...props}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div onClick={() => navigate(props.link)} className="col-md-3 btn-hover my-2">
            <div className="d-flex flex-column justify-content-between rounded-2 border border-light shadow-sm btn-hover px-4 pt-4 pb-3" style={{height: '150px'}}>
                <div className="d-flex flex-row align-items-center">
                    <FontAwesomeIcon icon={props.icon} className={`font-14 text-white p-2 rounded-circle wh-15 ${props.bg}`}/>
                    <span className="medium font-16 fg-dark ms-2">{props.name}</span>
                </div>
                <span className="fg-dark font-13 mt-2">{props.description}</span>
                <div className="d-flex flex-column">
                    <div className="d-flex flex-row justify-content-between">
                        <span></span>
                        <FontAwesomeIcon icon={faChevronRight} className="rounded-circle fg-blue-500 p-2 bg-light wh-15"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Option;