import { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faDownload, faPrint, faTableCells } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import html2pdf from 'html2pdf.js';
import useAuth from "../../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import Utils from "../../../../../../utils";
import ReportsWrapper from "../../ReportsWrapper";
import TableGeneralReport from './../components/TableGeneralReport';
import ButtonU from "../../../../../../components/ui/ButtonU";
import ToastU from "../../../../../../components/ui/ToastU";
import Months from "../../../../../../components/ui/Months";
import SelectProductClassification from "../../../Warehouse/components/SelectProductClassification";

const CurrentMonth = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const MONTHS = Months();
    
    const productClassifications = JSON.parse(localStorage.getItem('productClassifications'));

    const [rows, setRows] = useState([]);
    const [totals, setTotals] = useState({});

    const parsed = queryString.parse(location.search);
    const urlQuery = {
        filter: { productClassificationId: parsed.pc || '1' }
    }
    const [query, setQuery] = useState(urlQuery);

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('MAIN'), link: '/' },
        { title: t('REPORTS'), link: '/reports/general' } ,
        { title: t('GENERAL'), link: '/reports/general' },
        { title: t('REPORT'), active: true}
    ]

    useEffect(() => {
        if (ready) {
            getUpdatedUrl();
            getRecords();
        }
    }, [ready, query]);

    const getUpdatedUrl = () => {
        parsed.pc = query.filter.productClassificationId;
        window.history.replaceState({}, '', `${location.pathname}?${queryString.stringify(parsed)}`);
    }
 
    const getRecords = () => {
        setLoading(true);
        axiosPrivate.get('/report-current-month', { params: query }).then((res) => {
            if (res.status == 200) {
                setRows(res.data.rows);
                setTotals(res.data.totals);
            }
        }).finally(() => {setLoading(false)});
    }
        
    const handlePrint = () => {
        const printContents = document.getElementById('printSection').innerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();
    }
    
    const handleDownloadPdf  = () => {
        const printContents = document.getElementById('printSection').innerHTML;
        const opt = {
            filename: `${MONTHS.get(Utils.getTimestampMonth(new Date()))}.pdf`,
            html2canvas: { scale: 4, useCORS: true },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' },
            pagebreak: {mode: ['avoid-all', 'css', 'legacy'] } // Prevent page breaks within
        };
        html2pdf().from(printContents).set(opt).save();
    }

    return (
        <ReportsWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <span className="bold fs-5 fg-dark">{Utils.getYear()}-{MONTHS.get(Utils.getMonth())}</span>
                <div className="d-flex align-items-center">
                    <SelectProductClassification items={productClassifications} query={query} setQuery={setQuery}/>
                    <ButtonU onSubmit={() => {navigate(`/reports/monthly-reports/report`)}} className={'bg-purple-400 bg-gradient text-white me-2'} icon={faTableCells} text={<span>{t('LAST_MONTHS')}<FontAwesomeIcon icon={faChevronRight} className="ms-2"/></span>}/>
                </div>
            </div>
            <div className="mt-3 bg-dark-700 px-4 py-3">
                <div className="d-flex flex-row align-items-center justify-content-between bg-gray-500 bg-gradient px-2 py-1 rounded-1 mt-4 mb-1">
                    <div className=""></div>
                    <div className="d-flex flex-row">
                        <FontAwesomeIcon onClick={handleDownloadPdf } icon={faDownload} className="font-18 fg-hover mx-1"/>
                        <FontAwesomeIcon onClick={handlePrint } icon={faPrint} className="font-18 fg-hover mx-1"/>
                    </div>
                </div>
                <TableGeneralReport
                    rows={rows}
                    totals={totals}
                    months={MONTHS}
                    year={Utils.getYear()}
                    month={Utils.getMonth()}
                />
            </div>
            <div className="mb-5"></div>
            <ToastU toast={toast} setToast={setToast}/>
        </ReportsWrapper>
    );
}

export default CurrentMonth;