import { useState, useEffect } from "react";
import useAuth from '../../../../../../../hooks/useAuth';
import useAxiosPrivate from '../../../../../../../hooks/useAxiosPrivate';
import InputLabeled from "../../../../../../../components/form/InputLabeled";
import InputAreaLabeled from "../../../../../../../components/form/InputAreaLabeled";
import ModalU from '../../../../../../../components/ui/ModalU';
import Utils from "../../../../../../../utils";
import FoundProducts from "../../../../Invoice/components/FoundProducts";
import ToastU from "../../../../../../../components/ui/ToastU";
import { useTranslation } from "react-i18next";
import SelectStockProduct from "../../../components/SelectStockProduct";

const Edit = ({...props}) => {
    const { t } = useTranslation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    const expenseItem = props.item;

    const [productId, setProductId] = useState('');
    const [stockProducts, setStockProducts] = useState([]);
    const [selectedStockProduct, setSelectedStockProduct] = useState({});
    const [selectedStockProductId, setSelectedStockProductId] = useState('');
    const [quantity, setQuantity] = useState();
    const [description, setDescription] = useState('');

    const [productName, setProductName] = useState('');

    const [product, setProduct] = useState({});

    const [products, setProducts] = useState([]);

    const [queryProduct, setQueryProduct] = useState({
        limit:0, order: [['createdAt', 'ASC']], filter: { productClassificationId: '', name: '' }
    });

    const [queryStockProduct, setQueryStockProduct] = useState({
        order: [['createdAt', 'ASC']], filter: { stock: 0, productId: '#' }
    });
    
    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    useEffect(() => {
        setQueryProduct({
            ...queryProduct,
            filter: {...queryProduct.filter, productClassificationId: props.productClassificationId }
        });
    }, [props.productClassificationId]);

    useEffect(() => {
        if (!ready) return;
        getProducts(); 
    }, [queryProduct]);

    useEffect(() => {
        if (!expenseItem.productId) return;
        setProductId(expenseItem.productId);
        setSelectedStockProductId(expenseItem?.stockProductId);
        setQuantity(expenseItem.quantity);
        setDescription(expenseItem.description);

        setProduct(expenseItem.product);
        setProductName(expenseItem.product.name);
    }, [expenseItem]);

    useEffect(() => {
        if (productName.length < 1) {
            setProducts([]);
            return;
        }
        if (productName)
            setQueryProduct({
                ...queryProduct,
                limit: 100,
                filter: {...queryProduct.filter, name: productName }
            });
    }, [productName]);

    useEffect(() => {
        if (!product.id) return;
        setProductName(`${product?.name} [${t('BAR_CODE')}: ${product?.barcode}]`);
        setProductId(product?.id);
        setProducts([]);

        setQueryStockProduct({
            ...queryStockProduct,
            filter: {...queryStockProduct.filter, productId: product?.id }
        });
    }, [product]);

    useEffect(() => {
        if (!ready) return;
        getStockProducts();
    }, [queryStockProduct]);

    const getProducts = () => {
        if (!props.productClassificationId && productName) {
            setToast({ content: t('PRODUCT_CLASSIFICATION_REQUIRED'), variant: 'danger', title: t('ERROR'), show: true });
            return
        }
        axiosPrivate.get('/products', { params: queryProduct }).then((res) => {
            if (res.status == 200) setProducts(res.data.data);
        });
    }

    const getStockProducts = () => {
        axiosPrivate.get('/stock-products', { params: queryStockProduct }).then((res) => {
            if (res.status == 200) {
                const data = res.data.data;
                setStockProducts(data);
                setSelectedStockProduct(data.find(stockProduct => stockProduct.id == expenseItem?.stockProductId));
            }
        });
    }

    const editTempExpenseItem = () => {
        const requiredInputs = [
            { key: productId, value: t('PRODUCT_REQUIRED') },
            { key: quantity, value: t('QUANTITY_REQUIRED') },
            { key: (quantity <= selectedStockProduct?.stock), value: t('QUANTITY_CANNOT_EXCEED_PRODUCT_QUANTITY') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        const updatedItems = props.items.filter(item => item.productId !== expenseItem?.productId)

        const dataExpenseItem = {product, productId, stockProductId: selectedStockProduct.id, quantity, unitPrice: selectedStockProduct.unitPrice, description};
        props.setItems(
            [...updatedItems, dataExpenseItem]
        );

        props.setModalShow(false);
    }

    return (
        <ModalU show={props.modalShow} size={''} title={`${t('PRODUCT')} [${t('EDIT')}]`}
            actionbtntext={t('SAVE')}
            actionbtnclassname={"bg-green-700 font-14 medium text-white"}
            onSubmit={editTempExpenseItem} onHide={props.setModalShow}>
            <div className='row mx-2'>
                <div className="col-md-12">
                    <InputLabeled label={`${t('PRODUCT')}/${t('BAR_CODE')}`} type={'text'} value={productName} setValue={setProductName} hint={`${t('PRODUCT')} ${t('NAME')}, ${t('BAR_CODE')}`} required={true}/>
                    <FoundProducts items={products} setItem={setProduct}/>
                </div>
                {stockProducts?.length > 0 && <SelectStockProduct stockProducts={stockProducts} inline={false} value={selectedStockProductId} setValue={setSelectedStockProductId} setObject={setSelectedStockProduct} type={'radio'}/>}
                <InputLabeled label={<span>{t('QUANTITY')} ({product?.unit?.name})</span>} type={'number'} value={quantity} setValue={setQuantity} hint={"3"} className={'col-md-6'} required={true} disabled={!selectedStockProductId}/>
                <div className="col-md-6"></div>
                <InputAreaLabeled label={t('DEFINITION')} rows={3} value={description} setValue={setDescription} hint={t('DEFINITION')} className={'col-md-12'}/>
            </div>
            <ToastU toast={toast} setToast={setToast}/>
        </ModalU>
    );
}

export default Edit