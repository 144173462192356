import { useEffect, useState } from "react";
import useAuth from "../../../../../../hooks/useAuth";
import useAxiosPrivate from "../../../../../../hooks/useAxiosPrivate";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { faCopy, faEdit } from "@fortawesome/free-regular-svg-icons";
import { ListGroup } from "react-bootstrap";
import Config from "../../../../../../config";
import ProductCatalogWrapper from "./../../ProductCatalogWrapper";
import ButtonU from "../../../../../../components/ui/ButtonU";
import Utils from "../../../../../../utils";
import ImageModal from "../../../../../../components/ui/ImageModal";
import ToastU from "../../../../../../components/ui/ToastU";
import Status from "../../components/Status";
import Delete from "./Delete";
import icNotFound from "../../../../../../assets/svg/ic_page_not_found.svg";
import { useTranslation } from "react-i18next";

const Product = () => {
    const { t } = useTranslation();
    const { ready, auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);

    const [modalDelete, setModalDelete] = useState(false);

    const [product, setProduct] = useState({});
    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const [showImageModal, setShowImageModal] = useState({src: '', show: false});

    const breadcrumbs = [
        { title: t('MAIN'), link: '/' },
        { title: t('PRODUCT_CATALOG'), link: `/product-catalog/${params.pcid}/products` },
        { title: t('PRODUCTS'), link: `/product-catalog/${params.pcid}/products` },
        { title: product.barcode, active: true}
    ]

    const showImage = () => {
        setShowImageModal({src: `${Config.URL_SERVER}${Config.PATH_PREFIX}${Config.PATH_PRODUCT_IMAGES}${product.image}`, show: true});
    }

    useEffect(() => {
        if (ready) {
            setLoading(true);
            getProduct();
        }
    }, [ready]);

    const getProduct = () => {
        axiosPrivate.get('/products/'+params.id).then((res) => {
            if (res.status == 200) {
                setProduct(res.data.data);
            }
        }).finally(() => {setLoading(false)});
    }

    const modelProps = [
        { label: t('NAME'), value: (prop) => { return(<>{prop.name}</>)} },
        { label: t('BAR_CODE'),
            value: (prop) => {
                return(
                    <span className="text-primary hover-cursor"
                        onClick={() => {
                            try {
                                navigator.clipboard.writeText(prop.barcode);
                                setToast({ content: `${t('COPIED')}: ${prop.barcode}`, variant: 'primary', title: t('SUCCESS'), show: true });
                            } catch (e) {}
                        }}>
                        <FontAwesomeIcon icon={faCopy} className="me-2"/>
                        {prop.barcode}
                    </span>
                )
            }
        },
        { label: t('CATEGORY'), value: (prop) => { return(<>{prop.category ? prop.category.name : '-'}</>)} },
        { label: t('BRAND'), value: (prop) => { return(<>{prop.brand ? prop.brand.name : '-'}</>)} },
        { label: t('MANUFACTURER'), value: (prop) => { return(<>{prop.manufacturer ? prop.manufacturer.name : '-'}</>)} },
        { label: t('COUNTRY'), value: (prop) => { return(<>{prop.country ? prop.country.name : '-'}</>)} },
        { label: t('UNIT'), value: (prop) => { return(<>{prop.unit.name}</>)} },
        { label: t('DATE'), value: (prop) => { return(<>{Utils.getTimestampDateHm(prop.createdAt)}</>)} },
        { label: t('UPDATED_AT'), value: (prop) => { return(<>{Utils.getTimestampDateHm(prop.updatedAt)}</>)} },
        { label: t('ACTIVE'), value: (prop) => { return(<Status status={prop.active} size={'font-14'}/>)} },
        { label: t('DEFINITION'), value: (prop) => { return(<>{prop.description}</>)} },
    ];

    return (
        <ProductCatalogWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <span className="fs-5 bold fg-dark">{product.name}</span>
                <div className="d-flex align-items-center">
                    {/* <ButtonU className={'bg-yellow-400 bg-gradient fg-dark'} icon={faArrowRightArrowLeft} text={t('SALES')}/> */}
                    {/* <ButtonU onSubmit={() => {setModalDelete(true)}} className={'bg-red-400 bg-gradient text-white'} icon={faTrashCan} text={t('DELETE')}/> */}
                    {Utils.allowedRole(auth, '1001', 'write') && <ButtonU onSubmit={() => {navigate(`/product-catalog/${params.pcid}/products/update/`+product.id)}} className={'bg-blue-700 bg-gradient text-white'} icon={faEdit} text={t('EDIT')}/>}
                </div>
            </div>
            
            <div className="d-flex flex-row mt-5 mx-2">
                <ListGroup className="my-3 font-14 col-md-7">
                    { modelProps.map((prop) => {
                        return(
                            <ListGroup.Item key={prop.label} className="d-flex align-items-center justify-content-between py-1">
                                <span className="medium fg-dark">{prop.label}</span>
                                {product.id && prop.value(product)}
                            </ListGroup.Item>
                        )
                    })}
                </ListGroup>
                <div className="d-flex flex-column col-md-5 p-3">
                    {product.image ?
                    <img src={`${Config.URL_SERVER}${Config.PATH_PREFIX}${Config.PATH_PRODUCT_IMAGES}${product.image}`} 
                        onClick={showImage} className="image-product"/>
                    : <img src={icNotFound} className="image-product"/>
                    }
                </div>
            </div>
            <ImageModal show={showImageModal} setShow={setShowImageModal}/>
            <Delete
                modalShow={modalDelete}
                setModalShow={setModalDelete}
                toast={toast}
                setToast={setToast}
                item={product}
                setLoading={setLoading}
            />
            <ToastU toast={toast} setToast={setToast}/>
        </ProductCatalogWrapper>
    );
}

export default Product;