import { useEffect, useState } from "react";
import useAuth from "../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import MainWrapper from "../../layouts/MainWrapper";
import SideBar from "../../../../layouts/SideBar";
import icReceipt from './../../../../assets/svg/ic_receipt_outline.svg';

const InvoiceWrapper = (props) => {
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    
    const [productClassifications, setProductClassifications] = useState([]);

    const [query, setQuery] = useState({
        page: 1, limit: 10,
        order: [['id', 'ASC']],
    });

    useEffect(() => {
        if (ready) {
            getProductClassifications();
        }
    }, [ready]);

    const getProductClassifications = async() => {
        const res = await axiosPrivate.get('/product-classifications', { params: query });
        if (res.status == 200) {
            setProductCatalogLinks(res.data.data);
        }
    }

    const setProductCatalogLinks = (data) => {
        data.forEach((item) => {
            item.icon = icReceipt
            item.links = [];
            item.links.push(`/invoice/${item.id}/invoices`);
            item.links.push(`/invoice/${item.id}/invoices/:id`);
            item.links.push(`/invoice/${item.id}/invoices/create`);
        });
        setProductClassifications(data);
    }

    return (
        <MainWrapper loading={props.loading} breadcrumbs={props.breadcrumbs}>
           <div className="main-page">
                <SideBar items={productClassifications}/>
                <div className="body-page">
                    { props.children }
                </div>
            </div>
        </MainWrapper>
    );
};

export default InvoiceWrapper;