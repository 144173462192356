import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import useAuth from "../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import WarehouseWrapper from "../WarehouseWrapper";
import InputField from "../../../../../components/form/InputField";
import WarehouseCard from "./components/WarehouseCard";
import { useTranslation } from "react-i18next";

const Warehouses = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(false);

    const [warehouses, setWarehouses] = useState([]);

    const parsed = queryString.parse(location.search);
    const urlQuery = {
        page: parsed.page || 1,
        limit: 10,
        order: parsed.order || [['code', 'ASC']],
        filter: { createdAtLb: '', createdAtUb: '' }
    }
    
    const [query, setQuery] = useState(urlQuery);

    const [find, setfind] = useState('');

    const breadcrumbs = [
        { title: t('MAIN'), link: '/' },
        { title: t('WAREHOUSE'), link: '/warehouse/stock-products/1' } ,
        { title: t('WAREHOUSES'), active: true}
    ]

    useEffect(() => {
        if (ready) {
            getUpdatedUrl();
            getWarehouses();
        }
    }, [ready, query]);

    const getUpdatedUrl = () => {
        parsed.page = query.page;
        window.history.replaceState({}, '', `${location.pathname}?${queryString.stringify(parsed)}`);
    }

    useEffect(() => {
        setQuery({...query, filter: { name: find }});
    }, [find]);
 
    const getWarehouses = () => {
        setLoading(true);
        axiosPrivate.post('/warehouses/report-status', { params: query }).then((res) => {
            if (res.status == 200) {
                setWarehouses(res.data);
            }
        }).finally(() => {setLoading(false)});
    }

    return (
        <WarehouseWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <span className="fs-5 bold fg-dark">{t('WAREHOUSES')}</span>
                <div className="d-flex align-items-center">
                    <InputField type={"text"} value={find} setValue={setfind} hint={t('FIND')} icon={faSearch} formClass="input-field-container me-1 font-14" inputClass="input-field-outline"/> 
                </div>
            </div>
            <div className="row p-3 mt-5">
                { warehouses?.map((item) => {
                    return (<WarehouseCard key={item.id} item={item}/>)
                })}
            </div>
            <div className="mb-5"></div>
        </WarehouseWrapper>
    );
}

export default Warehouses;