import React from 'react';
import { prettyPrintJson } from 'pretty-print-json'; // Импортируем библиотеку


const PrettyJSON = ({ ...props }) => {
    const jsonData = props.data

    const options = {
        indent: 4,
        lineNumbers: false,
        linkUrls: true,
        linksNewTab: true,
        quoteKeys: false,
        trailingCommas: false
    };

    // Форматируем JSON-данные в красивый HTML
    const prettyHtml = prettyPrintJson.toHtml(jsonData, options);

    return (
        <div className="json-container">
            <pre dangerouslySetInnerHTML={{ __html: prettyHtml }} />
        </div>
    );
}

export default PrettyJSON;