import { useState, useEffect } from "react";
import useAuth from '../../../../../../hooks/useAuth';
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import { useNavigate } from "react-router-dom";
import { faCancel, faAdd } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import Utils from "../../../../../../utils";
import WarehouseWrapper from "../../WarehouseWrapper";
import ButtonU from "../../../../../../components/ui/ButtonU";
import SelectLabeled from "../../../../../../components/form/SelectLabeled";
import ItemCreate from "./ExpenseItem/Create";
import ExpenseItemsTemp from "./ExpenseItem/ExpenseItemsTemp";
import ToastU from "../../../../../../components/ui/ToastU";
import Cancel from "../../../../../../components/ui/Cancel";
import { useTranslation } from "react-i18next";

const Create = () => {
    const { t } = useTranslation();
    const { ready, isAdmin, auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const productClassifications = JSON.parse(localStorage.getItem('productClassifications'));
    
    const [productClassificationId, setProductClassificationId] = useState('');

    const [expenseItemModal, setExpenseItemModal] = useState(false);
    const [modalCancel, setModalCancel] = useState(false);

    const [expensesTemp, setExpenesTemp] = useState([]);

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('MAIN'), link: '/'},
        { title: t('WAREHOUSE'), link: `/warehouse/stock-products/1`},
        { title: t('EXPENSES'), link: `/warehouse/expenses` },
        { title: t('NEW'), active: true },
    ]

    useEffect(() => {
        if (!ready) return;
        setExpenesTemp([]);
    }, [productClassificationId]);

    const handleSubmit = (event) => {
        event.preventDefault();

        const requiredInputs = [
            { key: productClassificationId, value: t('PRODUCT_CLASSIFICATION_REQUIRED') },
            { key: !(expensesTemp.length < 1), value: `${t('DATA_NOT_ENTERED_YET')} (${t('PRODUCTS')})` },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        setLoading(true);
        const data = { items: expensesTemp };

        axiosPrivate.post('/expenses/create-bulk', data).then((res) => {
            if (res.status == 200) {
                setToast({ content: res.data.msg, variant: 'success', title: t('SUCCESS'), show: true });
                navigate(-1);
            }
        }).catch((err) => {
            if (!err?.response) {
                setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
                return;
            }
            switch(err.response.status) {
                case 406: setToast({ content: t('NOT_ALLOWED'), variant: 'danger', title: t('ERROR'), show: true }); break;
                default : setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true }); break;
            }
        }).finally(() => {setLoading(false)});
    }

    return (
        <WarehouseWrapper loading={loading} breadcrumbs={breadcrumbs}>
             <div className="head-long-page">
                <span className="fs-5 bold fg-dark">{t('NEW')} {t('EXPENSES')}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={() => {setModalCancel(true)}} icon={faCancel} text={t('CANCEL')} className={"bg-red-400 bg-gradient text-white"}/>
                    <ButtonU onSubmit={handleSubmit} icon={faSave} text={t('SAVE')} className={"bg-green-600 bg-gradient text-white"}/>
                </div>
            </div>

            <div className="my-2 py-2 px-4 mt-5">
                <div className="row">
                    <SelectLabeled label={t('PRODUCT_CLASSIFICATION')} items={productClassifications} value={productClassificationId} setValue={setProductClassificationId} className={'col-md-3'} required={true}/>
                    <div className="col-md-12">
                        <div className="d-flex flex-column border my-2 px-2 py-1">
                            <div className="d-flex align-items-center justify-content-between mb-1">
                                <span className="font-18 medium fg-dark">{t('PRODUCTS')} ({expensesTemp.length})</span>
                                <ButtonU onSubmit={() => {setExpenseItemModal(true)}} icon={faAdd} className={'bg-blue-700 bg-gradient text-white'}/>
                            </div>
                            <ExpenseItemsTemp items={expensesTemp} setItems={setExpenesTemp} productClassificationId={productClassificationId}/>
                        </div>
                    </div>
                </div>
            </div>
            <ItemCreate
                items={expensesTemp}
                setItems={setExpenesTemp}
                productClassificationId={productClassificationId}
                modalShow={expenseItemModal}
                setModalShow={setExpenseItemModal}
            />
            <Cancel modalShow={modalCancel} setModalShow={setModalCancel}/>
            <ToastU toast={toast} setToast={setToast}/>
        </WarehouseWrapper>
    );
}

export default Create;