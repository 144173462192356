import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { faMagnifyingGlass, faTableCells, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import Utils from "../../../../../utils";
import ReportsWrapper from "../ReportsWrapper";
import ButtonU from "../../../../../components/ui/ButtonU";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faCalendarCheck } from "@fortawesome/free-regular-svg-icons";
import Option from "./components/Option";
import useCheckExistenceOfPreviousMonthRecord from "../../../../../hooks/useCheckExistenceOfPreviousMonthRecord";
import SelectMonth from "../components/SelectMonth";
import SelectItem from "../components/SelectItem";
import Months from "../../../../../components/ui/Months";
import InExAreaChart from "./../../Dashboard/charts/InExAreaChart";

const General = () => {
    const { t } = useTranslation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const checkExistenceOfPreviousMonthRecord = useCheckExistenceOfPreviousMonthRecord();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const MONTHS = Months();
    const [isPreviousMonthRecordExist, setIisPreviousMonthRecordExist] = useState();

    const [years, setYears] = useState([]);

    const [incomeTotals, setIncomeTotals] = useState({});
    const [expenseTotals, setExpenseTotals] = useState({});

    const [year, setYear] = useState(Utils.getYear());
    const [month, setMonth] = useState(Utils.getMonth());

    const currentYear = Utils.getYear();
    const currentMonth = Utils.getMonth();

    const urlQuery = {
        order: [['createdAt', 'ASC']],
        filter: {
            year: currentYear,
            month: currentMonth,
        }
    }
    const [query, setQuery] = useState(urlQuery);

    const breadcrumbs = [
        { title: t('MAIN'), link: '/' },
        { title: t('REPORTS'), link: '/reports/general' } ,
        { title: t('GENERAL'), active: true}
    ]

    useEffect(() => {
        if (ready) {
            getSystemYears();
        }
    }, [ready]);

    const getSystemYears = () => {
        setLoading(true);
        axiosPrivate.get('/system/years', { params: query }).then((res) => {
            if (res.status == 200) {
                setYears(res.data);
            }
        }).finally(() => {setLoading(false)});
    }
 
    useEffect(() => {
        if (ready) {
            getIncomeTotals();
            getExpenseTotals();
        }
    }, [ready, query]);

    useEffect(() => {
        const checkPreviousMonthRecord = async() => {
            setIisPreviousMonthRecordExist(await checkExistenceOfPreviousMonthRecord());
        };
        checkPreviousMonthRecord();
    }, [checkExistenceOfPreviousMonthRecord]);

    const getIncomeTotals = () => {
        axiosPrivate.get('/reports/income-totals', { params: query }).then((res) => {
            if (res.status == 200) setIncomeTotals(res.data);
        });
    }

    const getExpenseTotals = () => {
        axiosPrivate.get('/reports/expense-totals', { params: query }).then((res) => {
            if (res.status == 200) setExpenseTotals(res.data);
        });
    }

    const handleFilterApply = () => {
        setQuery(prev => {
            return { ...prev, filter: { ...prev.filter, year, month } }
        })
    }

    const closeMonthlyReport = () => { navigate('/reports/general/close-monthly-report') }

    return (
        <ReportsWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="d-flex flex-row align-items-center justify-content-between mx-3">
                <span className="fs-2 bold">{t('REPORTS')}</span>
                <span className="text-secondary font-18"><FontAwesomeIcon icon={faCalendar} className="me-2"/>{Utils.getCurrentDate()}</span>
            </div>
            <div className="row mx-3 mt-3">
                {isPreviousMonthRecordExist == false && <ButtonU onSubmit={closeMonthlyReport} className={'bg-red-700 bg-gradient text-white col-md-12 mb-2'} icon={faTriangleExclamation} text={`${t('CLOSE_MONTHLY_REPORT')}!`}/>}
                <div className="col-md-12"></div>
                <Option name={t('REPORT')} description={t('CURRENT_MONTH')} link={`/reports/general/report`} bg={'bg-blue-400'} icon={faTableCells}/>
                <Option name={t('REPORTS')} description={t('LAST_MONTHS')} link={`/reports/monthly-reports/report`} bg={'bg-orange-400'} icon={faCalendarCheck}/>
            </div>
            <span className="mx-3 mt-4 font-20 bold">{t('STATISTICS')}</span>
            <div className="row mx-3 mt-2 bg-blue-100 pb-3 border">
                <div className="d-flex flex-row align-items-center bg-blue-900 p-2 col-md-12 mb-1">
                    <div className="d-flex flex-row align-items-center me-1">
                        <span className="text-white font-13 medium mx-2">{t('YEAR')}</span>
                        <SelectItem items={years} value={year} setValue={setYear} className={''}/>
                    </div>
                    <div className="d-flex flex-row align-items-center">
                        <span className="text-white font-13 medium mx-2">{t('MONTH')}</span>
                        <SelectMonth items={MONTHS} value={month} setValue={setMonth} className={''}/>
                    </div>
                    <FontAwesomeIcon onClick={handleFilterApply } icon={faMagnifyingGlass} className="font-18 fg-hover ms-2"/>
                </div>
                <InExAreaChart seriesTotals={incomeTotals?.totals} seriesTotals2={expenseTotals?.totals} seriesDates={incomeTotals?.dates} className={"col-md-12"}/>
            </div>
        </ReportsWrapper>
    );
}

export default General;