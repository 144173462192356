import MainWrapper from "../../layouts/MainWrapper";
import SideBar from "../../../../layouts/SideBar";
import { useTranslation } from "react-i18next";
import Keys from "../../../../utils/constants";
import icChartColumn from './../../../../assets/svg/ic_chart_column.svg';
import icTable from './../../../../assets/svg/ic_table.svg';

const ReportsWrapper = (props) => {
    const { t } = useTranslation();

    const items = [
        { name: t('GENERAL'), id: "general", icon:icChartColumn, links: Keys.REPORTS_GENERAL_LINKS},
        { name: t('MONTHLY_REPORTS'), id: "monthly-reports", icon:icTable, links: Keys.REPORTS_MONTHLY_REPORTS_LINKS},
    ];

    return (
        <MainWrapper loading={props.loading} breadcrumbs={props.breadcrumbs}>
            <div className="main-page">
                <SideBar items={items}/>
                <div className="body-page">
                    { props.children }
                </div>
            </div>
        </MainWrapper>
    );
};

export default ReportsWrapper;