import ReactApexChart from 'react-apexcharts';
import { useTranslation } from "react-i18next";

const ProductClassificationPieChart = ({...props}) => {
  const { t } = useTranslation();
  const series = props?.series;

  const options = {
    chart: {
      type: 'donut',
      dropShadow: {
        enabled: true,
        color: '#111',
        top: -1,
        left: 3,
        blur: 3,
        opacity: 0.2
      }
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
              label: t('TOTAL'),
              formatter: (w) => {
                return w.globals?.seriesTotals.reduce((a, b) => a + b, 0);
              },
            }
          }
        }
      }
    },
    labels: props?.labels,
    dataLabels: {
      dropShadow: {
        blur: 3,
        opacity: 0.8
      }
    },
    states: {
      hover: {
        filter: 'none'
      }
    },
    theme: {
      palette: props.palette
    },
    title: {
      text: props.title,
      style: {
        color:  '#505050'
      },
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  };

  return (
    <div className="col-md-5" id="chart">
        <ReactApexChart options={options} series={series} type="donut" height={300} className='shadow-sm'/>
    </div>
  );
}

export default ProductClassificationPieChart;