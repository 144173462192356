import Wrapper from "../../../layouts/Wrapper";
import SideBarSetings from "./SideBarSettings";
import { useTranslation } from "react-i18next";
import icUserOutline from "./../../../assets/svg/ic_user_outline.svg";
import icShieldKeyhole from "./../../../assets/svg/ic_shield_keyhole.svg";
import icObjectsColumn from "./../../../assets/svg/ic_objects_column.svg";

const SettingsWrapper = (props) => {
    const { t } = useTranslation();

    const items = [
        { name: t('PROFILE'), id: "profile", icon: icUserOutline, link: '/settings/profile'},
        { name: t('PRIVACY_AND_SECURITY'), id: "privacy_and_security", icon: icShieldKeyhole, link: '/settings/privacy-and-security'},
        { name: t('DATA'), id: "data", icon: icObjectsColumn, link: '/settings/data/general'}
    ];

    return (
        <Wrapper loading={props.loading} breadcrumbs={props.breadcrumbs}>
            <div className="main-page-settings">
                <SideBarSetings items={items}/>
                <div className="body-page-settings py-3 px-4">
                    { props.children }
                </div>
            </div>
        </Wrapper>
    );
};

export default SettingsWrapper;