import { useState, useEffect } from "react";
import useAuth from '../../../../../../hooks/useAuth';
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import { Link, useParams } from "react-router-dom";
import { faPrint, faFileExport } from "@fortawesome/free-solid-svg-icons";
import { faEdit, faEye } from "@fortawesome/free-regular-svg-icons";
import InvoiceWrapper from "../../InvoiceWrapper";
import TableStd from '../../../../../../components/ui/TableStd';
import ButtonU from "../../../../../../components/ui/ButtonU";
import Utils from "../../../../../../utils";
import ExportFile from "../../components/ExportFile";
import DataNotFound from '../../../../../../components/ui/DataNotFound';
import ToastU from "../../../../../../components/ui/ToastU";
import Status from "../../../../../../components/ui/Status";
import Update from "./Update";
import ShowInvoiceItem from "../../components/ShowInvoiceItem";
import { useTranslation } from "react-i18next";
import Actions from "../../../../../../components/ui/Actions";

const Invoice = () => {
    const { t } = useTranslation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const params = useParams();
    const [loading, setLoading] = useState(false);

    const [invoice, setInvoice] = useState({});
    const [invoiceItems, setInvoiceItems] = useState([]);
    const [invoiceItemsCount, setInvoiceItemsCount] = useState();

    const [exportModal, setExportModal] = useState(false);
    const [modalUpdate, setModalUpdate] = useState(false);
    const [modalShowItem, setModalShowItem] = useState(false);

    const [itemToBe, setItemToBe] = useState({});
   
    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('MAIN'), link: '/'},
        { title: t('INVOICE'), link: `/invoice/${params.pcid}/invoices`},
        { title: t('INVOICES'), link: `/invoice/${params.pcid}/invoices/${params.id}` },
        { title: invoice.number, active: true },
    ]

    useEffect(() => {
        if (ready) {
            getInvoice();
        }
    }, [ready, toast]);

    const getInvoice = () => {
        setLoading(true);
        axiosPrivate.get('/invoices/'+params.id).then((res) => {
            if (res.status == 200) {
                const invoice = res.data.data;
                setInvoice(invoice);
                setInvoiceItems(invoice.invoiceItems);
                setInvoiceItemsCount(invoice.invoiceItems.length);
            }
        }).finally(() => {setLoading(false)});
    }

    const modelProps = [ 
        { 
            name: t('BAR_CODE'),
            value: (prop) => { return(<>{prop?.product?.barcode}</>) } 
        },
        { 
            name: t('PRODUCT'),
            value: (prop) => { return(<Link to={`/product-catalog/${params.pcid}/products/${prop?.product?.id}`} className="no-decor">{prop?.product?.name}</Link>) } 
        },
        {
            name: t('QUANTITY'),
            value: (prop) => { return(<>{prop?.quantity} {prop?.product?.unit?.name}</>) }
        },
        {
            name: t('UNIT_PRICE'),
            value: (prop) => { return(<>{prop?.unitPrice?.toFixed(2)}</>) }
        },
        {
            name: Utils.truncateText(`${t('TOTAL')} ${t('UNIT_PRICE')}`, 14),
            value: (prop) => { return(<>{prop?.totalUnitPrice}</>) }
        },
        {
            name: t('ACTION'),
            value: (prop) => {
                const options =[
                    {
                        icon: faEye,
                        style: "fg-blue-700 font-14",
                        onClick: () => {
                            setItemToBe(prop);
                            setModalShowItem(true);
                        },
                    },
                ]
                return (<Actions items={options}/>)
            }
        }
    ];

    return (
        <InvoiceWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <span className="fs-5 bold fg-dark">{t('INVOICE')}: {invoice?.number}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={() => {setExportModal(true);}} icon={faFileExport} text={t('EXPORT')} className={"bg-orange-400 bg-gradient text-white"}/>
                    <ButtonU className={'border-dark fg-dark'} icon={faPrint} text={t('PRINT')}/>
                    <ButtonU onSubmit={() => {setModalUpdate(true);}} icon={faEdit} text={t('EDIT')} className={"bg-blue-700 bg-gradient text-white"}/>
                </div>
            </div>
            <div className="mt-5"></div>
            <div className="row d-flex flex-row mx-0 p-3">
                {invoice.id && <div className="d-flex flex-column col-md-8 border border-gray p-3">
                    <div className="d-flex flex-row justify-content-between">
                        <div className="d-flex flex-column">
                            <span className="font-20 medium fg-dark">{invoice?.supplier?.companyName}</span>
                            <span className="font-16 medium fg-dark">{invoice?.supplier?.contactName}</span>
                            <span className="font-12 fg-dark">{invoice?.supplier?.addressLine}</span>
                            <span className="font-12 fg-dark">{invoice?.supplier?.province?.name} {invoice?.supplier?.district?.name} {invoice?.supplier?.postalCode}</span>
                        </div>
                        <div className="d-flex flex-column">
                            <span className="fs-1 medium fg-blue-300">{t('INVOICE').toUpperCase()}</span>
                            <span className="font-12 text-secondary">{t('NUMBER')}: <span className="fg-dark medium">{invoice?.number}</span></span>
                            <span className="font-12 text-secondary">{t('DATE')}: <span className="fg-dark medium">{Utils.getTimestampDate(invoice.createdAt)}</span></span>
                        </div>
                    </div>
                    <hr  className="my-2"/>
                    <div className="d-flex flex-row justify-content-between">
                        <div className="d-flex flex-column">
                            <span className="font-12 fg-dark">{t('WAREHOUSE')}: <span className="medium">{invoice?.warehouse?.name}</span></span>
                        </div>
                        <span></span>
                    </div>
                    <hr className="my-2"/>
                    {invoiceItemsCount ?
                        <div style={{overflowY: "scroll", height: "250px"}}>
                            <TableStd modelProps={modelProps} data={invoiceItems}/>
                        </div>
                        : <DataNotFound/>
                    }
                    <span className="font-14 medium fg-dark">{t('TOTAL')} {t('ITEM')}: {invoiceItemsCount}</span>
                    <hr  className="my-2"/>
                    <div className="d-flex flex-row justify-content-between">
                        <div className="d-flex flex-column">
                            <span className="font-12 medium fg-dark">{t('PAYMENT_TERMS')}</span>
                            <span className="font-12 fg-dark">{invoice.paymentTerms ? invoice.paymentTerms : '-'}</span>
                            <span className="font-12 medium fg-dark">{t('PAYMENT_DETAILS')}</span>
                            <span className="font-12 fg-dark">{invoice.paymentDetail ? invoice.paymentDetail : '-'}</span>
                            <span className="font-12 medium fg-gray">{t('NOTE')}</span>
                            <span className="font-12 fg-dark">{invoice.note ? invoice.note : '-'}</span>
                        </div>
                        <div className="d-flex flex-column">
                            <span className="font-14 medium fg-dark">{t('TOTAL')}</span>
                            <span className="font-18 fg-dark bold">{invoice?.total?.toFixed(2)}</span>
                            <span className="font-12 text-secondary mt-3">{t('PAID')}: <span className="fg-dark medium">{invoice?.paid ? invoice?.paid?.toFixed(2) : 0}</span></span>
                            <span className="font-12 text-secondary mt-1">{t('STATUS')}: <Status  status={invoice.closed}/></span>
                        </div>
                    </div>
                </div>}
                {invoice.user && <div className="col-md-4">
                    <div className="ms-2 border p-2">
                        <div className="d-flex flex-row justify-content-between">
                            <div className="d-flex flex-column">
                                <span className="font-12 text-secondary medium">{t('REGISTRATION_DATE')}</span>
                                <span className="fg-dark font-14">{Utils.getTimestampDateHm(invoice.createdAt)}</span>
                            </div>
                            <div className="d-flex flex-column">
                                <span className="font-12 text-secondary medium">{t('UPDATED_AT')}</span>
                                <span className="fg-dark font-14">{Utils.getTimestampDateHm(invoice.updatedAt)}</span>
                            </div>
                        </div>
                        <div className="d-flex flex-column mt-3">
                            <span className="font-12 text-secondary medium">{t('ACCEPTING')} ({t('USER')})</span>
                            <span className="fg-dark font-14">{invoice?.user?.fullName}</span>
                        </div>
                    </div>
                </div>}
            </div>
            
            <ShowInvoiceItem
                modalShow={modalShowItem}
                setModalShow={setModalShowItem}
                item={itemToBe}
            />
            <ExportFile
                data={invoice}
                modalShow={exportModal}
                setModalShow={setExportModal}
                invoiceItemsCount={invoiceItemsCount}
                toast={toast}
                setToast={setToast}
            />
            <Update
                modalShow={modalUpdate}
                setModalShow={setModalUpdate}
                toast={toast}
                setToast={setToast}
                item={invoice}
                setLoading={setLoading}
            />
            <ToastU toast={toast} setToast={setToast}/>
        </InvoiceWrapper>
    );
}

export default Invoice;