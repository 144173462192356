export default class Utils {
    static getAvatar(user) {
        try {
            return user.firstName.charAt(0) + user.lastName.charAt(0);
        } catch (err) {}
    }

    static getFirstChar(s) { return s.charAt(0); }

    static getEmployeeShortName(employee) {
        const firstName1stChar = employee?.firstName?.charAt(0);
        const middleName1stChar = employee?.middleName?.charAt(0);
        const lastName = employee?.lastName;

        const shortName = `${firstName1stChar}. ${middleName1stChar && middleName1stChar + '. '}${lastName}`;

        return shortName;
    }

    static genIdByDateTime() {
        let date = new Date();
        let dd = String(date.getDate()).padStart(2, '0');
        let mm = String(date.getMonth() + 1).padStart(2, '0');
        let yy = String(date.getFullYear() % 100).padStart(2, '0');
        let hour = String(date.getHours()).padStart(2, '0');
        let minute = String(date.getMinutes()).padStart(2, '0');
    
        return `${yy}${mm}${dd}${hour}${minute}`;
    }

    static getTimestampDate(timestamp) {
        let date = new Date(timestamp);
        let dd = String(date.getDate()).padStart(2, '0');
        let mm = String(date.getMonth() + 1).padStart(2, '0');
        let yyyy = date.getFullYear();
        return `${dd}/${mm}/${yyyy}`;
    }

    static getTimestampDate2(timestamp, months) {
        let date = new Date(timestamp);
        let dd = String(date.getDate()).padStart(2, '0');
        let mm = String(date.getMonth() + 1).padStart(2, '0'); // Get month number and pad it
        let monthName = months.get(mm); // Get month name from the map
        let yyyy = date.getFullYear();
        return `${dd} ${monthName} ${yyyy}`;
    }

    static getTimestampMonth(timestamp) {
        let date = new Date(timestamp);
        let mm = String(date.getMonth() + 1).padStart(2, '0');
    
        return `${mm}`;
    }

    static getTimestampDateHm(timestamp) {
        let date = new Date(timestamp);
        let dd = String(date.getDate()).padStart(2, '0');
        let mm = String(date.getMonth() + 1).padStart(2, '0');
        let yyyy = date.getFullYear();
        let hour = String(date.getHours()).padStart(2, '0');
        let minute = String(date.getMinutes()).padStart(2, '0');
    
        return `${dd}/${mm}/${yyyy} ${hour}:${minute}`;
    }

    static getYear() {
        let date = new Date();
        let yyyy = date.getFullYear();

        return yyyy;
    }

    static getMonthYear() {
        let date = new Date();
        let mm = String(date.getMonth() + 1).padStart(2, '0');
        let yyyy = date.getFullYear();

        return `${mm}/${yyyy}`;
    }

    static getMonth() {
        let date = new Date();
        let mm = String(date.getMonth() + 1).padStart(2, '0');
    
        return `${mm}`;
    }

    static getDay() {
        let date = new Date();
        return date.getDate();
    }

    static getCurrentDate() {
        let date = new Date();
        let dd = String(date.getDate()).padStart(2, '0');
        let mm = String(date.getMonth() + 1).padStart(2, '0');
        let yyyy = date.getFullYear();

        return `${dd}-${mm}-${yyyy}`;
    }

    static getCurrentTime() {
        let date = new Date();
        let hour = String(date.getHours()).padStart(2, '0');
        let minute = String(date.getMinutes()).padStart(2, '0');
    
        return `${hour}:${minute}`;
    }

    static getCurrentDateISO() {
        let date = new Date();
        let dd = String(date.getDate()).padStart(2, '0');
        let mm = String(date.getMonth() + 1).padStart(2, '0');
        let yyyy = date.getFullYear();

        return `${yyyy}-${mm}-${dd}`;
    }

    static getTimestampPreviousMonthDate(timestamp) {
        let date = new Date(timestamp);
        const previousMonthDate = new Date(date.setMonth(date.getMonth() - 1));
        const year = previousMonthDate.getFullYear();
        const month = String(previousMonthDate.getMonth() + 1).padStart(2, '0');

        return {year, month};
    }

    static errorHandling (data) {
        let content = '';
        data.map((item, index) => { if (!item.key) content += `${index + 1}) ${item.value}. ` });

        return content;
    }

    static allowedRole(auth, module, type) {
        if (!auth.role) return false;
        const permissions = auth.role.permissions;
        if (permissions.find(p => p.module === module && p[type])) return true;
        return false;
    }

    static truncateText = (text, size) => {
        return text.length > size ? text.slice(0, size) + '.' : text;
    };

    static capitalizeFirstLetter = (text) => {
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    }

    static toggleSidebar() {
        let el = 'body'.trim();
        document.querySelector(el).classList.toggle('toggle-sidebar');
    }

    static checkBestBeforeDate(expireDate, limitDay) {
        let currentDate = new Date();
        expireDate = new Date(expireDate);

        if (isNaN(expireDate)) {
            throw new Error("Invalid expire date");
        }

        let timeDifference = expireDate - currentDate;

        const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
        let limitTime = limitDay * oneDayInMilliseconds;
        const remainingDay = Math.ceil(timeDifference / oneDayInMilliseconds)



        if (timeDifference > 0 && timeDifference <= limitTime) {
            return { status: 'WARNING', remainingDay }
        } else if (timeDifference <= 0) {
            return { status: 'DANGER', remainingDay: '0' }
        }
    }
    
    static toggleSidebar() {
        let el = 'body'.trim();
        document.querySelector(el).classList.toggle('toggle-sidebar');
    }
}