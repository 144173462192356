import React from 'react';
import icXMark from './../../assets/svg/ic_x_mark.svg';
import { useNavigate } from "react-router-dom"
import useAuth from "./../../hooks/useAuth";
import useSignOut from './../../hooks/useSignOut';
import UserAvatar from "./../../components/ui/UserAvatar";
import icUserOutline from "./../../assets/svg/ic_user_outline.svg";
import icSettingsOutline from "./../../assets/svg/ic_settings_outline.svg";
import icQuestionSquare from "./../../assets/svg/ic_question_square.svg";
import icHelp from "./../../assets/svg/ic_help.svg";
import icBoxOutline from "./../../assets/svg/ic_box_outline.svg";
import icExit from "./../../assets/svg/ic_exit.svg";
import { useTranslation } from "react-i18next";
import Config from '../../config';

const RightSideModal = ({...props}) => {
    const { auth, isAdmin } = useAuth();
    const signOut = useSignOut();
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div className={`right-nav-menu`}>
            <div className="d-flex flex-row justify-content-between p-2">
                <div className='d-flex flex-row'>
                    <UserAvatar user={auth.user} style={'bg-blue-600 text-white wh-35'}/>
                    <div className='d-flex flex-column ms-2'>
                        <span className='text-black medium font-14'>{auth.user.username}</span>
                        <span className='text-black font-12'>{auth.user.firstName}</span>
                    </div>
                </div>
                <span onClick={props.onClose}>
                    <img src={icXMark} className='wh-30 bg-white rounded-2 p-2' />
                </span>
            </div>
            <div className="d-flex flex-column mt-4 mx-2">
                <span onClick={() => {navigate('/settings/profile')}} className="font-14 d-flex align-items-center btn-hover">
                    <img src={icUserOutline} className="wh-13 me-2"/>
                    {t('PROFILE')}
                </span>
                <hr className='my-2'/>
                {isAdmin && <span onClick={() => {navigate('/settings/data/general')}} className="font-14 d-flex align-items-center btn-hover">
                    <img src={icSettingsOutline} className="wh-13 me-2"/>
                    {t('SETTINGS')}
                </span>}
                <span onClick={() => {}} className="font-14 d-flex align-items-center btn-hover">
                    <img src={icHelp} className="wh-13 me-2"/>
                    {t('HELP')}</span>
                <span onClick={() => {}} className="font-14 d-flex align-items-center btn-hover">
                    <img src={icQuestionSquare} className="wh-13 me-2"/>
                    {t('ABOUT')}
                </span>
                <div className="font-14 d-flex align-items-center justify-content-between">
                    <div className='d-flex flex-row align-items-center'>
                        <img src={icBoxOutline} className="wh-13 me-2"/>
                        {t('VERSION')}
                    </div>
                    <span>{Config.VERSION}</span>
                </div>
                <hr className='my-2'/>
                <span onClick={() => {signOut()}} className="font-14 fg-red-300 btn-hover">
                    <img src={icExit} className="wh-13 me-2"/>
                    {t('EXIT')}
                </span>
            </div>
        </div>
    );
};

export default RightSideModal;