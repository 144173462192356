import { useEffect, useState } from "react";
import WarehouseWrapper from "./../../WarehouseWrapper";
import StockProductsNavBar from "./StockProductsNavBar"

const StockProductsWrapper = ({...props}) => {
    const productClassifications = JSON.parse(localStorage.getItem('productClassifications'));
    const [productCatalogLinks, setProductCatalogLinks] = useState([]);

    useEffect(() => {
        productClassifications.forEach((item) => {
            item.link = `/warehouse/stock-products/${item.id}`;
        });
        setProductCatalogLinks(productClassifications);
    }, []);

    return (
        <WarehouseWrapper loading={props.loading} breadcrumbs={props.breadcrumbs}>
            <StockProductsNavBar
                items={productCatalogLinks}
                find={props.find}
                setFind={props.setFind}
                query={props.query}
                setQuery={props.setQuery}
                refresh={props.refresh}
            />
            <div style={{marginTop: '35px'}}>
                { props.children }
            </div>
        </WarehouseWrapper>
    );
};

export default StockProductsWrapper;