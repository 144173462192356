import { useState, useEffect, useRef } from "react";
import useAuth from '../../../../../../hooks/useAuth';
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import { useNavigate } from "react-router-dom";
import { faCancel, faSave, faAdd, faFileImport, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import Utils from "../../../../../../utils";
import WarehouseWrapper from "../../WarehouseWrapper";
import ButtonU from "../../../../../../components/ui/ButtonU";
import InputLabeled from "../../../../../../components/form/InputLabeled";
import InputAreaLabeled from "../../../../../../components/form/InputAreaLabeled";
import SelectLabeled from "../../../../../../components/form/SelectLabeled";
import ImportFile from "./../components/ImportFile";
import ItemCreate from "./RequestFormItem/Create";
import FoundWarehouses from '../../components/FoundWarehouses';
import RequestFormItemsTemp from "./RequestFormItem/RequestFormItemsTemp";
import ToastU from "../../../../../../components/ui/ToastU";
import Cancel from "../../../../../../components/ui/Cancel";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Create = () => {
    const { t } = useTranslation();
    const { ready, isAdmin, auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const productClassifications = JSON.parse(localStorage.getItem('productClassifications'));
    
    const warehouseNameRef = useRef(null);

    const [number, setNumber] = useState(Utils.genIdByDateTime());
    const [warehouseId, setWarehouseId] = useState('');
    const [productClassificationId, setProductClassificationId] = useState('');
    const [note, setNote] = useState();
    const [director, setDirector] = useState();
    const [deputyOfDirector, setDeputyOfDirector] = useState();
    const [generalAccountant, setGeneralAccountant] = useState();

    const [warehouseName, setWarehouseName] = useState('');

    const [centerWarehouse, setCenterWarehouse] = useState({});
    const [warehouse, setWarehouse] = useState({});

    const [importModal, setImportModal] = useState(false);
    const [requestFormItemModal, setRequestFormItemModal] = useState(false);
    const [modalCancel, setModalCancel] = useState(false);

    const [warehouses, setWarehouses] = useState([]);

    const [queryWarehouse, setQueryWarehouse] = useState({
        limit:0, order: [['order', 'ASC']], filter: { }
    });

    const [requestFormItemsTemp, setRequestFormItemsTemp] = useState([]);

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('MAIN'), link: '/'},
        { title: t('WAREHOUSE'), link: `/warehouse/stock-products/1`},
        { title: t('REQUEST_FORMS'), link: `/warehouse/request-forms` },
        { title: t('NEW'), active: true },
    ]

    useEffect(() => {
        if (!ready) return;
        setRequestFormItemsTemp([]);
        getCenterWarehouse();
    }, [productClassificationId]);

    useEffect(() => {
        if (ready && isAdmin != null) getWarehouses();
    }, [queryWarehouse, isAdmin]);

    const handleWarehouseInputOnFocus = () => {
        setQueryWarehouse({...queryWarehouse, limit: 20});
    };

    const handleWarehouseInputOnBlur = () => {
        setWarehouses([]);
    };

    useEffect(() => {
        if (warehouseName.length < 1) {
            setWarehouses([]);
            return;
        }
        if (warehouseName && warehouse.name != warehouseName)
            setQueryWarehouse({...queryWarehouse, limit: 50, filter: { name: warehouseName }});
    }, [warehouseName]);

    useEffect(() => {
        if (!warehouse.id) return;
        setWarehouseName(warehouse.name);
        setWarehouseId(warehouse.id);
        setWarehouses([]);
    }, [warehouse]);

    const getCenterWarehouse = () => {
        const query = {filter: {code: '1000'}}
        axiosPrivate.get('/warehouses/', { params: query }).then((res) => {
            if (res.status == 200) setCenterWarehouse(res.data.data[0]);
        });
    }

    const getWarehouses = () => {
        if (!isAdmin) {
            const query = { filter: {id: auth?.user?.warehouseId}}
            axiosPrivate.post('/warehouses/report-status', query).then((res) => {
                if (res.status == 200) setWarehouses(res.data);
            });
        } else {
            axiosPrivate.post('/warehouses/report-status', queryWarehouse).then((res) => {
                if (res.status == 200) {
                    // remove 1.st warehouse (Center)
                    let data = res.data.filter(item => item.code != '1000');
                    setWarehouses(data);
                }
            });
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const requiredInputs = [
            { key: number, value: t('INVOICE_NUMBER_REQUIRED') },
            { key: warehouseId, value: t('WAREHOUSE_REQUIRED') },
            { key: productClassificationId, value: t('PRODUCT_CLASSIFICATION_REQUIRED') },
            { key: !(requestFormItemsTemp.length < 1), value: `${t('DATA_NOT_ENTERED_YET')} (${t('PRODUCTS')})` },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        setLoading(true);
        const data = {
            number, warehouseId, productClassificationId, note,
            director, deputyOfDirector, generalAccountant, items: requestFormItemsTemp
        };

        axiosPrivate.post('/request-forms/create', data).then((res) => {
            if (res.status == 200) {
                setToast({ content: res.data.msg, variant: 'success', title: t('SUCCESS'), show: true });
                navigate(-1);
            }
        }).catch((err) => {
            if (!err?.response) {
                setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
                return;
            }
            switch(err.response.status) {
                case 406: setToast({ content: t('NOT_ALLOWED'), variant: 'danger', title: t('ERROR'), show: true }); break;
                default : setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true }); break;
            }
        }).finally(() => {setLoading(false)});
    }

    return (
        <WarehouseWrapper loading={loading} breadcrumbs={breadcrumbs}>
             <div className="head-long-page">
                <span className="fs-5 bold fg-dark">{t('NEW')} {t('REQUEST_FORM')}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={() => {setImportModal(true)}} icon={faFileImport} text={t('IMPORT')} className={"bg-pink-700 bg-gradient text-white"}/>
                    <ButtonU onSubmit={() => {setModalCancel(true)}} icon={faCancel} text={t('CANCEL')} className={"bg-red-400 bg-gradient text-white"}/>
                    <ButtonU onSubmit={handleSubmit} icon={faSave} text={t('SAVE')} className={"bg-green-600 bg-gradient text-white"} disabled={!warehouse?.reportStatus}/>
                </div>
            </div>

            <div className="my-2 py-2 px-4 mt-5">
                <div className="row">
                    {(warehouse?.reportStatus != undefined && !warehouse?.reportStatus) && <span className="bg-red-400 text-white py-1 px-3"><FontAwesomeIcon icon={faTriangleExclamation} className="me-2"/>{t('REPORT')}: <span className="bold">{t('OPEN')}</span></span>}
                    <InputLabeled label={t('NUMBER')} type={'text'} value={number} setValue={setNumber} hint={t('NUMBER')} className={'col-md-3'} required={true}/>
                    <SelectLabeled label={t('PRODUCT_CLASSIFICATION')} items={productClassifications} value={productClassificationId} setValue={setProductClassificationId} className={'col-md-3'} required={true}/>
                    <div className="col-md-3">
                        <InputLabeled label={t('WAREHOUSE')} type={'text'} value={warehouseName} setValue={setWarehouseName} hint={`${t('WAREHOUSE')} ${t('FIND')}`} innerRef={warehouseNameRef} onFocus={handleWarehouseInputOnFocus} onBlur={handleWarehouseInputOnBlur} required={true}/>
                        <FoundWarehouses items={warehouses} setItems={setWarehouses} setItem={setWarehouse}/>
                    </div>
                    <div className="d-flex flex-column my-2">
                        <div className="d-flex flex-row font-14">
                            <span className="text-secondary medium">{t('FROM_WHOM')}:</span>
                            <span className="fg-dark ms-2">{t('HEAD_NURSE')} {centerWarehouse?.user?.fullName}</span>
                        </div>
                        <div className="d-flex flex-row font-14">
                            <span className="text-secondary medium">{t('TO_WHOM')}:</span>
                            <span className="fg-dark ms-2">{warehouse?.department?.name} - {warehouse?.user?.fullName}</span>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="d-flex flex-column border my-2 px-2 py-1">
                            <div className="d-flex align-items-center justify-content-between mb-1">
                                <span className="font-18 medium fg-dark">{t('PRODUCTS')} ({requestFormItemsTemp.length})</span>
                                <ButtonU onSubmit={() => {setRequestFormItemModal(true)}} icon={faAdd} className={'bg-blue-700 bg-gradient text-white'}/>
                            </div>
                            <RequestFormItemsTemp items={requestFormItemsTemp} setItems={setRequestFormItemsTemp} productClassificationId={productClassificationId}/>
                        </div>
                    </div>
                    <InputLabeled label={t('DIRECTOR')} type={'text'} value={director} setValue={setDirector} hint={t('FULLNAME')} className={'col-md-6'} required={true}/>
                    <InputLabeled label={t('DEPUTY_OF_DIRECTOR')} type={'text'} value={deputyOfDirector} setValue={setDeputyOfDirector} hint={t('FULLNAME')} className={'col-md-6'} required={true}/>
                    <InputLabeled label={t('GENERAL_ACCOUNTANT')} type={'text'} value={generalAccountant} setValue={setGeneralAccountant} hint={t('FULLNAME')} className={'col-md-6'} required={true}/>
                    <div className="col-md-6"></div>
                    <InputAreaLabeled label={t('NOTE')} rows={3} value={note} setValue={setNote} hint={t('NOTE')} className={'col-md-6'}/>
                </div>
            </div>
            <ImportFile
                items={requestFormItemsTemp}
                setItems={setRequestFormItemsTemp}
                modalShow={importModal}
                setModalShow={setImportModal}
                toast={toast}
                setToast={setToast}
            />
            <ItemCreate
                items={requestFormItemsTemp}
                setItems={setRequestFormItemsTemp}
                productClassificationId={productClassificationId}
                modalShow={requestFormItemModal}
                setModalShow={setRequestFormItemModal}
            />
            <Cancel modalShow={modalCancel} setModalShow={setModalCancel}/>
            <ToastU toast={toast} setToast={setToast}/>
        </WarehouseWrapper>
    );
}

export default Create;