import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import useAuth from "../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { Link, useNavigate } from "react-router-dom";
import { faSearch, faArrowRotateRight, faTableCells, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Utils from "../../../../../utils";
import ReportsWrapper from "../ReportsWrapper";
import InputField from "../../../../../components/form/InputField";
import TableStd from '../../../../../components/ui/TableStd';
import PaginationU from '../../../../../components/ui/PaginationU';
import LimitU from '../../../../../components/ui/LimitU';
import DataNotFound from '../../../../../components/ui/DataNotFound';
import SortU from '../../../../../components/ui/SortU';
import ButtonU from "../../../../../components/ui/ButtonU";
import Filter from "./Filter";
import Status from "../../../../../components/ui/Status";
import { useTranslation } from "react-i18next";
import SelectProductClassification from "../../Warehouse/components/SelectProductClassification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Months from "./../../../../../components/ui/Months";
import ToastU from "../../../../../components/ui/ToastU";

const MonthlyReports = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const MONTHS = Months();

    const productClassifications = JSON.parse(localStorage.getItem('productClassifications'));

    const [monthlyProductsReports, setMonthlyProductsReports] = useState([]);
    const [monthlyProductsReportsCount, setMonthlyProductsReportsCount] = useState();

    const parsed = queryString.parse(location.search);
    const urlQuery = {
        page: parsed.page || 1,
        limit: 10,
        order: parsed.order || [['updatedAt', 'DESC']],
        filter: { createdAtLb: '', createdAtUb: '', productClassificationId: '1' }
    }
    const [query, setQuery] = useState(urlQuery);

    const [find, setfind] = useState('');

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('MAIN'), link: '/' },
        { title: t('REPORTS'), link: '/reports/general' },
        { title: t('MONTHLY_REPORTS'), active: true }
    ]

    useEffect(() => {
        if (ready) {
            getUpdatedUrl();
            getMonthlyProductsReports();
        }
    }, [ready, query]);

    const getUpdatedUrl = () => {
        parsed.page = query.page;
        window.history.replaceState({}, '', `${location.pathname}?${queryString.stringify(parsed)}`);
    }
    
    const getMonthlyProductsReports = () => {
        setLoading(true);
        axiosPrivate.get('/monthly-products-reports', { params: query }).then((res) => {
            if (res.status == 200) {
                setMonthlyProductsReports(res.data.data);
                setMonthlyProductsReportsCount(res.data.count);
            }
        }).catch((err) => {
            if (!err?.response) {
                setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
                return;
            }
            switch(err.response.status) {
                case 404: setToast({ content: t('DATA_NOT_FOUND'), variant: 'warning', title: t('NOT_FOUND'), show: true }); break;
                default : setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true }); break;
            }
        }).finally(() => {setLoading(false)});
    }

    useEffect(() => {
        setQuery({...query, filter: { ...query.filter, number: find }});
    }, [find]);

    const refresh = () => { getMonthlyProductsReports() }
    
    const modelProps = [
        { 
            name: <SortU title={t('NUMBER')} attr={'number'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop.number}</>) } 
        },
        {
            name: t('WAREHOUSE'),
            value: (prop) => { return(<>{prop?.warehouse?.name}</>) }
        },
        {
            name: t('PRODUCT_CLASSIFICATION'),
            value: (prop) => { return(<>{prop?.productClassification?.name}</>) }
        },
        {
            name: t('PRODUCT_COUNT'),
            value: (prop) => { return(<>{prop?.monthlyProductsReportItemsCount}</>) }
        },
        {
            name: <SortU title={t('CLOSED')} attr={'closed'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<Status status={prop.closed}/>) }
        },
        {
            name: <SortU title={t('MONTH')} attr={'createdAt'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<span className="badge bg-orange-400 small-x medium">{MONTHS.get(Utils.getTimestampMonth(prop.createdAt))}</span>) }
        },
        {
            name: <SortU title={t('CREATED_DATE')} attr={'createdAt'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{Utils.getTimestampDateHm(prop.createdAt)}</>) }
        }
    ];

    return (
        <ReportsWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <div className="d-flex flex-row align-items-center">
                    <span className="fs-5 bold fg-dark">{t('MONTHLY_REPORTS')}</span>
                </div>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={() => {navigate(`/reports/monthly-reports/report`)}} className={'bg-blue-500 border-info bg-gradient text-white me-2'} icon={faTableCells} text={<span>{t('REPORT')}<FontAwesomeIcon icon={faChevronRight} className="ms-2"/></span>}/>
                    <SelectProductClassification items={productClassifications} query={query} setQuery={setQuery}/>
                    <ButtonU onSubmit={refresh} className={'border-dark fg-dark'} icon={faArrowRotateRight}/>
                    <InputField type={"text"} value={find} setValue={setfind} hint={`${t('NUMBER')}`} icon={faSearch} formClass="input-field-container me-1 font-14" inputClass="input-field-outline"/> 
                    <Filter query={query} setQuery={setQuery} />
                </div>
            </div>
            { monthlyProductsReportsCount ?
                <div className="mt-5">
                    <TableStd modelProps={modelProps} data={monthlyProductsReports} count={monthlyProductsReportsCount} query={query}/>
                    <div className='d-flex align-items-center justify-content-between'>
                        <LimitU size={"sm"} filter={query} setFilter={setQuery}/>
                        <PaginationU filter={query} setFilter={setQuery} count={monthlyProductsReportsCount} />
                    </div>
                </div>
                : <DataNotFound/>
            }
            <ToastU toast={toast} setToast={setToast}/>    
        </ReportsWrapper>
    );
}

export default MonthlyReports;