import { useEffect, useState } from "react";
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import ModalU from '../../../../../../components/ui/ModalU';
import Utils from "../../../../../../utils";
import InputLabeled from '../../../../../../components/form/InputLabeled';
import InputAreaLabeled from '../../../../../../components/form/InputAreaLabeled';
import SelectLabeled from '../../../../../../components/form/SelectLabeled';
import { useTranslation } from "react-i18next";

const Update = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    const item = props.item;
    
    const [paymentTerms, setPaymentTerms] = useState('');
    const [paymentDetail, setPaymentDetail] = useState('');
    const [note, setNote] = useState('');
    const [total, setTotal] = useState('');
    const [paid, setPaid] = useState('');
    const [closed, setClosed] = useState();

    const ENUM_CLOSED = [ { id: true, name: t('CLOSED')}, { id: false, name: t('OPEN')} ];

    useEffect(() => {
        setPaymentTerms(item.paymentTerms);
        setPaymentDetail(item.paymentDetail);
        setNote(item.note);
        setTotal(item.total);
        setPaid(item.paid);
        setClosed(item.closed);
    }, [item])

    const handleSubmit = (event) => {
        event.preventDefault();

        const requiredInputs = [
            { key: total, value: t('TOTAL_REQUIRED') },
            { key: paid, value: t('PAID_REQUIRED') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            props.setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        const data = { paymentTerms, paymentDetail, note, paid, closed };

        axiosPrivate.put('/invoices/update/'+item.id, data).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {props.setLoading(false)});
    }

    return (
        <ModalU show={props.modalShow} size={''} title={`${t('INVOICE')} [${t('EDIT')}]`}
            actionbtntext={t('UPDATE')}
            actionbtnclassname={"bg-green-700 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit} className="row">
                <InputAreaLabeled label={t('PAYMENT_TERMS')} rows={5} value={paymentTerms} setValue={setPaymentTerms} hint={t('PAYMENT_TERMS')} className={'col-md-6'}/>
                <InputAreaLabeled label={t('PAYMENT_DETAILS')} rows={5} value={paymentDetail} setValue={setPaymentDetail} hint={t('PAYMENT_DETAILS')} className={'col-md-6'}/>
                <InputLabeled label={t('TOTAL')} type={'number'} value={total} setValue={setTotal} hint={'1000'} className={'col-md-6'} required={true} disabled={true}/>
                <InputLabeled label={t('PAID')} type={'number'} value={paid} setValue={setPaid} hint={'500'} className={'col-md-6'} required={true}/>
                <SelectLabeled label={t('STATUS')} items={ENUM_CLOSED} value={closed} setValue={setClosed} className={'col-md-6'} required={true}/>
                <InputAreaLabeled label={t('NOTE')} rows={3} value={note} setValue={setNote} hint={t('NOTE')} className={'col-md-12'}/>
            </form>
        </ModalU>
    );
}

export default Update