import { useState } from 'react';
import useAuth from '../../../../../../../../hooks/useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import TableStd from '../../../../../../../../components/ui/TableStd';
import Edit from './Edit';
import { useTranslation } from "react-i18next";

const RequestFormItems = ({...props}) => {
    const { t } = useTranslation();
    const { isAdmin } = useAuth();
    const [modalEdit, setModalEdit] = useState(false);

    const [itemToBe, setItemToBe] = useState({});

    const modelProps = [ 
        {
            name: t('BAR_CODE'),
            value: (prop) => { return(<>{prop?.product?.barcode}</>) }
        },
        {
            name: t('PRODUCT'),
            value: (prop) => { return(<>{prop?.product?.name}</>) } 
        },
        {
            name: t('REQUESTED'),
            value: (prop) => { return(<>{parseFloat(prop?.requestedQuantity)?.toFixed(2)}</>) }
        },
        {
            name: t('SPENT'),
            value: (prop) => { return(<>{prop?.quantity && parseFloat(prop?.quantity)?.toFixed(2)}</>) }
        },
        {
            name: t('UNIT_PRICE'),
            value: (prop) => { return(<>{prop?.unitPrice && parseFloat(prop?.unitPrice)?.toFixed(2)}</>) }
        },
        {
            name: t('TOTAL'),
            value: (prop) => { return(<>{prop?.quantity && (parseFloat(prop?.quantity) * parseFloat(prop?.unitPrice)).toFixed(2)}</>) }
        },
        {
            name: "",
            value: (prop) => {
                return (
                    <div className='d-flex flex-row'>
                        <span className='me-3'
                            onClick={() => {
                                if (!isAdmin) return;
                                setItemToBe(prop);
                                setModalEdit(isAdmin);
                            }}>
                            <FontAwesomeIcon className={`${isAdmin ? 'fg-blue-500' : 'fg-gray'} font-12`} icon={faPenToSquare}/>
                        </span>
                    </div>
                )
            }
        }
    ];

    return (
        <div style={{overflowY: "auto", minHeight: "100px", maxHeight: "250px"}}>
            <TableStd modelProps={modelProps} data={props?.items}/>
            <Edit
                modalShow={modalEdit}
                setModalShow={setModalEdit}
                item={itemToBe}
                items={props.items}
                setItems={props.setItems}
                productClassificationId={props.productClassificationId}
            />
        </div>
    )
}

export default RequestFormItems;