import { useEffect, useState } from "react";
import useAuth from "../../../../hooks/useAuth";
import MainWrapper from "../../layouts/MainWrapper";
import SideBar from "../../../../layouts/SideBar";
import { useTranslation } from "react-i18next";
import Keys from "../../../../utils/constants";
import icCube from './../../../../assets/svg/ic_cube_outline.svg';
import icWarehouse from './../../../../assets/svg/ic_warehouse_outline.svg';
import icMemoCheck from './../../../../assets/svg/ic_memo_check.svg';
import icFileArrowUpAlt from './../../../../assets/svg/ic_file_arrow_up_alt.svg';
import icFileArrowDownAlt from './../../../../assets/svg/ic_file_arrow_down_alt.svg';

const WarehouseWrapper = (props) => {
    const { t } = useTranslation();
    const { isAdmin } = useAuth();

    const productClassifications = JSON.parse(localStorage.getItem('productClassifications'));
    const [productCatalogLinks, setProductCatalogLinks] = useState([]);

    const items = [
        { name: t('REMAINDER'), id: "remainder", icon: icCube, links: []},
        { name: t('INCOMES'), id: "incomes", icon: icFileArrowDownAlt, links: Keys.WAREHOUSE_INCOMES_LINKS},
        { name: t('EXPENSES'), id: "expenses", icon: icFileArrowUpAlt, links: Keys.WAREHOUSE_EXPENSES_LINKS},
        { name: t('REQUEST_FORMS'), id: "request_forms", icon: icMemoCheck, links: Keys.WAREHOUSE_REQUEST_FORMS_LINKS},
    ];

    if(isAdmin)
        items.push({ name: t('WAREHOUSES'), id: "warehouse", icon: icWarehouse, links: Keys.WAREHOUSE_WAREHOUSES_LINKS});

    useEffect(() => {
        setNavBarLinks();
    }, []);

    const setNavBarLinks = () => {
        productClassifications.forEach((item) => {
            items[0].links.push(`/warehouse/stock-products/${item.id}`);
        });

        setProductCatalogLinks(items);
    }


    return (
        <MainWrapper loading={props.loading} breadcrumbs={props.breadcrumbs}>
            <div className="main-page">
                <SideBar items={productCatalogLinks}/>
                <div className="body-page">
                    { props.children }
                </div>
            </div>
        </MainWrapper>
    );
};

export default WarehouseWrapper;