import { useEffect, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import Utils from "../../../utils";
import Wrapper from "../../../layouts/Wrapper";
import NavBar from "../../../layouts/NavBar";
import Keys from "../../../utils/constants";
import icDashboard from '../../../assets/svg/ic_dashboard.svg';
import icProduct from '../../../assets/svg/ic_cube.svg';
import icDocument from '../../../assets/svg/ic_receipt.svg';
import icWarehouse from '../../../assets/svg/ic_warehouse.svg';
import icBuilding from '../../../assets/svg/ic_building.svg';
import icFinance from '../../../assets/svg/ic_chart.svg';
import { useTranslation } from "react-i18next";

const MainWrapper = ({...props}) => {
    const { t } = useTranslation();
    const { ready, auth } = useAuth();

    const productClassifications = JSON.parse(localStorage.getItem('productClassifications'));

    let items = [   
        { name: t('DASHBOARD'), id: "1000", links: Keys.DASHBOARD_LINKS, icon: icDashboard },
        { name: t('WAREHOUSE'), id: "1003", links: [], icon: icWarehouse },
        { name: t('INVOICE'), id: "1002", links: [], icon: icDocument },
        { name: t('PRODUCT_CATALOG'), id: "1001", links: [], icon: icProduct },
        { name: t('COMPANY'), id: "1004", links: Keys.COMPANY_LINKS, icon: icBuilding },
        { name: t('REPORTS'), id: "1005", links: Keys.REPORTS_LINKS, icon: icFinance },
    ];

    const [allowedItems, setAllowedItems] = useState([]);

    useEffect(() => {
        if (!ready) return;
        setNavBarLinks();
    }, [auth, t]);

    const setNavBarLinks = async() => {
        setProductCatalogLinks();
        setAllowedItems(items.filter(item => Utils.allowedRole(auth, item.id, 'read')));
    }

    const setProductCatalogLinks = () => {
        productClassifications?.forEach((item) => {
            items[3].links.push(`/product-catalog/${item.id}/products`);
            items[3].links.push(`/product-catalog/${item.id}/products/:id`);
            items[3].links.push(`/product-catalog/${item.id}/products/create`);

            items[2].links.push(`/invoice/${item.id}/invoices`);
            items[2].links.push(`/invoice/${item.id}/invoices/:id`);
            items[2].links.push(`/invoice/${item.id}/invoices/create`);

            items[1].links.push(`/warehouse/stock-products/${item.id}`);
            items[1].links.concat(Keys.WAREHOUSE_LINKS);
        });
    }

    return (
        <Wrapper loading={props.loading} breadcrumbs={props.breadcrumbs}>
            <NavBar items={allowedItems} wrapper={'MAIN'}/>
            {props.children}
        </Wrapper>
    );
};

export default MainWrapper;