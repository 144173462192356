import ModalU from './ModalU';
import { useTranslation } from 'react-i18next';

const Accept = ({...props}) => {
    const { t } = useTranslation();

    const handleSubmit = () => { props.action() }

    return (
        <ModalU show={props.modalShow} size={''} title={t('WARNING')}
            actionbtntext={t('ACCEPT')}
            actionbtnclassname={"bg-blue-500 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <div className="d-flex flex-column align-items-center p-4">
                <span className="medium fg-blue-300">{t('DATA_ACCEPTANCE_CONFIRMATION')}</span>
                <span className="fg-dark mt-2 text-center font-12 mx-3">{t('DATA_ACCEPTANCE_CONFIRMATION_DESC')}</span>
            </div>
        </ModalU>
    );
}

export default Accept