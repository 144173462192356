import { useState, useEffect } from "react";
import useAuth from '../../../../../../../hooks/useAuth';
import useAxiosPrivate from '../../../../../../../hooks/useAxiosPrivate';
import InputLabeled from "../../../../../../../components/form/InputLabeled";
import InputAreaLabeled from "../../../../../../../components/form/InputAreaLabeled";
import ModalU from '../../../../../../../components/ui/ModalU';
import Utils from "../../../../../../../utils";
import FoundProducts from "../../../../Invoice/components/FoundProducts";
import ToastU from "../../../../../../../components/ui/ToastU";
import { useTranslation } from "react-i18next";

const Edit = ({...props}) => {
    const { t } = useTranslation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    const requestFormItem = props.item;

    const [productId, setProductId] = useState('');
    const [requestedQuantity, setRequestedQuantity] = useState();
    const [description, setDescription] = useState('');

    const [productName, setProductName] = useState('');

    const [product, setProduct] = useState({});

    const [products, setProducts] = useState([]);

    const [queryProduct, setQueryProduct] = useState({
        limit:0, order: [['createdAt', 'ASC']], filter: { productClassificationId: props.productClassificationId, name: '' }
    });

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    useEffect(() => {
        setQueryProduct({
            ...queryProduct,
            filter: {...queryProduct.filter, productClassificationId: props.productClassificationId }
        });
    }, [props.productClassificationId]);

    useEffect(() => {
        if (!requestFormItem.productId) return;
        setProductId(requestFormItem.productId);
        setRequestedQuantity(requestFormItem.requestedQuantity);
        setDescription(requestFormItem.description);

        setProduct(requestFormItem.product);
        setProductName(requestFormItem.product.name);
    }, [requestFormItem]);

    useEffect(() => {
        if (ready) {
            getProducts();
        }
    }, [queryProduct]);

    useEffect(() => {
        if (productName.length < 1) {
            setProducts([]);
            return;
        }
        if (productName)
            setQueryProduct({
                ...queryProduct,
                limit: 100,
                filter: {...queryProduct.filter, name: productName }
            });
    }, [productName]);

    useEffect(() => {
        if (!product.id) return;
        setProductName(`${product?.name} [${t('BAR_CODE')}: ${product?.barcode}]`);
        setProductId(product?.id);
        setProducts([]);
    }, [product]);

    const getProducts = () => {
        if (!props.productClassificationId && productName) {
            setToast({ content: t('PRODUCT_CLASSIFICATION_REQUIRED'), variant: 'danger', title: t('ERROR'), show: true });
            return
        }
        axiosPrivate.get('/products', { params: queryProduct }).then((res) => {
            if (res.status == 200) setProducts(res.data.data);
        });
    }

    const editTempRequestFormItem = () => {
        const requiredInputs = [
            { key: productId, value: t('PRODUCT_REQUIRED') },
            { key: requestedQuantity, value: t('QUANTITY_REQUIRED') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        const updatedItems = props.items.filter(item => item.productId !== requestFormItem.productId)

        props.setItems(
            [...updatedItems, {product, productId, requestedQuantity, description}]
        );

        props.setModalShow(false);
    }

    return (
        <ModalU show={props.modalShow} size={''} title={`${t('PRODUCT')} [${t('EDIT')}]`}
            actionbtntext={t('SAVE')}
            actionbtnclassname={"bg-green-700 font-14 medium text-white"}
            onSubmit={editTempRequestFormItem} onHide={props.setModalShow}>
            <div className='row mx-2'>
                <div className="col-md-12">
                    <InputLabeled label={`${t('PRODUCT')}/${t('BAR_CODE')}`} type={'text'} value={productName} setValue={setProductName} hint={`${t('PRODUCT')} ${t('NAME')}, ${t('BAR_CODE')}`} required={true}/>
                    <FoundProducts items={products} setItem={setProduct}/>
                </div>
                <InputLabeled label={<span>{t('REQUESTED')} ({product?.unit?.name})</span>} type={'number'} value={requestedQuantity} setValue={setRequestedQuantity} hint={"3"} className={'col-md-6'} required={true}/>
                <div className="col-md-6"></div>
                <InputAreaLabeled label={t('DEFINITION')} rows={3} value={description} setValue={setDescription} hint={t('DEFINITION')} className={'col-md-12'}/>
            </div>
            <ToastU toast={toast} setToast={setToast}/>
        </ModalU>
    );
}

export default Edit