import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const SelectProductClassification = ({...props}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const items = props.items;

    const handleChange = (event) => {
        const value = event.target.value;
        props.setValue(value);
        navigate(`/invoice/${value}/invoices/create`)
    }

    return (
        <div className="d-flex flex-row align-items-center mb-3">
            <div className={`d-flex flex-column`}>
                <span className="fg-blue-500 medium mb-1">{t('PRODUCT_CLASSIFICATION')}</span>
                <select
                    value={props.value}
                    onChange={handleChange}
                    className={`rounded-2 border border-secondary px-2 py-2 font-14 bg-light`}>
                    { items.map((item) => {
                        return (<option value={item.id} key={item.id}>{item.name}</option>)
                    }) }
                </select>
            </div>
        </div>
    );
}

export default SelectProductClassification;