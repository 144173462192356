import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import useAuth from "../../../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../../../hooks/useAxiosPrivate';
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Keys from "../../../../../../../utils/constants";
import ButtonU from "../../../../../../../components/ui/ButtonU";
import ToastU from "../../../../../../../components/ui/ToastU";
import LabelU from "../../../../../../../components/ui/LabelU";
import Instructions from "../../../../../../../components/ui/Instructions";
import SwitchLabeled from "../../../../../../../components/form/SwitchLabeled";
import SelectLabeled from "../../../../../../../components/form/SelectLabeled";
import DataWrapper from "../../../DataWrapper";
import { useTranslation } from "react-i18next";

const Create = () => {
    const { t } = useTranslation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [username, setUsername] = useState('');
    const [validUsername, setValidUsername] = useState(false);
    const [usernameFocus, setUsernameFocus] = useState(false);

    const [firstName, setFirstName] = useState('');
    const [validFirstName, setValidFirstName] = useState(false);
    const [firstNameFocus, setFirstNameFocus] = useState(false);

    const [lastName, setLastName] = useState('');
    const [validLastName, setValidLastName] = useState(false);
    const [lastNameFocus, setLastNameFocus] = useState(false);

    const [middleName, setMiddleName] = useState('');
    const [validMiddleName, setValidMiddleName] = useState(false);
    const [middleNameFocus, setMiddleNameFocus] = useState(false);

    const [password, setPassword] = useState('');
    const [validPassword, setValidPassword] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);

    const [matchPassword, setMatchPassword] = useState('');
    const [validMatchPassword, setValidMatchPassword] = useState(false);
    const [matchPasswordFocus, setMatchPasswordFocus] = useState(false);

    const [roleId, setRoleId] = useState('');
    const [departmentId, setDepartmentId] = useState('');
    const [warehouseId, setWarehouseId] = useState('');

    const [verified, setVerified] = useState(true);
    const [active, setActive] = useState(true);

    const [roles, setRoles] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [warehouses, setWarehouses] = useState([]);

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('SETTINGS'), link: '/settings/profile'}, { title: t('DATABASE'), link: '/settings/data/general'},
        { title: t('ADMINISTRATION'), link: '/settings/data/administration' },
        { title: t('USERS'), link: '/settings/data/administration/users' },
        { title: t('NEW'), active: true }
    ]

    useEffect(() => {
        if (ready) {
            getRoles();
            getDepartments();
        }
    }, [ready]);

    useEffect(() => {
        if (ready)
            getWarehouses();
    }, [departmentId]);

    const getRoles = () => {
        setLoading(true);
        axiosPrivate.get('/roles').then((res) => {
            if (res.status == 200) setRoles(res.data.data);
        }).finally(() => {setLoading(false)});
    }

    const getDepartments = () => {
        setLoading(true);
        axiosPrivate.get('/departments').then((res) => {
            if (res.status == 200) setDepartments(res.data.data);
        }).finally(() => {setLoading(false)});
    }

    const getWarehouses = () => {
        if (!departmentId) return;
        
        setLoading(true);
        const query = { order: [['order', 'ASC']], filter: { departmentId } };
        axiosPrivate.get('/warehouses', { params: query }).then((res) => {
            if (res.status == 200) setWarehouses(res.data.data);
        }).finally(() => {setLoading(false)});
    }

    useEffect(() => {
        setValidUsername(Keys.USERNAME_REGEX.test(username));
    }, [username]);

    useEffect(() => {
        setValidFirstName(Keys.NAME_REGEX.test(firstName));
    }, [firstName]);

    useEffect(() => {
        setValidLastName(Keys.NAME_REGEX.test(lastName));
    }, [lastName]);

    useEffect(() => {
        setValidMiddleName(Keys.NAME_REGEX.test(middleName));
    }, [middleName]);

    useEffect(() => {
        setValidPassword(Keys.PASSWORD_REGEX.test(password));
        const match = (password === matchPassword);
        setValidMatchPassword(match);
    }, [password, matchPassword]);

    useEffect(() => {
        setToast({...toast, content: ''});
    }, [username, firstName, lastName, password, matchPassword]);

    const handleSubmit = (event) => {
        event.preventDefault();

        const v1 = Keys.USERNAME_REGEX.test(username);
        const v2 = Keys.PASSWORD_REGEX.test(password);
        const v3 = Keys.NAME_REGEX.test(firstName);
        const v4 = Keys.NAME_REGEX.test(lastName);
        const v5 = roleId;

        if (!v1 || !v2 || !v3 || !v4 || !v5) {
            setToast({ content: t('INVALID_ENTRY'), variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        const data = { username, firstName, lastName, middleName, password, roleId, verified, active, departmentId, warehouseId };
        
        setLoading(true);
        axiosPrivate.post('/users/create', data).then((res) => {
            if (res.status == 200) {
                setToast({ content: t('SUCCESS'), variant: 'primary', title: t('SUCCESS'), show: true });
                navigate(-1);
            }
        }).catch((err) => {
            if (!err?.response) {
                setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
                return;
            }
            switch(err.response.status) {
                case 400: setToast({ content: t('SOMETHING_WENT_WRONG'), variant: 'danger', title: t('ERROR'), show: true }); break;
                default : setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true }); break;
            }
        }).finally(() => {setLoading(false)});
    }

    return (
        <DataWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <span className="bold fs-5 fg-dark">{`${t('NEW')} ${t('USER')}`}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={handleSubmit} className={'bg-green-700 text-white'} text={t('CREATE')} icon={faCheck} disabled={(!validUsername || !validPassword || !validMatchPassword || !validFirstName || !validLastName)}/>
                </div>
            </div>
            <form onSubmit={handleSubmit} noValidate className="mt-5 mx-2 mb-2 row">
                <span className="fg-blue-700 medium font-18 mb-3">{t('USER')}</span>
                <div className="col-md-3 my-2">
                    <LabelU htmlFor={"username"} label={t('USERNAME')} validAttr={validUsername} attr={username} attr2={true}/>
                    <input id="username"
                        onChange={(e) => setUsername(e.target.value)}
                        className="rounded-2 border px-2 py-1 small bg-light col-md-12"
                        placeholder={t('USERNAME')}
                        autoComplete="off"
                        aria-invalid={validUsername ? "false" : "true"}
                        aria-describedby="username_note"
                        onFocus={() => setUsernameFocus(true)}
                        onBlur={() => setUsernameFocus(false)}
                        required/>
                    <Instructions id={"username_note"} attrFocus={usernameFocus} attr={username} validAttr={validUsername} attrInstruction={t('USERNAME_INSTRUCTION')}/>
                </div>

                <div className="col-md-3 my-1">
                    <LabelU htmlFor={"firstName"} label={t('FIRSTNAME')} validAttr={validFirstName} attr={firstName} attr2={true}/>
                    <input id="firstName"
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="rounded-2 border px-2 py-1 small bg-light col-md-12"
                        placeholder={t('FIRSTNAME')}
                        autoComplete="off"
                        aria-invalid={validFirstName ? "false" : "true"}
                        aria-describedby="firstName_note"
                        onFocus={() => setFirstNameFocus(true)}
                        onBlur={() => setFirstNameFocus(false)}
                        required/>
                    <Instructions id={"firstName_note"} attrFocus={firstNameFocus} attr={firstName} validAttr={validFirstName} attrInstruction={t('NAME_INSTRUCTION')}/>
                </div>

                <div className="col-md-3 my-1">
                    <LabelU htmlFor={"lastName"} label={t('LASTNAME')} validAttr={validLastName} attr={lastName} attr2={true}/>
                    <input id="lastName"
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className="rounded-2 border px-2 py-1 small bg-light col-md-12"
                        placeholder={t('LASTNAME')}
                        autoComplete="off"
                        aria-invalid={validLastName ? "false" : "true"}
                        aria-describedby="lastName_note"
                        onFocus={() => setLastNameFocus(true)}
                        onBlur={() => setLastNameFocus(false)}
                        required/>
                    <Instructions id={"lastName_note"} attrFocus={lastNameFocus} attr={lastName} validAttr={validLastName} attrInstruction={t('NAME_INSTRUCTION')}/>
                </div>

                <div className="col-md-3 my-1">
                    <LabelU htmlFor={"middleName"} label={t('MIDDLENAME')} validAttr={validMiddleName} attr={middleName} attr2={true}/>
                    <input id="middleName"
                        type="text"
                        value={middleName}
                        onChange={(e) => setMiddleName(e.target.value)}
                        className="rounded-2 border px-2 py-1 small bg-light col-md-12"
                        placeholder={t('MIDDLENAME')}
                        autoComplete="off"
                        aria-invalid={validMiddleName ? "false" : "true"}
                        aria-describedby="middleName_note"
                        onFocus={() => setMiddleNameFocus(true)}
                        onBlur={() => setMiddleNameFocus(false)}
                        required/>
                    <Instructions id={"lastName_note"} attrFocus={middleNameFocus} attr={lastName} validAttr={validMiddleName} attrInstruction={t('NAME_INSTRUCTION')}/>
                </div>

                <hr className="mt-5"/>
                <span className="fg-blue-700 medium font-18">{t('PRIVACY')}</span>
                <SelectLabeled label={t('ROLE')} items={roles} value={roleId} setValue={setRoleId} className={'col-md-3'} required={true}/>
                <SwitchLabeled label={t('VERIFIED')} value={verified} setValue={setVerified} size="small" className={'col-md-1'} required={false}/>
                <SwitchLabeled label={t('ACTIVE')} value={active} setValue={setActive} size="small" className={'col-md-1'} required={false}/>
            
                <hr className="mt-5"/>
                <span className="fg-blue-700 medium font-18">{t('SECURITY')}</span>
                <div className="col-md-3 my-1">
                    <LabelU htmlFor={"password"} label={t('PASSWORD')} validAttr={validPassword} attr={password} attr2={true}/>
                    <input id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="rounded-2 border px-2 py-1 small bg-light col-md-12"
                        placeholder={t('PASSWORD')}
                        aria-invalid={validPassword ? "false" : "true"}
                        aria-describedby="password_note"
                        onFocus={() => setPasswordFocus(true)}
                        onBlur={() => setPasswordFocus(false)}
                        required/>
                    <Instructions id={"password_note"} attrFocus={passwordFocus} attr={password} validAttr={validPassword} attrInstruction={t('PASSWORD_INSTRUCTION')}/>
                </div>
                <div className="col-md-3 my-1">
                    <LabelU htmlFor={"confirm_password"} label={t('CONFIRM_PASSWORD')} validAttr={validMatchPassword} attr={matchPassword} attr2={matchPassword}/>
                    <input id="confirm_password"
                        value={matchPassword}
                        onChange={(e) => setMatchPassword(e.target.value)}
                        className="rounded-2 border px-2 py-1 small bg-light col-md-12"
                        placeholder={t('CONFIRM_PASSWORD')}
                        aria-invalid={validPassword ? "false" : "true"}
                        aria-describedby="confirm_password_note"
                        onFocus={() => setMatchPasswordFocus(true)}
                        onBlur={() => setMatchPasswordFocus(false)}
                        required/>
                    <Instructions id={"confirm_password_note"} attrFocus={matchPasswordFocus} attr={true} validAttr={validMatchPassword} attrInstruction={t('MATCH_PASSWORD_INSTRUCTION')}/>
                </div>

                <hr className="mt-5"/>
                <span className="fg-blue-700 medium font-18">{t('WORK')}</span>
                <SelectLabeled label={t('DEPARTMENT')} items={departments} value={departmentId} setValue={setDepartmentId} className={'col-md-3'} required={true}/>
                <SelectLabeled label={t('WAREHOUSE')} items={warehouses} value={warehouseId} setValue={setWarehouseId} className={'col-md-3'} required={true}/>
            </form>
            <ToastU toast={toast} setToast={setToast}/>
        </DataWrapper>
    );
}

export default Create;