import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import useAuth from "../../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import convertSize from "convert-size";
import { faAdd, faSearch, faArrowRotateRight, faDownload } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import DataWrapper from "../../DataWrapper";
import InputField from "../../../../../../components/form/InputField";
import TableStd from '../../../../../../components/ui/TableStd';
import Filter from "./Filter";
import PaginationU from '../../../../../../components/ui/PaginationU';
import LimitU from '../../../../../../components/ui/LimitU';
import DataNotFound from '../../../../../../components/ui/DataNotFound';
import ButtonU from "../../../../../../components/ui/ButtonU";
import SortU from "../../../../../../components/ui/SortU";
import ToastU from "../../../../../../components/ui/ToastU";
import Create from "./Create";
import Delete from "./Delete";
import Utils from "../../../../../../utils";
import Actions from "../../../../../../components/ui/Actions";
import { useTranslation } from "react-i18next";

const Backup = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(false);

    const [brands, setBrands] = useState([]);
    const [brandsCount, setBrandsCount] = useState();

    const parsed = queryString.parse(location.search);
    const urlQuery = {
        page: parsed.page || 1,
        limit: 10,
        order: parsed.order || [['name', 'ASC']],
        filter: { createdAtLb: '', createdAtUb: '' }
    }
    const [query, setQuery] = useState(urlQuery);

    const [find, setfind] = useState('');

    const [modalCreate, setModalCreate] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);

    const [itemToBe, setItemToBe] = useState({});

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('SETTINGS'), link: '/settings/profile'}, { title: t('DATABASE'), link: '/settings/data/general'}, { title: t('ADMINISTRATION'), link: '/settings/data/administration' }, { title: t('BACKUPS'), active: true }
    ]

    useEffect(() => {
        if (ready) {
            getUpdatedUrl();
            getBrands();
        }
    }, [ready, query, toast]);

    const getUpdatedUrl = () => {
        parsed.page = query.page;
        window.history.replaceState({}, '', `${location.pathname}?${queryString.stringify(parsed)}`);
    }

    const getBrands = () => {
        setLoading(true);
        axiosPrivate.get('/backups', { params: query }).then((res) => {
            if (res.status == 200) {
                setBrands(res.data.data);
                setBrandsCount(res.data.count);
            }
        }).finally(() => {setLoading(false)});
    }

    useEffect(() => {
        setQuery({...query, filter: { name: find }});
    }, [find]);

    const createNew = () => { setModalCreate(true) }

    const downloadFile = (item) => {
        setLoading(true);
        axiosPrivate.get('/backups/download/'+item.file, { responseType: 'blob'}).then((res) => {
            if (res.status == 200) {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', item.file);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }).finally(() => {setLoading(false)});
    }

    const refresh = () => { getBrands() }

    const modelProps = [
        { 
            name: <SortU title={t('NAME')} attr={'name'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop.name}</>) } 
        },
        { 
            name: <SortU title={`${t('FILE')}`} attr={'file'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop.file}</>) } 
        },
        { 
            name: <SortU title={t('SIZE')} attr={'size'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{convertSize(prop.size, {accuracy: 3})}</>) } 
        },
        {
            name: <SortU title={t('REGISTRATION_DATE')} attr={'createdAt'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{Utils.getTimestampDateHm(prop.createdAt)}</>) }
        },
        {
            name: "",
            value: (prop) => {
                const options =[
                    {
                        icon: faDownload,
                        style: "bg-orange-400 text-white rounded-2",
                        onClick: () => {
                            downloadFile(prop);
                        }
                    },
                    {
                        icon: faTrashCan,
                        style: "bg-red-400 text-white rounded-2",
                        onClick: () => { 
                            setItemToBe(prop);
                            setModalDelete(true);
                        }
                    }
                ]
                return (<Actions items={options}/>)
            }
        }
    ];

    return (
        <DataWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <span className="bold fs-5 fg-dark">{t('BACKUPS')}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={refresh} className={'border-dark fg-dark'} icon={faArrowRotateRight}/>
                    <InputField type={"text"} value={find} setValue={setfind} hint={t('FIND')} icon={faSearch} formClass="input-field-container me-1 font-14" inputClass="input-field-outline"/> 
                    <Filter query={query} setQuery={setQuery} />
                    <ButtonU onSubmit={createNew} className={'border-success fg-green-500'} icon={faAdd} text={t('ADD')}/>
                </div>
            </div>
            { brandsCount ?
                <div className="mt-5">
                    <TableStd modelProps={modelProps} data={brands} count={brandsCount} query={query}/>
                    <div className='d-flex align-items-center justify-content-between'>
                        <LimitU size={"sm"} filter={query} setFilter={setQuery}/>
                        <PaginationU filter={query} setFilter={setQuery} count={brandsCount} />
                    </div>
                </div>
                : <DataNotFound/>
            }
            <Create
                modalShow={modalCreate}
                setModalShow={setModalCreate}
                toast={toast}
                setToast={setToast}
                setLoading={setLoading}
            />
            <Delete
                modalShow={modalDelete}
                setModalShow={setModalDelete}
                toast={toast}
                setToast={setToast}
                item={itemToBe}
                setLoading={setLoading}
            />
            <ToastU toast={toast} setToast={setToast}/>
            <div className="mb-5"></div>
        </DataWrapper>
    );
}

export default Backup;