import { useState, useEffect } from "react";
import useAuth from '../../../../../../../hooks/useAuth';
import useAxiosPrivate from '../../../../../../../hooks/useAxiosPrivate';
import { useParams } from "react-router-dom";
import InputLabeled from "../../../../../../../components/form/InputLabeled";
import InputAreaLabeled from "../../../../../../../components/form/InputAreaLabeled";
import ModalU from '../../../../../../../components/ui/ModalU';
import Utils from "../../../../../../../utils";
import FoundProducts from "../../../components/FoundProducts";
import ToastU from "../../../../../../../components/ui/ToastU";
import { useTranslation } from "react-i18next";

const Create = ({...props}) => {
    const { t } = useTranslation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const params = useParams();

    const [productId, setProductId] = useState('');
    const [quantity, setQuantity] = useState();
    const [unitPrice, setUnitPrice] = useState();
    const [issueDate, setIssueDate] = useState();
    const [expireDate, setExpireDate] = useState();
    const [description, setDescription] = useState('');

    const [productName, setProductName] = useState('');

    const [product, setProduct] = useState({});

    const [products, setProducts] = useState([]);

    const [queryProduct, setQueryProduct] = useState({
        limit:0, order: [['createdAt', 'ASC']], filter: { productClassificationId: params.pcid, name: '' }
    });

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    useEffect(() => {
        if (ready) {
            getProducts();
        }
    }, [queryProduct]);

    useEffect(() => {
        if (productName.length < 1) {
            setProducts([]);
            return;
        }
        if (productName)
            setQueryProduct({
                ...queryProduct,
                limit: 100,
                filter: {...queryProduct.filter, name: productName }
            });
    }, [productName]);

    useEffect(() => {
        if (!product.id) return;
        setProductName(`${product?.name} [${t('BAR_CODE')}: ${product?.barcode}]`);
        setProductId(product?.id);
        setProducts([]);
    }, [product]);

    const getProducts = () => {
        axiosPrivate.get('/products', { params: queryProduct }).then((res) => {
            if (res.status == 200) setProducts(res.data.data);
        });
    }

    const addTempInvoiceItem = () => {
        const dublicatedProduct = props.items.some((item) => item.productId == productId)
        const requiredInputs = [
            { key: productId, value: t('PRODUCT_REQUIRED') },
            { key: quantity, value: t('QUANTITY_REQUIRED') },
            { key: unitPrice, value: t('UNIT_PRICE_REQUIRED') },
            { key: !dublicatedProduct, value: t('DUPLICATE_PRODUCT') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }
        
        const invoiceItem = {product, productId, quantity, unitPrice, issueDate, expireDate, description};
        props.setItems([...props.items, invoiceItem]);
        clearField();
        props.setModalShow(false);
    }

    const clearField = () => {
        setProduct({});
        setProductName('');
        setProductId('');
        setQuantity();
        setUnitPrice();
        setIssueDate(undefined);
        setExpireDate(undefined);
        setDescription('');
    }

    return (
        <ModalU show={props.modalShow} size={''} title={t('PRODUCT')}
            actionbtntext={t('ADD')}
            actionbtnclassname={"bg-blue-700 font-14 medium text-white"}
            onSubmit={addTempInvoiceItem} onHide={props.setModalShow}>
            <div className='row mx-2'>
                <div className="col-md-12">
                    <InputLabeled label={`${t('PRODUCT')}/${t('BAR_CODE')}`} type={'text'} value={productName} setValue={setProductName} hint={`${t('PRODUCT')} ${t('NAME')}, ${t('BAR_CODE')}`} required={true}/>
                    <FoundProducts items={products} setItem={setProduct}/>
                </div>
                <InputLabeled label={<span>{t('QUANTITY')} ({product?.unit?.name})</span>} type={'number'} value={quantity} setValue={setQuantity} hint={"3"} className={'col-md-6'} required={true}/>
                <InputLabeled label={t('UNIT_PRICE')} type={'number'} value={unitPrice} setValue={setUnitPrice} hint={"50"} className={'col-md-6'} required={true}/>
                <InputLabeled label={t('ISSUE_DATE')} type={'date'} value={issueDate} setValue={setIssueDate} hint={t('ISSUE_DATE')} className={'col-md-6'}/>
                <InputLabeled label={t('EXPIRE_DATE')} type={'date'} value={expireDate} setValue={setExpireDate} hint={t('EXPIRE_DATE')} className={'col-md-6'}/>
                <InputAreaLabeled label={t('DEFINITION')} rows={3} value={description} setValue={setDescription} hint={t('DEFINITION')} className={'col-md-12'}/>
            </div>
            <ToastU toast={toast} setToast={setToast}/>
        </ModalU>
    );
}

export default Create