import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import useAuth from "../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { Link, useNavigate } from "react-router-dom";
import { faAdd, faSearch, faEllipsisV, faArrowRotateRight } from "@fortawesome/free-solid-svg-icons";
import { faEye, faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import Utils from "../../../../../utils";
import WarehouseWrapper from "../WarehouseWrapper";
import InputField from "../../../../../components/form/InputField";
import TableStd from '../../../../../components/ui/TableStd';
import DropdownU from '../../../../../components/ui/DropdownU'
import PaginationU from '../../../../../components/ui/PaginationU';
import LimitU from '../../../../../components/ui/LimitU';
import DataNotFound from '../../../../../components/ui/DataNotFound';
import SortU from '../../../../../components/ui/SortU';
import ButtonU from "../../../../../components/ui/ButtonU";
import Filter from "./Filter";
import Status from "../../../../../components/ui/Status";
import { useTranslation } from "react-i18next";
import SelectProductClassification from "../components/SelectProductClassification";
import Delete from "./components/Delete";
import ToastU from "./../../../../../components/ui/ToastU";
import Actions from "../../../../../components/ui/Actions";

const RequestForms = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const productClassifications = JSON.parse(localStorage.getItem('productClassifications'));

    const [requestForms, setRequestForms] = useState([]);
    const [requestFormsCount, setRequestFormsCount] = useState();

    const parsed = queryString.parse(location.search);
    const urlQuery = {
        page: parsed.page || 1,
        limit: 10,
        order: parsed.order || [['updatedAt', 'DESC']],
        filter: { createdAtLb: '', createdAtUb: '', productClassificationId: '1' }
    }
    const [query, setQuery] = useState(urlQuery);

    const [itemToBe, setItemToBe] = useState({});
    const [modalDelete, setModalDelete] = useState(false);

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const [find, setfind] = useState('');

    const breadcrumbs = [
        { title: t('MAIN'), link: '/' },
        { title: t('WAREHOUSE'), link: '/warehouse/stock-products/1' } ,
        { title: t('REQUEST_FORMS'), active: true}
    ]

    useEffect(() => {
        if (ready) {
            getUpdatedUrl();
            getRequestForms();
        }
    }, [ready, query]);

    const getUpdatedUrl = () => {
        parsed.page = query.page;
        window.history.replaceState({}, '', `${location.pathname}?${queryString.stringify(parsed)}`);
    }
    
    const getRequestForms = () => {
        setLoading(true);
        axiosPrivate.get('/request-forms', { params: query }).then((res) => {
            if (res.status == 200) {
                setRequestForms(res.data.data);
                setRequestFormsCount(res.data.count);
            }
        }).finally(() => {setLoading(false)});
    }

    useEffect(() => {
        setQuery({...query, filter: { ...query.filter, number: find }});
    }, [find]);

    const createNew = () => { navigate('/warehouse/request-forms/create'); }

    const refresh = () => { getRequestForms() }
    
    const modelProps = [
        { 
            name: <SortU title={t('NUMBER')} attr={'number'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<Link to={`/warehouse/request-forms/${prop.id}`} className='no-decor'>{prop.number}</Link>) } 
        },
        {
            name: <SortU title={t('WAREHOUSE')} attr={'warehouse'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.warehouse?.name}</>) }
        },
        {
            name: t('PRODUCT_COUNT'),
            value: (prop) => { return(<>{prop?.requestFormItemCount}</>) }
        },
        {
            name: <SortU title={t('TOTAL')} attr={'total'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.total?.toFixed(2)}</>) }
        },
        {
            name: <SortU title={t('ACCEPTED')} attr={'accepted'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<Status status={prop.accepted}/>) }
        },
        {
            name: <SortU title={t('PRODUCT_CLASSIFICATION')} attr={'productClassificationId'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.productClassification?.name}</>) }
        },
        {
            name: <SortU title={t('CREATED_DATE')} attr={'createdAt'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{Utils.getTimestampDateHm(prop.createdAt)}</>) }
        },
        {
            name: t('ACTION'),
            value: (prop) => {
                const options =[
                    {
                        icon: faEye,
                        style: "fg-blue-700 font-14",
                        onClick: () => { navigate(`/warehouse/request-forms/${prop.id}`); }
                    },
                    {
                        icon: faTrashCan,
                        style: "fg-red-300 font-14",
                        onClick: () => { 
                            setItemToBe(prop);
                            setModalDelete(true);
                        }
                    }
                ]
                return (<Actions items={options}/>)
            }
        }
    ];

    return (
        <WarehouseWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <div className="d-flex flex-row align-items-center">
                    <span className="fs-5 bold fg-dark">{t('REQUEST_FORMS')}</span>
                </div>
                <div className="d-flex align-items-center">
                    <SelectProductClassification items={productClassifications} query={query} setQuery={setQuery}/>
                    <ButtonU onSubmit={refresh} className={'border-dark fg-dark'} icon={faArrowRotateRight}/>
                    <InputField type={"text"} value={find} setValue={setfind} hint={`${t('REQUEST_FORM')} ${t('NUMBER')}`} icon={faSearch} formClass="input-field-container me-1 font-14" inputClass="input-field-outline"/> 
                    <Filter query={query} setQuery={setQuery} />
                    <ButtonU onSubmit={createNew} className={'bg-green-600 bg-gradient text-white'} icon={faAdd} text={t('ADD')}/>
                </div>
            </div>
            { requestFormsCount ?
                <div className="mt-5">
                    <TableStd modelProps={modelProps} data={requestForms} count={requestFormsCount} query={query}/>
                    <div className='d-flex align-items-center justify-content-between'>
                        <LimitU size={"sm"} filter={query} setFilter={setQuery}/>
                        <PaginationU filter={query} setFilter={setQuery} count={requestFormsCount} />
                    </div>
                </div>
                : <DataNotFound/>
            }
            <Delete
                modalShow={modalDelete}
                setModalShow={setModalDelete}
                toast={toast}
                setToast={setToast}
                refresh={refresh}
                item={itemToBe}
                setLoading={setLoading}
            />
            <ToastU toast={toast} setToast={setToast}/>
        </WarehouseWrapper>
    );
}

export default RequestForms;