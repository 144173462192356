import { useState, useEffect } from "react";
import useAuth from "../../../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../../../hooks/useAxiosPrivate';
import { useParams } from "react-router-dom";
import Utils from "../../../../../../../utils";
import ToastU from "../../../../../../../components/ui/ToastU";
import DataWrapper from "../../../DataWrapper";
import InputLabeled from '../../../../../../../components/form/InputLabeled';
import SelectLabeled from "../../../../../../../components/form/SelectLabeled";
import SwitchLabeled from "../../../../../../../components/form/SwitchLabeled";
import { useTranslation } from "react-i18next";

const User = ({...props}) => {
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const params = useParams();
    const { t } = useTranslation();
  
    const [loading, setLoading] = useState(false);

    const [user, setUser] = useState({});
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [username, setUsername] = useState('');
    const [roleId, setRoleId] = useState('');
    const [departmentId, setDepartmentId] = useState('');
    const [warehouseId, setWarehouseId] = useState('');
    const [verified, setVerified] = useState(false);
    const [active, setActive] = useState(false);

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPasswor] = useState('');
    const [forgotPassword, setForgotPassword] = useState(false);

    const [roles, setRoles] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    
    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('SETTINGS'), link: '/settings/profile'}, { title: t('DATABASE'), link: '/settings/data/general'},
        { title: t('ADMINISTRATION'), link: '/settings/data/administration' },
        { title: t('USERS'), link: '/settings/data/administration/users' },
        { title: user.username, active: true },
    ]

    useEffect(() => {
        if (ready) {
            getUser();
            getRoles();
            getDepartments();
        }
    }, [ready, toast]);

    useEffect(() => {
        if (ready)
            getWarehouses();
    }, [departmentId]);

    const getUser = () => {
        setLoading(true);
        axiosPrivate.get('/users/'+params.id).then((res) => {
            if (res.status == 200) {
                setUser(res.data.data);
            }
        }).finally(() => {setLoading(false)});
    }

    useEffect(() => {
        if (user) {
            setFirstName(user?.firstName);
            setLastName(user?.lastName);
            setMiddleName(user?.middleName);
            setUsername(user?.username);
            setRoleId(user?.userAuth?.roleId);
            setDepartmentId(user?.departmentId);
            setWarehouseId(user?.warehouseId);
            setVerified(user?.userAuth?.verified);
            setActive(user?.userAuth?.active);
        }
    }, [user]);

    useEffect(() => {
        clearPasswordForm();
    }, [forgotPassword]);

    const clearPasswordForm = () => {
        setCurrentPassword('');
        setNewPassword('');
        setConfirmNewPasswor('');
    }

    const getRoles = () => {
        setLoading(true);
        axiosPrivate.get('/roles').then((res) => {
            if (res.status == 200) setRoles(res.data.data);
        }).finally(() => {setLoading(false)});
    }

    const getDepartments = () => {
        setLoading(true);
        axiosPrivate.get('/departments').then((res) => {
            if (res.status == 200) setDepartments(res.data.data);
        }).finally(() => {setLoading(false)});
    }

    const getWarehouses = () => {
        if (!departmentId) return;
        
        setLoading(true);
        const query = { order: [['order', 'ASC']], filter: { departmentId } };
        axiosPrivate.get('/warehouses', { params: query }).then((res) => {
            if (res.status == 200) setWarehouses(res.data.data);
        }).finally(() => {setLoading(false)});
    }

    const handleSubmit = () => {
        const requiredInputs = [
            { key: firstName, value: t('NAME_REQUIRED') },
            { key: lastName, value: t('LASTNAME_REQUIRED') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        const data = { firstName, lastName, middleName, departmentId, warehouseId };

        axiosPrivate.put('/users/update/'+user.id, data).then((res) => {
            if (res.status === 200) {
                setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) {
                setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
                return;
            }
            switch(err.response.status) {
                case 400: setToast({ content: t('SOMETHING_WENT_WRONG'), variant: 'danger', title: t('ERROR'), show: true }); break;
                default : setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true }); break;
            }
        }).finally(() => {setLoading(false)});
    }

    const handlePrivacyChange = () => {
        const data = { roleId, verified, active };

        axiosPrivate.put('/auth/update/'+user.id, data).then((res) => {
            if (res.status === 200) {
                setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {setLoading(false)});
    }

    const handlePasswordChange = () => {
        const requiredInputs = [
            { key: currentPassword, value: t('CURRENT_PASSWORD_REQUIRED') },
            { key: newPassword, value: t('NEW_PASSWORD_REQUIRED') },
            { key: confirmNewPassword, value: t('CONFIRM_NEW_PASSWORD_REQUIRED') },
            { key: newPassword === confirmNewPassword, value: t('MATCH_PASSWORD_INSTRUCTION') }
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        const data = { currentPassword, newPassword, confirmNewPassword };

        axiosPrivate.put('/auth/change-password/'+user.id, data).then((res) => {
            if (res.status == 200) {
                setToast({ content: t('PASSWORD_UPDATED'), variant: 'success', title: t('SUCCESS'), show: true });
                clearPasswordForm();
            }
        }).catch((err) => {
            if (!err?.response) {
                setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
                return;
            }
            switch(err.response.status) {
                case 400: setToast({ content: t('INVALID_ENTRY'), variant: 'danger', title: t('ERROR'), show: true }); break;
                case 402: setToast({ content: t('INCORRECT_PASSWORD'), variant: 'danger', title: t('ERROR'), show: true }); break;
                default : setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true }); break;
            }
        }).finally(() => {setLoading(false)});
    }

    const handlePasswordReset = () => {
        const requiredInputs = [
            { key: newPassword, value: t('NEW_PASSWORD_REQUIRED') },
            { key: confirmNewPassword, value: t('CONFIRM_NEW_PASSWORD_REQUIRED') },
            { key: newPassword === confirmNewPassword, value: t('MATCH_PASSWORD_INSTRUCTION') }
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        const data = { newPassword, confirmNewPassword };

        axiosPrivate.put('/auth/reset-password/'+user.id, data).then((res) => {
            if (res.status == 200) {
                setToast({ content: t('PASSWORD_UPDATED'), variant: 'success', title: t('SUCCESS'), show: true });
                clearPasswordForm();
            }
        }).catch((err) => {
            if (!err?.response) {
                setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
                return;
            }
            switch(err.response.status) {
                case 400: setToast({ content: t('INVALID_ENTRY'), variant: 'danger', title: t('ERROR'), show: true }); break;
                default : setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true }); break;
            }
        }).finally(() => {setLoading(false)});
    }
 
    return (
        <DataWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="m-3">
                <form className="row">
                    <div className='col-md-12 mb-3'>
                        <span className='medium font-18 fg-blue-700'>{t('USER')}</span>
                        <span onClick={handleSubmit} className='text-primary medium ms-4 bg-light border rounded-2 font-12 px-2 hover-cursor'>{t('UPDATE')}</span>
                    </div>
                    <InputLabeled label={t('USERNAME')} type={'text'} value={username} setValue={setUsername} hint={t('USERNAME')} className={'col-md-3'} disabled={true}/>
                    <div className="col-md-9"></div>
                    <InputLabeled label={t('FIRSTNAME')} type={'text'} value={firstName} setValue={setFirstName} hint={t('FIRSTNAME')} className={'col-md-3'} required={true}/>
                    <InputLabeled label={t('LASTNAME')} type={'text'} value={lastName} setValue={setLastName} hint={t('LASTNAME')} className={'col-md-3'} required={true}/>
                    <InputLabeled label={t('MIDDLENAME')} type={'text'} value={middleName} setValue={setMiddleName} hint={t('MIDDLENAME')} className={'col-md-3'} required={true}/>
                    <hr className='mt-5'/>
                    <div className='col-md-12'>
                        <span className='medium font-18 fg-blue-700'>{t('PRIVACY')}</span>
                        <span onClick={handlePrivacyChange} className='text-primary medium ms-4 bg-light border rounded-2 font-12 px-2 hover-cursor'>{t('UPDATE')}</span>
                    </div>
                    <SelectLabeled label={t('ROLE')} items={roles} value={roleId} setValue={setRoleId} className={'col-md-3'} required={true}/>
                    <SwitchLabeled label={t('VERIFIED')} value={verified} setValue={setVerified} size="small" className={'col-md-1'} required={false}/>
                    <SwitchLabeled label={t('ACTIVE')} value={active} setValue={setActive} size="small" className={'col-md-1'} required={false}/>
                    <hr className='mt-5'/>
                    <div className='col-md-12'>
                        <span className='medium font-18 fg-blue-700'>{t('SECURITY')}</span>
                        <span
                            onClick={() => {
                                if (!forgotPassword) handlePasswordChange();
                                else handlePasswordReset();
                            }}
                            className='text-primary medium ms-4 bg-light border rounded-2 font-12 px-2 py-1 hover-cursor'>{forgotPassword ? t('UPDATE') : t('EDIT')}
                        </span>
                    </div>
                    <SwitchLabeled label={t('FORGOT_PASSWORD')} value={forgotPassword} setValue={setForgotPassword} size="small" className={'my-2'} required={false}/>
                    {!forgotPassword && <InputLabeled label={`${t('CURRENT')} ${t('PASSWORD')}`} type={'text'} value={currentPassword} setValue={setCurrentPassword} className={'col-md-3'} bg={`bg-yellow-200`} required={true}/>}
                    <InputLabeled label={`${t('NEW')} ${t('PASSWORD')}`} type={'text'} value={newPassword} setValue={setNewPassword} className={'col-md-3'} bg={`${forgotPassword ? 'bg-blue-100' : 'bg-yellow-200'}`} required={true}/>
                    <InputLabeled label={`${t('NEW')} ${t('PASSWORD')} ${t('CONFIRM')}`} type={'text'} value={confirmNewPassword} setValue={setConfirmNewPasswor} className={'col-md-3'} bg={`${forgotPassword ? 'bg-blue-100' : 'bg-yellow-200'}`} required={true}/>
                    <hr className="mt-5"/>
                    <div className='col-md-12'>
                        <span className='medium font-18 fg-blue-700'>{t('WORK')}</span>
                        <span onClick={handleSubmit} className='text-primary medium ms-4 bg-light border rounded-2 font-12 px-2 hover-cursor'>{t('UPDATE')}</span>
                    </div>
                    <SelectLabeled label={t('DEPARTMENT')} items={departments} value={departmentId} setValue={setDepartmentId} className={'col-md-3'} required={true}/>
                    <SelectLabeled label={t('WAREHOUSE')} items={warehouses} value={warehouseId} setValue={setWarehouseId} className={'col-md-3'} required={true}/>
                </form>
            </div>
            <ToastU toast={toast} setToast={setToast}/>
        </DataWrapper>
    );
}

export default User;