import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

const CategoryPath = ({...props}) => {
    const { t } = useTranslation();

    return (
        <div className={`d-flex flex-column ${props.className}`}>
            {props.categoryPath?.length != 0 && <span className="fg-red-300 medium font-13 mb-2 hover-cursor"
                onClick={props.clearCategoryPath}>
                <FontAwesomeIcon icon={faXmark} className="me-1"/>
                {t('CLEAR')}
            </span>}
            <div className="d-flex flex-row mb-2">
                {props.categoryPath?.map((item) => {
                    return ( <span key={item.id} className="font-12 fg-dark">/<span className="bg-white fg-blue-500 mx-1 border rounded px-2 py-1">{item?.name}</span></span>)
                })}
            </div>
        </div>
    )
}

export default CategoryPath;