import { useState, useEffect } from "react";
import useAuth from '../../../../../../hooks/useAuth';
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import { Link, useNavigate, useParams } from "react-router-dom";
import { faPrint, faFileExport } from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import WarehouseWrapper from "../../WarehouseWrapper";
import TableStd from '../../../../../../components/ui/TableStd';
import ButtonU from "../../../../../../components/ui/ButtonU";
import Utils from "../../../../../../utils";
import ExportFile from "./../components/ExportFile";
import DataNotFound from '../../../../../../components/ui/DataNotFound';
import ToastU from "../../../../../../components/ui/ToastU";
import Status from "../../../../../../components/ui/Status";
import { useTranslation } from "react-i18next";

const RequestForm = () => {
    const { t } = useTranslation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);

    const [centerWarehouse, setCenterWarehouse] = useState({});
    const [requestForm, setRequestForm] = useState({});
    const [requestFormItems, setRequestFormItems] = useState([]);
    const [requestFormItemsCount, setRequestFormItemsCount] = useState();

    const [exportModal, setExportModal] = useState(false);
   
    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('MAIN'), link: '/'},
        { title: t('WAREHOUSE'), link: `/warehouse/stock-products/1`},
        { title: t('REQUEST_FORMS'), link: `/warehouse/request-forms` },
        { title: requestForm.number, active: true },
    ]

    useEffect(() => {
        if (ready) {
            getCenterWarehouse();
            getRequestForm();
        }
    }, [ready, toast]);

    const getCenterWarehouse = () => {
        setLoading(true);
        const queryWarehouse = { limit: 1, filter: {code: '1000'}}
        axiosPrivate.get('/warehouses', {params: queryWarehouse}).then((res) => {
            if (res.status == 200) {
                setCenterWarehouse(res.data.data[0]);
            }
        }).finally(() => {setLoading(false)});
    }

    const getRequestForm = () => {
        setLoading(true);
        axiosPrivate.get('/request-forms/'+params.id).then((res) => {
            if (res.status == 200) {
                const requestForm = res.data.data;
                setRequestForm(requestForm);
                setRequestFormItems(requestForm.requestFormItems);
                setRequestFormItemsCount(requestForm.requestFormItems.length);
            }
        }).finally(() => {setLoading(false)});
    }

    const modelProps = [ 
        { 
            name: t('BAR_CODE'),
            value: (prop) => { return(<>{prop?.product?.barcode}</>) } 
        },
        { 
            name: t('PRODUCT'),
            value: (prop) => { return(<Link to={`/product-catalog/${params.pcid}/products/${prop?.product?.id}`} className="no-decor">{prop?.product?.name}</Link>) } 
        },
        {
            name: t('REQUESTED'),
            value: (prop) => { return(<>{prop?.requestedQuantity?.toFixed(2)} {prop?.product?.unit?.name}</>) }
        },
        {
            name: t('SPENT'),
            value: (prop) => { return(<>{prop?.quantity?.toFixed(2)} {prop?.quantity && prop?.product?.unit?.name}</>) }
        },
        {
            name: t('UNIT_PRICE'),
            value: (prop) => { return(<>{prop?.unitPrice?.toFixed(2)}</>) }
        },
        {
            name: `${t('TOTAL')}`,
            value: (prop) => { return(<>{prop?.totalUnitPrice}</>) }
        }
    ];

    return (
        <WarehouseWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <span className="fs-5 bold fg-dark">{t('REQUEST_FORM')}: {requestForm?.number}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={() => {setExportModal(true);}} icon={faFileExport} text={t('EXPORT')} className={"bg-orange-400 bg-gradient text-white"}/>
                    <ButtonU className={'border-dark fg-dark'} icon={faPrint} text={t('PRINT')}/>
                    {requestForm.id && !requestForm?.accepted && <ButtonU onSubmit={() => {navigate(`/warehouse/request-forms/update/${params.id}`)}} icon={faEdit} text={t('EDIT')} className={"bg-blue-700 bg-gradient text-white"}/>}
                </div>
            </div>
            <div className="mt-5"></div>
            <div className="row d-flex flex-row mx-0 p-3">
                {requestForm.id && <div className="d-flex flex-column col-md-8 border border-gray p-3">
                    <div className="d-flex flex-row justify-content-between">
                        <div className="d-flex flex-column">
                            <div className="d-flex flex-column font-14 medium">
                                <span className="text-secondary">{t('FROM_WHOM')}</span>
                                <span className="fg-dark">{t('HEAD_NURSE')} - {centerWarehouse?.user?.fullName}</span>
                            </div>
                            <div className="d-flex flex-column font-14 medium mt-2">
                                <span className="text-secondary">{t('TO_WHOM')}</span>
                                <span className="fg-dark">{requestForm?.warehouse?.name} - {requestForm?.warehouse?.user?.fullName}</span>
                            </div>
                        </div>
                        <div className="d-flex flex-column">
                            <span className="fs-1 medium fg-blue-500">{t('REQUEST_FORM').toUpperCase()}</span>
                            <span className="font-12 text-secondary">{t('NUMBER')}: <span className="fg-dark medium">{requestForm?.number}</span></span>
                            <span className="font-12 text-secondary">{t('DATE')}: <span className="fg-dark medium">{Utils.getTimestampDate(requestForm.createdAt)}</span></span>
                        </div>
                    </div>
                    <hr  className="my-2"/>
                    <div className="d-flex flex-row justify-content-between">
                        <div className="d-flex flex-row">
                            <span className="font-12 fg-dark">{t('WAREHOUSE')}: <span className="medium">{requestForm?.warehouse?.name}</span></span>
                            <span className="font-12 fg-dark ms-4">{t('DEPARTMENT')}: <span className="medium">{requestForm?.warehouse?.department?.name}</span></span>
                        </div>
                        <span></span>
                    </div>
                    <hr className="my-2"/>
                    {requestFormItemsCount ?
                        <div style={{overflowY: "scroll", height: "250px"}}>
                            <TableStd modelProps={modelProps} data={requestFormItems}/>
                        </div>
                        : <DataNotFound/>
                    }
                    <span className="font-14 medium fg-dark">{t('TOTAL')} {t('ITEM')}: {requestFormItemsCount}</span>
                    <hr  className="my-2"/>
                    <div className="d-flex flex-row justify-content-between">
                        <div className="d-flex flex-column">
                            <span className="font-12 medium fg-gray">{t('NOTE')}</span>
                            <span className="font-12 fg-dark">{requestForm.note ? requestForm.note : '-'}</span>
                        </div>
                        <div className="d-flex flex-column">
                            <span className="font-14 medium fg-dark">{t('TOTAL')}</span>
                            <span className="font-18 fg-dark bold">{requestForm?.total?.toFixed(2)}</span>
                            <span className="font-14 text-secondary mt-1">{t('ACCEPTED')}: <Status status={requestForm.accepted} textSize={'font-14'}/></span>
                        </div>
                    </div>
                    <hr className="my-2"/>
                    <div className="row font-12 medium">
                        <div className="d-flex flex-column col-md-6">
                            <div className="d-flex flex-row justify-content-between">
                                <span className="text-secondary">{t('DIRECTOR')}:</span>
                                <span className="fg-dark">{requestForm?.director}</span>
                            </div>
                            <div className="d-flex flex-row justify-content-between">
                                <span className="text-secondary">{t('GENERAL_ACCOUNTANT')}:</span>
                                <span className="fg-dark">{requestForm?.generalAccountant}</span>
                            </div>
                            <div className="d-flex flex-row justify-content-between">
                                <span className="text-secondary">{t('DEPARTMENT_DIRECTOR')}:</span>
                                <span className="fg-dark">{requestForm?.warehouse?.user?.fullName}</span>
                            </div>
                            <div className="d-flex flex-row justify-content-between">
                                <span className="text-secondary">{t('HEAD_NURSE')}:</span>
                                <span className="fg-dark">{centerWarehouse?.user?.fullName}</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex flex-row justify-content-between">
                                <span className="text-secondary">{t('DEPUTY_OF_DIRECTOR')}:</span>
                                <span className="fg-dark">{requestForm.deputyOfDirector}</span>
                            </div>
                        </div>
                    </div>
                </div>}
                {requestForm.user && <div className="col-md-4">
                    <div className="ms-2 border p-2">
                        <div className="d-flex flex-row justify-content-between">
                            <div className="d-flex flex-column">
                                <span className="font-12 text-secondary medium">{t('REGISTRATION_DATE')}</span>
                                <span className="fg-dark font-14">{Utils.getTimestampDateHm(requestForm.createdAt)}</span>
                            </div>
                            <div className="d-flex flex-column">
                                <span className="font-12 text-secondary medium">{t('UPDATED_AT')}</span>
                                <span className="fg-dark font-14">{Utils.getTimestampDateHm(requestForm.updatedAt)}</span>
                            </div>
                        </div>
                        <div className="d-flex flex-column mt-3">
                            <span className="font-12 text-secondary medium">{t('REQUESTED')} ({t('USER')})</span>
                            <span className="fg-dark font-14">{requestForm?.user?.fullName}</span>
                        </div>
                    </div>
                </div>}
            </div>

            <ExportFile
                data={requestForm}
                modalShow={exportModal}
                setModalShow={setExportModal}
                toast={toast}
                setToast={setToast}
            />
            <ToastU toast={toast} setToast={setToast}/>
        </WarehouseWrapper>
    );
}

export default RequestForm;