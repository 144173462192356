import { useTranslation } from 'react-i18next';

const MonthsShort = () => {
    const { t } = useTranslation();

    const MONTHS_SHORT = new Map([
        ['01', t('JAN')], ['02', t('FEB')], ['03', t('MAR')], ['04', t('APR')], ['05', t('MAY')], ['06', t('JUN')],
        ['07', t('JUL')], ['08', t('AUG')], ['09', t('SEP')], ['10', t('OCT')], ['11', t('NOV')], ['12', t('DEC')], 
    ]);

    return MONTHS_SHORT;
};

export default MonthsShort;