import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import icWarehouseOutline from "./../../../../../../assets/svg/ic_warehouse_outline.svg";
import icCodeBranch from "./../../../../../../assets/svg/ic_code_branch.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

const WarehouseCard = ({...props}) => {
    const { t } = useTranslation();

    const item = props.item;
    console.log(item.reportStatus)

    return (
        <Link /*to={`/warehouse/warehouses/${item.id}`}*/ className="col-md-3 btn-hover no-decor my-2">
            <div className="d-flex flex-column justify-content-between border border-light shadow-sm btn-hover">
                <div className="d-flex flex-row align-items-center justify-content-between mt-3 mx-3">
                    <span className="font-14 medium fg-blue-300 rounded-1 border border-info px-2 bg-blue-50">{item?.name}</span>
                    <img src={icWarehouseOutline} alt={item?.name} className={`wh-20`}/>
                </div>
                <div className="d-flex flex-column font-14 mx-3 mt-3">
                    <div className="d-flex flex-row align-items-center">
                        <img src={icCodeBranch} alt={item?.name} className={`wh-10 me-2`}/>
                        <span className="fg-gray-200 font-12">{item?.department?.name}</span>
                    </div>
                    <span className="fg-dark font-12">{item?.user?.fullName || '-'}</span>
                </div>
                <div className="d-flex flex-row justify-content-between mx-3 mb-2 mt-3 pb-2">
                    <span className="fg-gray-200 font-12">{t('REPORT')}</span>
                    <span className={`${item.reportStatus ? 'bg-green-600' : 'bg-orange-400'} medium rounded-2 text-white small px-2`}>
                        <FontAwesomeIcon icon={item.reportStatus ? faCheckCircle : faTriangleExclamation} className="me-1"/>
                        {item.reportStatus ? t('CLOSED') : t('OPEN')}
                    </span>
                </div>
            </div>
        </Link>
    )
}

export default WarehouseCard;