import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import icHome from "../../assets/svg/ic_home.svg";

const BreadCrumbsU = ({...props}) => {
  const navigate = useNavigate();

  return (
    <div role="presentation" className={props.className}>
        {props.rootPageLink !== '/' && <img src={icHome} alt="logo" onClick={() => navigate('/')} className="wh-20 hover-cursor btn-hover me-2"/>}
        <Breadcrumbs aria-label="breadcrumb">
        { props.items.map((item, index) => {
          return (
            <div key={index} onClick={() => { if (item.link) navigate(item.link)}} className='hover-cursor'>
              {!item.active ? <Link underline="hover" color="inherit">{item.title}</Link>
                :
                <Link underline="hover" color="text.primary" aria-current="page">{item.title}</Link>
              }
            </div>
          )
        }) }
      </Breadcrumbs>
    </div>
  );
}

export default BreadCrumbsU;