import { useEffect, useState } from "react";
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import ModalU from '../../../../../../components/ui/ModalU';
import Utils from "../../../../../../utils";
import InputLabeled from '../../../../../../components/form/InputLabeled';
import SelectLabeled from '../../../../../../components/form/SelectLabeled';
import { useTranslation } from "react-i18next";

const Update = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    const item = props.item;
    
    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [departmentId, setDepartmentId] = useState('');
    const [order, setOrder] = useState();

    useEffect(() => {
        setName(item.name);
        setCode(item.code);
        setDepartmentId(item.departmentId);
        setOrder(item.order);
    }, [item])

    const handleSubmit = (event) => {
        event.preventDefault();
        
        const requiredInputs = [
            { key: name, value: t('NAME_REQUIRED') },
            { key: code, value: t('CODE_REQUIRED') },
            { key: departmentId, value: t('DEPARTMENT_REQUIRED') },
            { key: order, value: t('ORDER_REQUIRED') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            props.setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        props.setLoading(true);
        const data = { name, code, departmentId, order };
        axiosPrivate.put('/warehouses/update/'+item.id, data).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) {
                props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
                return;
            }
            switch(err.response.status) {
                case 400: props.setToast({ content: t('SOMETHING_WENT_WRONG'), variant: 'danger', title: t('ERROR'), show: true }); break;
                default : props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true }); break;
            }
        }).finally(() => {props.setLoading(false)});
    }

    return (
        <ModalU show={props.modalShow} size={''} title={`${t('WAREHOUSE')} [${t('EDIT')}]`}
            actionbtntext={t('UPDATE')}
            actionbtnclassname={"bg-green-700 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit} className="row">
                <InputLabeled label={t('NAME')} type={'text'} value={name} setValue={setName} hint={t('NAME')} className="col-md-6" required={true}/>
                <InputLabeled label={t('CODE')} type={'text'} value={code} setValue={setCode} hint={t('CODE')} className="col-md-6" required={true}/>
                <SelectLabeled label={t('DEPARTMENT')} items={props.departments} value={departmentId} setValue={setDepartmentId} className="col-md-6" required={true}/>
                <InputLabeled label={t('ORDER')} type={'number'} value={order} setValue={setOrder} hint={'1'} className="col-md-6" required={true}/>
            </form>
        </ModalU>
    );
}

export default Update