import { useState, useEffect } from "react";
import { useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import useAuth from "../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { Link, useNavigate } from "react-router-dom";
import { faAdd, faSearch, faEllipsisV, faArrowRotateRight, faCube, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import Utils from "../../../../../utils";
import InvoiceWrapper from "../InvoiceWrapper";
import InputField from "../../../../../components/form/InputField";
import TableStd from '../../../../../components/ui/TableStd';
import DropdownU from '../../../../../components/ui/DropdownU'
import PaginationU from '../../../../../components/ui/PaginationU';
import LimitU from '../../../../../components/ui/LimitU';
import DataNotFound from '../../../../../components/ui/DataNotFound';
import SortU from '../../../../../components/ui/SortU';
import ButtonU from "../../../../../components/ui/ButtonU";
import Filter from "./Filter";
import Status from "../../../../../components/ui/Status";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Actions from "../../../../../components/ui/Actions";

const Invoices = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const params = useParams();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [invoices, setInvoices] = useState([]);
    const [invoicesCount, setInvoicesCount] = useState();

    const parsed = queryString.parse(location.search);
    const urlQuery = {
        page: parsed.page || 1,
        limit: 10,
        order: parsed.order || [['updatedAt', 'DESC']],
        filter: { productClassificationId: params.pcid, createdAtLb: '', createdAtUb: '' }
    }
    const [query, setQuery] = useState(urlQuery);

    const [find, setfind] = useState('');

    const breadcrumbs = [
        { title: t('MAIN'), link: '/' },
        { title: t('INVOICE'), link: `/invoice/${params.pcid}/invoices` } ,
        { title: t('INVOICES'), active: true}
    ]

    useEffect(() => {
        if (ready) {
            getUpdatedUrl();
            getInvoices();
        }
    }, [ready, query]);

    const getUpdatedUrl = () => {
        parsed.page = query.page;
        window.history.replaceState({}, '', `${location.pathname}?${queryString.stringify(parsed)}`);
    }
    
    const getInvoices = () => {
        setLoading(true);
        axiosPrivate.get('/invoices', { params: query }).then((res) => {
            if (res.status == 200) {
                setInvoices(res.data.data);
                setInvoicesCount(res.data.count);
            }
        }).finally(() => {setLoading(false)});
    }

    useEffect(() => {
        setQuery(prev => {
            return {...prev, filter: { ...prev.filter, number: find }}
        });
    }, [find]);

    useEffect(() => {
        setQuery(prev => {
            return {...prev, filter: { ...prev.filter, productClassificationId: params.pcid }}
        });
    }, [params.pcid]);

    const createNew = () => { navigate(`/invoice/${params.pcid}/invoices/create`); }

    const refresh = () => { getInvoices() }
    
    const modelProps = [ 
        { 
            name: <SortU title={t('NUMBER')} attr={'number'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<Link to={`/invoice/${params.pcid}/invoices/${prop.id}`} className='no-decor'>{prop.number}</Link>) } 
        },
        {
            name: <SortU title={t('WAREHOUSE')} attr={'warehouseId'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.warehouse?.name}</>) }
        },
        {
            name: t('PRODUCT_COUNT'),
            value: (prop) => { return(<>{prop?.invoiceItemCount}</>) }
        },
        {
            name: <SortU title={t('PAID')} attr={'paid'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.paid?.toFixed(2)}</>) }
        },
        {
            name: <SortU title={t('TOTAL')} attr={'total'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.total?.toFixed(2)}</>) }
        },
        {
            name: <SortU title={t('CLOSED')} attr={'closed'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<Status status={prop.closed}/>) }
        },
        {
            name: <SortU title={t('UPDATED_AT')} attr={'updatedAt'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{Utils.getTimestampDateHm(prop.updatedAt)}</>) }
        },
        {
            name: <SortU title={t('SUPPLIER')} attr={'supplierId'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.supplier?.companyName}</>) }
        },
        {
            name: t('ACTION'),
            value: (prop) => {
                const options =[
                    {
                        icon: faEye,
                        style: "fg-blue-700 font-14",
                        onClick: () => { navigate(`/invoice/${params.pcid}/invoices/${prop.id}`); }
                    },
                ]
                return (<Actions items={options}/>)
            }
        }
    ];

    return (
        <InvoiceWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <span className="fs-5 bold fg-dark">{t('INVOICES')}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={() => {navigate(`/invoice/${params.pcid}/invoice-items`)}} className={'bg-gray-50 bg-gradient text-primary'} icon={faCube} text={<span>{t('PRODUCTS')}<FontAwesomeIcon icon={faChevronRight} className="ms-2"/></span>}/>
                    <ButtonU onSubmit={refresh} className={'border-dark fg-dark'} icon={faArrowRotateRight}/>
                    <InputField type={"text"} value={find} setValue={setfind} hint={`${t('INVOICE')} ${t('NUMBER')}`} icon={faSearch} formClass="input-field-container me-1 font-14" inputClass="input-field-outline"/> 
                    <Filter query={query} setQuery={setQuery} />
                    <ButtonU onSubmit={createNew} className={'bg-green-600 bg-gradient text-white'} icon={faAdd} text={t('ADD')}/>
                </div>
            </div>
            { invoicesCount ?
                <div className="mt-5">
                    <TableStd modelProps={modelProps} data={invoices} count={invoicesCount} query={query}/>
                    <div className='d-flex align-items-center justify-content-between'>
                        <LimitU size={"sm"} filter={query} setFilter={setQuery}/>
                        <PaginationU filter={query} setFilter={setQuery} count={invoicesCount} />
                    </div>
                </div>
                : <DataNotFound/>
            }
        </InvoiceWrapper>
    );
}

export default Invoices;