import { useState, useEffect } from "react";
import * as XLSX from "xlsx/xlsx.mjs";
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import ModalU from '../../../../../../components/ui/ModalU';
import ToastU from "../../../../../../components/ui/ToastU";
import LinearIndeterminant from "../../../../../../components/ui/PbLinearIndeterminant";
import Utils from "../../../../../../utils";
import RadioBtnU from "./../../../../../../components/form/RadioBtnU";
import { useTranslation } from "react-i18next";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";

const ExportFile = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    const params = useParams();
    const [loading, setLoading] = useState(false);

    const [exportType, setExportType] = useState('');

    const [query, setQuery] = useState({
        order: [['name', 'DESC']],
        filter: { productClassificationId: params.pcid },
        attributes: {exclude: ['createdAt', 'updatedAt', 'deletedAt', 'version']}
    });

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const exportTypes = [
        { id: 'VIEW', name: t('VIEW_PURPOSE') },
        { id: 'RAW_DATA', name: t('IMPORT_PURPOSE') },
    ];

    useEffect(() => {
        setQuery(prev => {
            return {...prev, filter: { ...prev.filter, productClassificationId: params.pcid }}
        });
    }, [params.pcid]);

    const modifyForRawData = () => {
        setLoading(true);
        axiosPrivate.get('/products', { params: query }).then((res) => {
            if (res.status == 200) {
                const rawData = res.data.data;
                const exportData = [];
                rawData.forEach((e) => {
                    const row = {
                        id: e.id,
                        name: e.name,
                        barcode: e.barcode,
                        unitId: e.unitId
                    }
                    exportData.push(row);
                });
                createForRawData(exportData);

                setToast({ content: t('FILE_EXPORTED'), variant: 'primary', title: t('DONE'), show: true });
                props.setModalShow(false);
            }
        }).finally(() => { setLoading(false) });
    }

    const modifyForView = () => {
        setLoading(true);
        axiosPrivate.get('/products', { params: query }).then((res) => {
            if (res.status == 200) {
                const rawData = res.data.data;
                const viewData = [];
                rawData.forEach((e) => {
                    const row = {
                        name: e.name,
                        barcode: e.barcode,
                        category: e.category && e.category.name,
                        unit: e.unit.shortName,
                        description: e.description,
                    }
                    viewData.push(row);
                });

                createForView(viewData);
                setToast({ content: t('FILE_EXPORTED'), variant: 'primary', title: t('DONE'), show: true });
                props.setModalShow(false);
            }
        }).finally(() => { setLoading(false) });
    }

    const handleExport = () => {
        switch(exportType) {
            case 'RAW_DATA': modifyForRawData(); break;
            case 'VIEW': modifyForView(); break;
            default:
                setToast({ content: t('CHOOSE_EXPORT_TYPE'), variant: 'warning', title: t('WARNING'), show: true });
                break;
        }
    }

    const createForRawData = (sheetData) => {
        const jsonSheet = JSON.parse(JSON.stringify(sheetData));
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(jsonSheet);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        const filename = `products_${Utils.getTimestampDate(Date.now())}_raw.xlsx`
        XLSX.writeFile(wb, filename);
    }

    const createForView = (sheetData) => {
        const Heading = [[t('NAME'), t('BAR_CODE'), t('CATEGORY'), t('UNIT'), t('DEFINITION')]];
        const jsonSheet = JSON.parse(JSON.stringify(sheetData));
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(jsonSheet, { origin: 'A2', skipHeader: true });
        XLSX.utils.sheet_add_aoa(ws, Heading);

        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        const filename = `products_${Utils.getTimestampDate(Date.now())}_view.xlsx`
        XLSX.writeFile(wb, filename);
    }

    return (
        <ModalU show={props.modalShow}
            title={<span className="fg-orange-300"><FontAwesomeIcon icon={faFileExport} className="me-2"/>{t('EXPORT')}</span>}
            actionbtntext={t('CONFIRM')}
            actionbtnclassname={"bg-blue-700 font-14 medium text-white"}
            onSubmit={handleExport} onHide={props.setModalShow}>
            <div className="d-flex flex-column p-3">
                <span className="font-18 medium fg-dark">{t('HOW_DO_YOU_WANT_TO_EXPORT_DATA')}</span>
                <span className="fg-dark font-14 mt-3">{t('VIEW_PURPOSE')}:<span className="fg-green-500 medium ms-2">{t('VIEW_PURPOSE_DESC')}</span></span>
                <span className="fg-dark font-14 mb-3">{t('IMPORT_PURPOSE')}:<span className="fg-blue-500 medium ms-2">{t('IMPORT_PURPOSE_DESC')}</span></span>
                <RadioBtnU items={exportTypes} inline={false} group={t('EXPORT')} attr={exportType} onChangeAttr={setExportType} type={'radio'}/>
                {loading && 
                    <div className="mt-2">
                        <LinearIndeterminant color={'secondary'}/>
                    </div>
                }
            </div>
            <ToastU toast={toast} setToast={setToast}/>
        </ModalU>
    );
}

export default ExportFile