import { useState } from "react";
import * as XLSX from "xlsx/xlsx.mjs";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import convertSize from "convert-size";
import { faCheckToSlot, faFileImport, faInfoCircle, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import ButtonU from "../../../../../components/ui/ButtonU";
import ModalU from '../../../../../components/ui/ModalU';
import Keys from "../../../../../utils/constants";
import ToastU from "../../../../../components/ui/ToastU";
import TableStd from '../../../../../components/ui/TableStd';
import LinearIndeterminant from "../../../../../components/ui/PbLinearIndeterminant";
import icExcel from "../../../../../assets/svg/ic_excel.svg";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ImportFile = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState([]);
    const [file, setFile] = useState();
    const [totalRow, setTotalRows] = useState();

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const handleFile = (e) => {
        setFile(e.target.files[0]);
    }

    const handleCheck = () => {
        if (!file) {
            setToast({ content: t('FILE_NOT_SELECTED'), variant: 'warning', title: t('WARNING'), show: true });
            return;
        }

        setLoading(true);
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary'});
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            let parsedData = XLSX.utils.sheet_to_json(sheet);
            modifyDataSheet(parsedData);
        }
    }

    const modifyDataSheet = async(dataSheet) => {
        const row = dataSheet[0];
        if (!row?.barcode || !row?.unitPrice || !row?.quantity)
            setToast({ content: t('DATA_STRUCTURE_NOT_SUPPORTED'), variant: 'warning', title: t('WARNING'), show: true });
        else {
            const batchSize = 100;
            const invoiceItems = [];

            for (let i = 0; i < dataSheet.length; i += batchSize) {
                const batch = dataSheet.slice(i, i + batchSize);
                const batchPromises = batch.map(async (item) => {
                    if (!Keys.UNSIGNED_INTEGER_REGEX.test(item.barcode)) return;

                    const id = undefined;
                    const barcode = item.barcode;

                    if (item.unitPrice <= 0 || item.quantity <= 0) return;

                    try {
                        const res = await axiosPrivate.get('/products/'+id +'/'+barcode);
                        if (res.status === 200) {
                            const product = res.data.data;
                            const ii = {
                                product, productId: product.id, unitId: product.unit.id,
                                unitPrice: item.unitPrice.toFixed(2),
                                quantity: item.quantity, total: (item.quantity * item.unitPrice).toFixed(2)
                            }
                            invoiceItems.push(ii);
                        }
                    } catch (error) {
                        setToast({ content:`${t('PRODUCT_NOT_FOUND')}. ${t('BARCODE')}: ${barcode}`, variant: 'warning', title: t('WARNING'), show: true });
                        setLoading(false);
                    }
                });
            
                await Promise.all(batchPromises);
            }
    
            setLoading(false);
            setData(invoiceItems);
            setTotalRows(invoiceItems.length);
        }
        setLoading(false);
    }

    const handleImport = () => {
        if (data.length <= 0) return;

        props.setItems([...props.items, ...data]);
        props.setToast({ content: t('FILE_IMPORTED'), variant: 'primary', title: t('DONE'), show: true });
        props.setModalShow(false);
    }

    const modelProps = [
        { name: t('PRODUCT'), value: (prop) => { return(<>{prop.product.name}</>) } },
        { name: t('BAR_CODE'), value: (prop) => { return(<>{prop.product.barcode}</>) } },
        { name: t('QUANTITY'), value: (prop) => { return(<>{prop.quantity}</>) } },
        { name: t('UNIT'), value: (prop) => { return(<>{prop.product.unit.name}</>) } },
        { name: t('UNIT_PRICE'), value: (prop) => { return(<>{prop.unitPrice}</>) } },
        { name: `${t('TOTAL')} ${t('UNIT_PRICE')}`, value: (prop) => { return(<>{prop.totalUnitPrice}</>) } },
    ]

    return (
        <ModalU show={props.modalShow} size={'lg'}
            title={<span className="fg-pink-500"><FontAwesomeIcon icon={faFileImport} className="me-2"/>{t('IMPORT')}</span>}
            actionbtntext={t('UPLOAD')}
            actionbtnclassname={"bg-blue-700 font-14 medium text-white"}
            onSubmit={handleImport} onHide={props.setModalShow}>
            <div className="d-flex flex-column">
                <div className="d-flex flex-column bg-yellow-100  fg-yellow-500 rounded-2 border border-warning px-3 py-2">
                    <span className="font-13 medium mb-1">
                        <FontAwesomeIcon icon={faTriangleExclamation} className="me-2"/>
                        {t('NOTICE')}
                    </span>
                    <span className="font-12">● {t('THIS_FEATURE_ONLY_IMPORTS_EXISTING_PRODUCTS')}</span>
                    <span className="font-12">● {t('FOLLOW_INSTRUCTIONS_BELOW_FOR_IMPORTNG_PRODUCTS')}</span>
                </div>
                <div className="d-flex flex-column bg-blue-100  fg-blue-500 rounded-2 border border-info px-3 py-2 mt-2">
                    <span className="font-13 medium mb-1">
                        <FontAwesomeIcon icon={faInfoCircle} className="me-2"/>
                        {t('INSTRUCTION')}
                    </span>
                    <span className="font-12">● {t('IMPORT_EXCEL_COLUMN_DESCRIPTION')}</span>
                    <div className="row bg-blue-50 rounded-1 col-md-12 font-16 py-1 ms-2">
                        <div className="d-flex flex-column badge bg-blue-50 col-md-3">
                            <span className="fg-blue-500">{t('BAR_CODE')}</span>
                            <span className="bg-white fg-blue-500 mt-1 p-1 rounded-1">barcode</span>
                        </div>
                        <div className="d-flex flex-column badge bg-blue-50 col-md-3">
                            <span className="fg-blue-500">{t('QUANTITY')}</span>
                            <span className="bg-white fg-blue-500 mt-1 p-1 rounded-1">quantity</span>
                        </div>
                        <div className="d-flex flex-column badge bg-blue-50 col-md-3">
                            <span className="fg-blue-500">{t('UNIT_PRICE')}</span>
                            <span className="bg-white fg-blue-500 mt-1 p-1 rounded-1">unitPrice</span>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-between mt-3">
                    <div className="d-flex flex-row align-items-center">
                        <img src={icExcel} alt="excel" className="wh-40"/>
                        {file && <div className="d-flex flex-column m-1">
                            <span className="text-secondary font-14">{t('NAME')}:<span className="fg-blue-500 ms-2">{file.name}</span></span>
                            <span className="text-secondary font-14">{t('SIZE')}:<span className="fg-green-500 medium ms-2">{convertSize(file.size, {accuracy: 3})}</span></span>
                        </div>}
                        <label htmlFor="uploadBtn" onChange={handleFile} className="upload-btn ms-2">
                            {t('CHOOSE')}
                            <input id="uploadBtn" type="file" hidden/>
                        </label>
                    </div>
                    {file && <div>
                        <ButtonU onSubmit={handleCheck} className={'bg-blue-400 bg-gradient mt-2 text-white'} icon={faCheckToSlot} text={t('CHECK')} disabled={loading}/>
                    </div>}
                </div>
                {loading && <div className="mt-1"><LinearIndeterminant color={'secondary'}/></div>}
                {totalRow && 
                    <div className="d-flex flex-column mt-2">
                        <div className="badge bg-green-100 d-flex flex-row justify-content-center font-16 fg-green-700 medium">
                            <span className="">{t('TOTAL')} {t('INVOICE_ITEM')}: </span>
                            <span className="ms-2">{totalRow}</span>
                        </div>
                        <div className="my-2" style={{overflow: "auto", height: "300px"}}>
                            <TableStd modelProps={modelProps} data={data}/>
                        </div>
                    </div>
                }
            </div>
            <ToastU toast={toast} setToast={setToast}/>
        </ModalU>
    );
}

export default ImportFile