import { useState, useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import useAuth from "../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import queryString from 'query-string';
import StockProductsWrapper from './layouts/StockProductsWrapper'
import { useTranslation } from "react-i18next";
import Utils from "../../../../../utils";
import TableStd from "../../../../../components/ui/TableStd";
import LimitU from "../../../../../components/ui/LimitU";
import PaginationU from "../../../../../components/ui/PaginationU";
import DataNotFound from "../../../../../components/ui/DataNotFound";
import SortU from "../../../../../components/ui/SortU";
import MonthsShort from "../../../../../components/ui/MonthsShort";

const StockProducts = ({...props}) => {
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const params = useParams();
    const location = useLocation();
    const { t } = useTranslation();
    const MONTHS = MonthsShort();

    const [loading, setLoading] = useState(false);

    const [stockProducts, setStockProducts] = useState([]);
    const [stockProductsCount, setStockProductsCount] = useState();

    const parsed = queryString.parse(location.search);
    const urlQuery = {
        page: parsed.page || 1,
        limit: 10,
        order: parsed.order || [['createdAt', 'DESC']],
        filter: { productClassificationId: params.pcid, createdAtLb: '', createdAtUb: '' }
    }
    const [query, setQuery] = useState(urlQuery);

    const [find, setFind] = useState('');

    const breadcrumbs = [
        { title: t('MAIN'), link: '/' },
        { title: t('WAREHOUSE'), link: '/warehouse/stock-products/1' } ,
        { title: t('PRODUCTS'), link: '/warehouse/stock-products/1' },
        { title: params?.pcid, link: `/warehouse/stock-products/${params?.pcid}`, active: true},
    ]

    useEffect(() => {
        if (ready) {
            getUpdatedUrl();
            getStockProducts();
        }
    }, [ready, query]);

    const getUpdatedUrl = () => {
        parsed.page = query.page;
        window.history.replaceState({}, '', `${location.pathname}?${queryString.stringify(parsed)}`);
    }

    useEffect(() => {
        setQuery({...query, filter: {...query.filter, name: find}});
    }, [find]);
 
    const getStockProducts = () => {
        setLoading(true);
        axiosPrivate.get('/stock-products', { params: query }).then((res) => {
            if (res.status == 200) {
                setStockProducts(res.data.data);
                setStockProductsCount(res.data.count);
            }
        }).finally(() => {setLoading(false)});
    }

    useEffect(() => {
        setQuery(prev => {
            return {...prev, filter: { ...prev.filter, name: find }}
        });
    }, [find]);

    useEffect(() => {
        setQuery(prev => {
            return {...prev, filter: { ...prev.filter, productClassificationId: params.pcid }}
        });
    }, [params.pcid]);

    const refresh = () => { getStockProducts() }

    const modelProps = [ 
        {
            name: t('BAR_CODE'),
            value: (prop) => { return(<>{prop?.product?.barcode}</>) }
        },
        { 
            name: t('PRODUCT'),
            value: (prop) => { return(<Link to={`/product-catalog/${params.pcid}/products/${prop?.productId}`} className='no-decor'>{prop?.product?.name}</Link>) } 
        },
        {
            name: <SortU title={t('STOCK')} attr={'stock'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.stock}</>) }
        },
        {
            name: <SortU title={Utils.truncateText(t('UNIT'), 10)} attr={'unitId'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.product?.unit?.name}</>) }
        },
        {
            name: <SortU title={Utils.truncateText(t('UNIT_PRICE'), 10)} attr={'unitPrice'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.unitPrice?.toFixed(2)}</>) }
        },
        {
            name: `${t('TOTAL')}`,
            value: (prop) => { return(<>{prop?.totalUnitPrice}</>) }
        },
        {
            name: <SortU title={Utils.truncateText(t('ISSUE_DATE'), 13)} attr={'issueDate'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop.issueDate ? Utils.getTimestampDate2(prop.issueDate, MONTHS) : '-'}</>) }
        },
        {
            name: <SortU title={Utils.truncateText(t('EXPIRE_DATE'), 15)} attr={'expireDate'} query={query} setQuery={setQuery}/>,
            value: (prop) => {
                const bestBeforeDate = Utils.checkBestBeforeDate(prop.expireDate, 30);
                return(
                    <span className={``}>
                        {prop.expireDate ? Utils.getTimestampDate2(prop.expireDate, MONTHS) : '-'}
                        {prop.expireDate && <span className={`medium p-1 rounded-1 text-white small ms-3 ${bestBeforeDate?.status == 'WARNING' ? 'bg-orange-400' : bestBeforeDate?.status == 'DANGER' ? 'bg-red-400' : 'bg-white'}`}>
                            {bestBeforeDate?.remainingDay && `${bestBeforeDate?.remainingDay} ${t('DAYS').toLowerCase()}`}
                        </span>}
                    </span>
                )
            }
        },
        {
            name: <SortU title={Utils.truncateText(t('UPDATED_AT'), 14)} attr={'updatedAt'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{Utils.getTimestampDateHm(prop.updatedAt)}</>) }
        }
    ];

    return (
        <StockProductsWrapper loading={loading} breadcrumbs={breadcrumbs} find={find} setFind={setFind} query={query} setQuery={setQuery} refresh={refresh}>
            { stockProductsCount ?
                <div className="mt-2">
                    <TableStd modelProps={modelProps} data={stockProducts} count={stockProductsCount} query={query}/>
                    <div className='d-flex align-items-center justify-content-between'>
                        <LimitU size={"sm"} filter={query} setFilter={setQuery}/>
                        <PaginationU filter={query} setFilter={setQuery} count={stockProductsCount} />
                    </div>
                </div>
                : <DataNotFound/>
            }
        </StockProductsWrapper>
    );
}

export default StockProducts;