export default class Config {
    static CO_NAME = "Älem Tilsimat";
    static APP_NAME = "Hassahana Ammar Dolandyryş Ulgamy";
    static VERSION = "1.2.8";
    
    static IS_DEV = false;
    static URL_SERVER = this.IS_DEV ? 'http://127.0.0.1:4000' : 'https://ammar.alemtilsimat.com';
    static PATH_PREFIX = this.IS_DEV ? "/static" : "/api/static";
    static PATH_PRODUCT_IMAGES = "/images/products/";
    static PATH_BACKUP_SQL = "/backup/";
}