import { useState, useEffect } from "react";
import { useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import useAuth from "../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { Link, useNavigate } from "react-router-dom";
import { faAdd, faSearch, faEllipsisV, faArrowRotateRight, faFileImport, faFileExport } from "@fortawesome/free-solid-svg-icons";
import { faEye, faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import Utils from "../../../../../utils";
import ProductCatalogWrapper from "./../ProductCatalogWrapper";
import InputField from "../../../../../components/form/InputField";
import TableStd from '../../../../../components/ui/TableStd';
import Status from "../components/Status";
import DropdownU from '../../../../../components/ui/DropdownU'
import PaginationU from '../../../../../components/ui/PaginationU';
import LimitU from '../../../../../components/ui/LimitU';
import DataNotFound from '../../../../../components/ui/DataNotFound';
import ButtonU from "../../../../../components/ui/ButtonU";
import ToastU from "../../../../../components/ui/ToastU";
import SortU from "../../../../../components/ui/SortU";
import Filter from "./components/Filter";
import Delete from "./components/Delete";
import ImportFile from "./components/ImportFile";
import ExportFile from "./components/ExportFile";
import { useTranslation } from "react-i18next";
import Actions from "../../../../../components/ui/Actions";

const Products = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const params = useParams();
    const { ready, auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [products, setProducts] = useState([]);
    const [productsCount, setProductsCount] = useState();

    const parsed = queryString.parse(location.search);
    const urlQuery = {
        page: parsed.page || 1,
        limit: 10,
        order: parsed.order || [['createdAt', 'DESC']],
        filter: { productClassificationId: params.pcid, createdAtLb: '', createdAtUb: '' }
    }
    const [query, setQuery] = useState(urlQuery);
    
    const [find, setfind] = useState('');
    const [modalDelete, setModalDelete] = useState(false);
    const [importModal, setImportModal] = useState(false);
    const [exportModal, setExportModal] = useState(false);

    const [itemToBe, setItemToBe] = useState({});

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('MAIN'), link: '/' },
        { title: t('PRODUCT_CATALOG'), link: `/product-catalog/${params.pcid}/products` } ,
        { title: t('PRODUCTS'), active: true}
    ]

    useEffect(() => {
        if (ready) {
            getUpdatedUrl();
            getProducts();
        }
    }, [ready, query, toast]);

    const getUpdatedUrl = () => {
        parsed.page = query.page;
        window.history.replaceState({}, '', `${location.pathname}?${queryString.stringify(parsed)}`);
    }
    
    const getProducts = () => {
        setLoading(true);
        axiosPrivate.get('/products', { params: query }).then((res) => {
            if (res.status == 200) {
                setProducts(res.data.data);
                setProductsCount(res.data.count);
            }
        }).finally(() => {setLoading(false)});
    }

    useEffect(() => {
        setQuery(prev => {
            return {...prev, filter: { ...prev.filter, name: find }}
        });
    }, [find]);

    useEffect(() => {
        setQuery(prev => {
            return {...prev, filter: { ...prev.filter, productClassificationId: params.pcid }}
        });
    }, [params.pcid]);

    const createNew = () => { navigate(`/product-catalog/${params.pcid}/products/create`); }

    const refresh = () => { getProducts() }
    
    const modelProps = [ 
        {
            name: <SortU title={t('BAR_CODE')} attr={'barcode'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop.barcode}</>) }
        },
        { 
            name: <SortU title={t('NAME')} attr={'name'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<Link to={`/product-catalog/${params.pcid}/products/${prop.id}`} className='no-decor'>{prop.name}</Link>) } 
        },
        {
            name: t('UNIT'),
            value: (prop) => { return(<>{prop?.unit?.name}</>) }
        },
        {
            name: t('CATEGORY'),
            value: (prop) => { return(<>{prop?.category ? prop?.category?.name : '-'}</>) }
        },
        {
            name: <SortU title={t('STATUS')} attr={'active'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<Status status={prop.active} size={'font-10'}/>) }
        },
        {
            name: <SortU title={t('UPDATED_AT')} attr={'updatedAt'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{Utils.getTimestampDateHm(prop.updatedAt)}</>) }
        },
        {
            name: t('ACTION'),
            value: (prop) => {
                const options =[
                    {
                        icon: faEye,
                        style: "fg-blue-700 font-14",
                        onClick: () => { navigate(`/product-catalog/${params.pcid}/products/${prop.id}`); }
                    },
                    {
                        icon: faPenToSquare,
                        style: "fg-blue-500 font-14",
                        onClick: () => { navigate(`/product-catalog/${params.pcid}/products/update/${prop.id}`); }
                    },
                    {
                        icon: faTrashCan,
                        style: "fg-red-300 font-14",
                        onClick: () => { 
                            setItemToBe(prop);
                            setModalDelete(true);
                        }
                    }
                ]
                return (<Actions items={options}/>)
            }
        }
    ];

    return (
        <ProductCatalogWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <span className="fs-5 bold fg-dark">{t('PRODUCTS')}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={refresh} fg={''} className={'border-dark fg-dark'} icon={faArrowRotateRight}/>
                    <InputField type={"text"} value={find} setValue={setfind} hint={`${t('PRODUCT')} ${t('NAME')}, ${t('BAR_CODE')}`} icon={faSearch} formClass="input-field-container me-1 font-14" inputClass="input-field-outline"/> 
                    <Filter query={query} setQuery={setQuery} />
                    <ButtonU onSubmit={() => {setImportModal(true)}} icon={faFileImport} text={t('IMPORT')} className={"bg-pink-700 bg-gradient text-white"}/>
                    <ButtonU onSubmit={() => {setExportModal(true);}} icon={faFileExport} text={t('EXPORT')} className={"bg-orange-400 bg-gradient text-white"}/>
                    {Utils.allowedRole(auth, '1001', 'write') && <ButtonU onSubmit={createNew} className={'bg-green-600 bg-gradient text-white'} icon={faAdd} text={t('ADD')}/>}
                </div>
            </div>
            { productsCount ?
                <div className="mt-5">
                    <TableStd modelProps={modelProps} data={products} count={productsCount} query={query}/>
                    <div className='d-flex align-items-center justify-content-between'>
                        <LimitU size={"sm"} filter={query} setFilter={setQuery}/>
                        <PaginationU filter={query} setFilter={setQuery} count={productsCount} />
                    </div>
                </div>
                : <DataNotFound/>
            }
            <Delete
                modalShow={modalDelete}
                setModalShow={setModalDelete}
                toast={toast}
                setToast={setToast}
                item={itemToBe}
                setLoading={setLoading}
            />
            <ImportFile
                modalShow={importModal}
                setModalShow={setImportModal}
                toast={toast}
                setToast={setToast}
            />
            <ExportFile
                modalShow={exportModal}
                setModalShow={setExportModal}
                toast={toast}
                setToast={setToast}
            />
            <ToastU toast={toast} setToast={setToast}/>
        </ProductCatalogWrapper>
    );
}

export default Products;