import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import useAuth from "../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { Link, useNavigate } from "react-router-dom";
import { faAdd, faSearch, faEllipsisV, faArrowRotateRight } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import Utils from "../../../../../utils";
import WarehouseWrapper from "../WarehouseWrapper";
import InputField from "../../../../../components/form/InputField";
import TableStd from '../../../../../components/ui/TableStd';
import DropdownU from '../../../../../components/ui/DropdownU'
import PaginationU from '../../../../../components/ui/PaginationU';
import LimitU from '../../../../../components/ui/LimitU';
import DataNotFound from '../../../../../components/ui/DataNotFound';
import SortU from '../../../../../components/ui/SortU';
import ButtonU from "../../../../../components/ui/ButtonU";
import Filter from "./Filter";
import { useTranslation } from "react-i18next";
import SelectProductClassification from "../components/SelectProductClassification";

const Incomes = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const productClassifications = JSON.parse(localStorage.getItem('productClassifications'));

    const [incomes, setIncomes] = useState([]);
    const [incomesCount, setIncomesCount] = useState();

    const parsed = queryString.parse(location.search);
    const urlQuery = {
        page: parsed.page || 1,
        limit: 10,
        order: parsed.order || [['updatedAt', 'DESC']],
        filter: { createdAtLb: '', createdAtUb: '', productClassificationId: '1' }
    }
    const [query, setQuery] = useState(urlQuery);

    const [find, setfind] = useState('');

    const breadcrumbs = [
        { title: t('MAIN'), link: '/' },
        { title: t('WAREHOUSE'), link: '/warehouse/stock-products/1' } ,
        { title: t('INCOMES'), active: true}
    ]

    useEffect(() => {
        if (ready) {
            getUpdatedUrl();
            getIncomes();
        }
    }, [ready, query]);

    const getUpdatedUrl = () => {
        parsed.page = query.page;
        window.history.replaceState({}, '', `${location.pathname}?${queryString.stringify(parsed)}`);
    }
    
    const getIncomes = () => {
        setLoading(true);
        axiosPrivate.get('/incomes', { params: query }).then((res) => {
            if (res.status == 200) {
                setIncomes(res.data.data);
                setIncomesCount(res.data.count);
            }
        }).finally(() => {setLoading(false)});
    }

    useEffect(() => {
        setQuery({...query, filter: { ...query.filter, name: find }});
    }, [find]);

    const refresh = () => { getIncomes()}

    const modelProps = [
        {
            name: t('BAR_CODE'),
            value: (prop) => { return(<>{prop?.product?.barcode}</>) } 
        },
        { 
            name: t('PRODUCT'),
            value: (prop) => { return(<Link to={`/product-catalog/${prop?.product?.productClassificationId}/products/${prop?.productId}`} className='no-decor'>{prop?.product?.name}</Link>) } 
        },
        {
            name: <SortU title={t('QUANTITY')} attr={'quantity'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.quantity}</>) }
        },
        {
            name: <SortU title={Utils.truncateText(t('UNIT_PRICE'), 10)} attr={'unitPrice'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.unitPrice?.toFixed(2)}</>) }
        },
        {
            name: `${t('TOTAL')}`,
            value: (prop) => { return(<>{prop?.totalUnitPrice}</>) }
        },
        {
            name: Utils.truncateText(t('PRODUCT_CLASSIFICATION'), 14),
            value: (prop) => { return(<>{prop?.product?.productClassification?.name}</>) }
        },
        {
            name: <SortU title={t('WAREHOUSE')} attr={'warehouseId'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.warehouse?.name}</>) }
        },
        {
            name: <SortU title={t('CREATED_DATE')} attr={'updatedAt'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{Utils.getTimestampDateHm(prop.updatedAt)}</>) }
        }
    ];

    return (
        <WarehouseWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <div className="d-flex flex-row align-items-center">
                    <span className="fs-5 bold fg-dark">{t('INCOMES')}</span>
                </div>
                <div className="d-flex align-items-center">
                    <SelectProductClassification items={productClassifications} query={query} setQuery={setQuery}/>
                    <ButtonU onSubmit={refresh} className={'border-dark fg-dark'} icon={faArrowRotateRight}/>
                    <InputField type={"text"} value={find} setValue={setfind} hint={`${t('PRODUCT')} ${t('NAME')}, ${t('BAR_CODE')}`} icon={faSearch} formClass="input-field-container me-1 font-14" inputClass="input-field-outline"/> 
                    <Filter query={query} setQuery={setQuery} />
                </div>
            </div>
            { incomesCount ?
                <div className="mt-5">
                    <TableStd modelProps={modelProps} data={incomes} count={incomesCount} query={query}/>
                    <div className='d-flex align-items-center justify-content-between'>
                        <LimitU size={"sm"} filter={query} setFilter={setQuery}/>
                        <PaginationU filter={query} setFilter={setQuery} count={incomesCount} />
                    </div>
                </div>
                : <DataNotFound/>
            }
        </WarehouseWrapper>
    );
}

export default Incomes;