import MainWrapper from "../../layouts/MainWrapper";
import SideBar from "../../../../layouts/SideBar";
import { useTranslation } from "react-i18next";
import Keys from "../../../../utils/constants";
import icBookUser from './../../../../assets/svg/ic_book_user.svg';

const CompanyWrapper = (props) => {
    const { t } = useTranslation();

    const items = [
        { name: t('SUPPLIERS'), id: "suppliers", icon:icBookUser, links: Keys.COMPANY_SUPPLIERS_LINKS},
        { name: t('CONTACTS'), id: "contacts", icon:icBookUser, links: Keys.COMPANY_CONTACTS_LINKS},
    ];

    return (
        <MainWrapper loading={props.loading} breadcrumbs={props.breadcrumbs}>
            <div className="main-page">
                <SideBar items={items}/>
                <div className="body-page">
                    { props.children }
                </div>
            </div>
        </MainWrapper>
    );
};

export default CompanyWrapper;