import { useTranslation } from 'react-i18next';

const SelectMonth = ({...props}) => {
    const { t } = useTranslation();

    const handleChange = (event) => {
        event.preventDefault();
        props.setValue(event.target.value);
    }

    return (
        <div className={`d-flex flex-column ${props.className}`}>
            {props.label && <div className="d-flex flex-row mb-1">
                <span className="medium font-13 fg-dark me-1">{props.label}</span>
            </div>}
            <select
                size={props.size}
                value={props.value}
                onChange={handleChange}
                className={`input-field font-13 bg-light`}>
                { Array.from(props.items).map(([key, value]) => {
                    return (<option value={key} key={key}>{value}</option>)
                }) }
            </select>
        </div>
    );
}

export default SelectMonth;