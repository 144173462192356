import { Link, useLocation } from "react-router-dom"
import Utils from "../utils";

const NavBar = ({...props}) => {
    const location = useLocation();
    const items = props.items;

    const isActive = (links) => {
        let flag = links.some((link) => {
            const baseLink = link.split('/')[1];
            const path = location.pathname.split('/')[1];
            return (baseLink === path);
        });
        
        return flag;
    }

    return (
        <ul className="nav-bar" id="nav-bar">
            { items.map((item, index)=>{
                return (
                    <Link to={item.links[0]} className="no-decor" key={item.id} >
                        <div className={"nav-item " + (!isActive(item.links) && 'collapsed')} data-bs-target={`#${item.id}`} data-bs-toggle="collapse">
                            <img src={item.icon} className={"icon " + item.bg} />
                            <span className="ms-2">{item.name}</span>
                        </div>
                    </Link>
                );
            })}
        </ul>
    );
}

export default NavBar;