import { useState, useEffect } from "react";
import useAuth from '../../../../../../../hooks/useAuth';
import useAxiosPrivate from '../../../../../../../hooks/useAxiosPrivate';
import { useNavigate, useParams } from "react-router-dom";
import { faAdd, faClipboardCheck, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faSave, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import WarehouseWrapper from "../../../WarehouseWrapper";
import ButtonU from "../../../../../../../components/ui/ButtonU";
import InputLabeled from "../../../../../../../components/form/InputLabeled";
import InputAreaLabeled from "../../../../../../../components/form/InputAreaLabeled";
import ItemCreate from "./RequestFormItem/Create";
import RequestFormItems from "./RequestFormItem/RequestFormItems";
import ToastU from "../../../../../../../components/ui/ToastU";
import Cancel from "../../../../../../../components/ui/Cancel";
import { useTranslation } from "react-i18next";
import Accept from "../../../../../../../components/ui/Accept";
import Status from "../../../../../../../components/ui/Status";
import Delete from "./Delete";

const Update = () => {
    const { t } = useTranslation();
    const { ready, isAdmin } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);

    const [requestForm, setRequestForm] = useState({});

    const [accepted, setAccepted] = useState();
    const [note, setNote] = useState();
    const [director, setDirector] = useState();
    const [deputyOfDirector, setDeputyOfDirector] = useState();
    const [generalAccountant, setGeneralAccountant] = useState();

    const [modalCancel, setModalCancel] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [modalAccept, setModalAccept] = useState(false);
    const [requestFormItemModal, setRequestFormItemModal] = useState(false);

    const [requestFormItems, setRequestFormItems] = useState([]);

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('MAIN'), link: '/'},
        { title: t('WAREHOUSE'), link: `/warehouse/stock-products/1`},
        { title: t('REQUEST_FORMS'), link: `/warehouse/request-forms` },
        { title: requestForm?.number, active: true },
    ]

    useEffect(() => {
        if (ready) {
            getRequestForm();
        }
    }, [ready]);

    useEffect(() => {
        if (!requestForm) return;
        if (requestForm.accepted) navigate(-1);
        setAccepted(requestForm.accepted);
        setNote(requestForm.note);
        setDirector(requestForm.director);
        setDeputyOfDirector(requestForm.deputyOfDirector);
        setGeneralAccountant(requestForm.generalAccountant);
        setRequestFormItems(requestForm.requestFormItems);
    }, [requestForm])

    const getRequestForm = () => {
        setLoading(true);
        axiosPrivate.get('/request-forms/'+params.id).then((res) => {
            if (res.status === 200) {
                setRequestForm(res.data.data);
            }
        }).finally(() => {setLoading(false)})
    }

    const handleSubmit = () => {
        setLoading(true);
        const data = { note, director, deputyOfDirector, generalAccountant, items: requestFormItems };

        axiosPrivate.put('/request-forms/update/'+requestForm.id, data).then((res) => {
            if (res.status == 200) {
                setToast({ content: res.data.msg, variant: 'success', title: t('SUCCESS'), show: true });
                navigate(-1);
            }
        }).catch((err) => {
            if (!err?.response) setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {setLoading(false)});
    }

    const handleAccept = () => {
        setLoading(true);
        const data = {
            warehouseId: requestForm.warehouseId, accepted: true, note,
            director, deputyOfDirector, generalAccountant, items: requestFormItems
        };
        axiosPrivate.put('/request-forms/update/'+requestForm.id, data).then((res) => {
            if (res.status == 200) {
                setToast({ content: res.data.msg, variant: 'success', title: t('SUCCESS'), show: true });
                setModalAccept(false);
                navigate(-1);
            }
        }).catch((err) => {
            if (!err?.response) setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {setLoading(false)});
    }

    return (
        <WarehouseWrapper loading={loading} breadcrumbs={breadcrumbs}>
             <div className="head-long-page">
                <span className="fs-5 bold fg-dark">{t('REQUEST_FORM')}: {requestForm?.number}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={() => {setModalCancel(true)}} icon={faChevronLeft} text={t('BACK')} className={"bg-gray-50 bg-gradient fg-blue-700"}/>
                    <ButtonU onSubmit={() => setModalDelete(true)} className={'bg-red-400 bg-gradient text-white'} icon={faTrashCan} text={t('DELETE')}/>
                    {isAdmin && <ButtonU onSubmit={() => {setModalAccept(true)}} icon={faClipboardCheck} text={t('ACCEPT')} className={"bg-blue-500 bg-gradient text-white"} disabled={accepted}/>}
                    <ButtonU onSubmit={handleSubmit} icon={faSave} text={t('SAVE')} className={"bg-green-600 bg-gradient text-white"}/>
                </div>
            </div>

            <div className="my-2 py-2 px-4 mt-5">
                <div className="row">
                    <div className="d-flex flex-row">
                        <div className="d-flex flex-row me-4 badge bg-blue-50 fg-dark py-2 px-3">
                            <span className="me-3">{t('NUMBER')}:</span>
                            <span className="medium">{requestForm?.number}</span>
                        </div>
                        <div className="d-flex flex-row me-4 badge bg-blue-50 fg-dark py-2 px-3">
                            <span className="me-3">{t('WAREHOUSE')}:</span>
                            <span className="medium">{requestForm?.warehouse?.name}</span>
                        </div>
                        <div className="d-flex flex-row me-4 badge bg-blue-50 fg-dark py-2 px-3">
                            <span className="me-3">{t('DEPARTMENT')}:</span>
                            <span className="medium">{requestForm?.warehouse?.department?.name}</span>
                        </div>
                    </div>
                    <span className="font-14 text-secondary my-2">{t('ACCEPTED')}: <Status status={accepted} textSize={'font-14'}/></span>
                    <div className="col-md-12">
                        <div className="d-flex flex-column border my-2 px-2 py-1">
                            <div className="d-flex align-items-center justify-content-between mb-1">
                                <span className="font-18 medium fg-dark">{t('PRODUCTS')} ({requestFormItems?.length})</span>
                                <ButtonU onSubmit={() => {setRequestFormItemModal(true)}} icon={faAdd} className={'bg-blue-700 bg-gradient text-white'}/>
                            </div>
                            <RequestFormItems items={requestFormItems} setItems={setRequestFormItems} productClassificationId={requestForm?.productClassificationId}/>
                        </div>
                    </div>
                    <InputLabeled label={t('DIRECTOR')} type={'text'} value={director} setValue={setDirector} hint={t('FULLNAME')} className={'col-md-6'} required={true}/>
                    <InputLabeled label={t('DEPUTY_OF_DIRECTOR')} type={'text'} value={deputyOfDirector} setValue={setDeputyOfDirector} hint={t('FULLNAME')} className={'col-md-6'} required={true}/>
                    <InputLabeled label={t('GENERAL_ACCOUNTANT')} type={'text'} value={generalAccountant} setValue={setGeneralAccountant} hint={t('FULLNAME')} className={'col-md-6'} required={true}/>
                    <div className="col-md-6"></div>
                    <InputAreaLabeled label={t('NOTE')} rows={3} value={note} setValue={setNote} hint={t('NOTE')} className={'col-md-6'}/>
                </div>
            </div>
            <ItemCreate
                items={requestFormItems}
                setItems={setRequestFormItems}
                modalShow={requestFormItemModal}
                setModalShow={setRequestFormItemModal}
                productClassificationId={requestForm?.productClassificationId}
            />
            <Cancel modalShow={modalCancel} setModalShow={setModalCancel}/>
            <Delete
                modalShow={modalDelete}
                setModalShow={setModalDelete}
                requestForm={requestForm}
                toast={toast}
                setToast={setToast}
                setLoading={setLoading}
            />
            <Accept modalShow={modalAccept} setModalShow={setModalAccept} action={handleAccept}/>
            <ToastU toast={toast} setToast={setToast}/>
        </WarehouseWrapper>
    );
}

export default Update;