import { useEffect } from 'react';
import useAuth from './hooks/useAuth';
import Utils from "./utils";
import useRefreshToken from './hooks/useRefreshToken';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import PageNotFound from './pages/PageNotFound';
import SomethinWentWrong from './pages/SomethingWentWrong';
import Login from './pages/Login/Login';

import Dashboard from './pages/Main/pages/Dashboard';

import Profile from './pages/Settings/pages/Profile';
import PrivacyAndSecurity from './pages/Settings/pages/PrivacyAndSecurity';
// import Appearance from './pages/Settings/pages/Appearance';

import SettingsDataAdministration from './pages/Settings/pages/Data/Administration';
import SettingsUsers from './pages/Settings/pages/Data/Administration/Users';
import SettingsUser from './pages/Settings/pages/Data/Administration/Users/User';
import SettingsUserCreate from './pages/Settings/pages/Data/Administration/Users/User/Create';
import SettingsLogs from './pages/Settings/pages/Data/Administration/Logs';
import SettingsRoles from './pages/Settings/pages/Data/Administration/Roles';
import SettingsBackup from './pages/Settings/pages/Data/Administration/Backup';

import SettingsDataGeneral from './pages/Settings/pages/Data/General';
import SettingsProductClassification from './pages/Settings/pages/Data/General/ProductClassifications';
import SettingsCategories from './pages/Settings/pages/Data/General/Categories';
import SettingsBrands from './pages/Settings/pages/Data/General/Brands';
import SettingsCountries from './pages/Settings/pages/Data/General/Countries';
import SettingsManufacturers from './pages/Settings/pages/Data/General/Manufacturers';
import SettingsUnits from './pages/Settings/pages/Data/General/Units';

import SettingsDataHR from './pages/Settings/pages/Data/Warehouse';
import SettingsDepartments from './pages/Settings/pages/Data/Warehouse/Departments';
import SettingsWarehouses from './pages/Settings/pages/Data/Warehouse/Warehouses';

import Products from './pages/Main/pages/ProductCatalog/Products';
import Product from './pages/Main/pages/ProductCatalog/Products/Product';
import ProductCreate from './pages/Main/pages/ProductCatalog/Products/Product/Create';
import ProductUpdate from './pages/Main/pages/ProductCatalog/Products/Product/Update';

import Invoices from './pages/Main/pages/Invoice/Invoices';
import Invoice from './pages/Main/pages/Invoice/Invoices/Invoice';
import InvoiceCreate from './pages/Main/pages/Invoice/Invoices/Create';
import InvoiceItems from './pages/Main/pages/Invoice/InvoiceItems';

import StockProducts from './pages/Main/pages/Warehouse/StockProducts';
import Warehouses from './pages/Main/pages/Warehouse/Warehouses';
import RequestForms from './pages/Main/pages/Warehouse/RequestForms';
import RequestFormCreate from './pages/Main/pages/Warehouse/RequestForms/Create';
import RequestForm from './pages/Main/pages/Warehouse/RequestForms/RequestForm';
import RequestFormUpdate from './pages/Main/pages/Warehouse/RequestForms/RequestForm/Update';
import Expenses from './pages/Main/pages/Warehouse/Expenses';
import ExpensesCreate from './pages/Main/pages/Warehouse/Expenses/Create';
import Incomes from './pages/Main/pages/Warehouse/Incomes';

import Suppliers from './pages/Main/pages/Company/Suppliers';
import Contacts from './pages/Main/pages/Company/Contacts';

import ReportsGeneral from './pages/Main/pages/Reports/General';
import ReportsCurrentMonth from './pages/Main/pages/Reports/General/CurrentMonth';
import CloseMonthlyReport from './pages/Main/pages/Reports/General/CloseMonthlyReport';
import MonthlyReports from './pages/Main/pages/Reports/MonthlyReports';
import MonthlyReport from './pages/Main/pages/Reports/MonthlyReports/MonthlyReport';

function App() {
  const { auth } = useAuth();
  const refresh = useRefreshToken();

  useEffect(() => {
    refresh();
  }, [auth.token]);
  
  return (
    <BrowserRouter>
      <Routes>
        {/* public routes */}
        <Route path='/something-went-wrong' element={<SomethinWentWrong />} />
        <Route path='/auth/signin' element={<Login />} />

        <Route path='/' element={<Dashboard/>} />

        {/* protected routes */}
        
        <Route path='/settings/profile' element={<Profile />} />
        <Route path='/settings/privacy-and-security' element={<PrivacyAndSecurity />} />

        {Utils.allowedRole(auth, '1007', 'read') && <>
          <Route path='/settings/data/administration' element={<SettingsDataAdministration />} />
          <Route path='/settings/data/administration/users' element={<SettingsUsers />} />
          <Route path='/settings/data/administration/users/:id' element={<SettingsUser />} />
          <Route path='/settings/data/administration/users/create' element={<SettingsUserCreate />} />
          <Route path='/settings/data/administration/logs' element={<SettingsLogs />} />
          <Route path='/settings/data/administration/roles' element={<SettingsRoles />} />
          <Route path='/settings/data/administration/backup' element={<SettingsBackup />} />
        </>}

        {Utils.allowedRole(auth, '1006', 'read') && <>
          <Route path='/settings/data/general' element={<SettingsDataGeneral />} />
          <Route path='/settings/data/general/product-classifications' element={<SettingsProductClassification />} />
          <Route path='/settings/data/general/categories' element={<SettingsCategories />} />
          <Route path='/settings/data/general/brands' element={<SettingsBrands />} />
          <Route path='/settings/data/general/countries' element={<SettingsCountries />} />
          <Route path='/settings/data/general/manufacturers' element={<SettingsManufacturers />} />
          <Route path='/settings/data/general/units' element={<SettingsUnits />} />
          <Route path='/settings/data/warehouse' element={<SettingsDataHR />} />
          <Route path='/settings/data/warehouse/departments' element={<SettingsDepartments />} />
          <Route path='/settings/data/warehouse/warehouses' element={<SettingsWarehouses />} />
        </>}

        {Utils.allowedRole(auth, '1001', 'read') && <>
          <Route path='/product-catalog/:pcid/products' element={<Products />} />
          <Route path='/product-catalog/:pcid/products/:id' element={<Product />} />
          {Utils.allowedRole(auth, '1001', 'write') && <Route path='/product-catalog/:pcid/products/create' element={<ProductCreate/>} />}
          {Utils.allowedRole(auth, '1001', 'update') && <Route path='/product-catalog/:pcid/products/update/:id' element={<ProductUpdate/>} />}
        </>}

        {Utils.allowedRole(auth, '1002', 'read') && <>
          <Route path='/invoice/:pcid/invoices' element={<Invoices/>} />
          <Route path='/invoice/:pcid/invoices/:id' element={<Invoice/>} />
          <Route path='/invoice/:pcid/invoices/create' element={<InvoiceCreate/>} />
          <Route path='/invoice/:pcid/invoice-items' element={<InvoiceItems/>} />
        </>}

        {Utils.allowedRole(auth, '1003', 'read') && <>
          <Route path='/warehouse/stock-products/:pcid' element={<StockProducts />} />
          <Route path='/warehouse/warehouses' element={<Warehouses />} />
          <Route path='/warehouse/request-forms' element={<RequestForms />} />
          <Route path='/warehouse/request-forms/create' element={<RequestFormCreate />} />
          <Route path='/warehouse/request-forms/:id' element={<RequestForm/>} />
          <Route path='/warehouse/request-forms/update/:id' element={<RequestFormUpdate/>} />
          <Route path='/warehouse/expenses' element={<Expenses />} />
          <Route path='/warehouse/expenses/create-bulk' element={<ExpensesCreate />} />
          <Route path='/warehouse/incomes' element={<Incomes />} />
        </>}

        {Utils.allowedRole(auth, '1004', 'read') && <>
          <Route path='/company/suppliers' element={<Suppliers />} />
          <Route path='/company/contacts' element={<Contacts />} />
        </>}

        {Utils.allowedRole(auth, '1005', 'read') && <>
          <Route path='/reports/general' element={<ReportsGeneral />} />
          <Route path='/reports/general/report' element={<ReportsCurrentMonth />} />
          <Route path='/reports/general/close-monthly-report' element={<CloseMonthlyReport />} />
          <Route path='/reports/monthly-reports' element={<MonthlyReports />} />
          <Route path='/reports/monthly-reports/report' element={<MonthlyReport />} />
        </>}

        {/* catch all */}
        <Route path='/page-not-found' element={<PageNotFound />} />
        {/* <Route path='*' element={<PageNotFound />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;