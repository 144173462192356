import { useState, useEffect } from "react";
import useAuth from "../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import useCheckExistenceOfPreviousMonthRecord from "../../../../hooks/useCheckExistenceOfPreviousMonthRecord";
import MainWrapper from "../../layouts/MainWrapper";
import CardTotal from "./components/CardTotal";
import { useTranslation } from "react-i18next";
import Utils from "../../../../utils";
import icCube from '../../../../assets/svg/ic_cube_outline.svg';
import icMemoCheck from '../../../../assets/svg/ic_memo_check.svg';
import icFileArrowUpAlt from '../../../../assets/svg/ic_file_arrow_up_alt.svg';
import icFileArrowDownAlt from '../../../../assets/svg/ic_file_arrow_down_alt.svg';
import InExAreaChart from "./charts/InExAreaChart";
import { Link, useNavigate } from "react-router-dom";
import ButtonU from "../../../../components/ui/ButtonU";
import { faAdd, faChevronRight, faList, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductClassificationPieChart from "./charts/ProductClassificationPieChart";

const Dashboard = () => {
    const { t } = useTranslation();
    const { ready, isAdmin } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const checkExistenceOfPreviousMonthRecord = useCheckExistenceOfPreviousMonthRecord();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isPreviousMonthRecordExist, setIisPreviousMonthRecordExist] = useState();

    const [countStockProducts, setCountStockProducts] = useState();
    const [countExpenses, setCountExpenses] = useState();
    const [countIncomes, setCountIncomes] = useState();
    const [countRequestForms, setCountRequestForms] = useState();
    const [incomeTotals, setIncomeTotals] = useState({});
    const [expenseTotals, setExpenseTotals] = useState({});
    const [productClassificationsCount, setProductClassificationsCount] = useState({});

    const currentYear = Utils.getYear();
    const currentMonth = Utils.getMonth();

    const urlQuery = {
        order: [['createdAt', 'ASC']],
        filter: {
            year: currentYear,
            month: currentMonth,
        }
    }
    const [query, setQuery] = useState(urlQuery);
    
    const today = new Date(Utils.getCurrentDateISO()).toISOString().split('T')[0];

    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).toLocaleDateString('en-CA');
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).toLocaleDateString('en-CA');
       
    const breadcrumbs = [ { title: t('MAIN'), active: true } ];

    useEffect(() => {
        const checkPreviousMonthRecord = async() => {
            setIisPreviousMonthRecordExist(await checkExistenceOfPreviousMonthRecord());
        };
        checkPreviousMonthRecord();
    }, [checkExistenceOfPreviousMonthRecord]);
    
    useEffect(() => {
        if (ready) {
            getCountStockProducts();
            getCountIncomes();
            getCountRequestForms();
            getCountExpenses();
            getIncomeTotals();
            getExpenseTotals();
            getProductClassificationsCount();
        }
    }, [ready, query]);

    const getCountStockProducts = () => {
        axiosPrivate.get('/count/stock-products').then((res) => {
            if (res.status == 200) setCountStockProducts(res.data);
        });
    }

    const getCountIncomes = () => {
        const query = { filter: { createdAtLb: firstDayOfMonth, createdAtUb: lastDayOfMonth }};
        axiosPrivate.get('/count/incomes', { params: query }).then((res) => {
            if (res.status == 200) setCountIncomes(res.data);
        });
    }

    const getCountExpenses = () => {
        const query = { filter: { createdAtLb: firstDayOfMonth, createdAtUb: lastDayOfMonth }};
        axiosPrivate.get('/count/expenses', { params: query }).then((res) => {
            if (res.status == 200) setCountExpenses(res.data);
        });
    }

    const getCountRequestForms = () => {
        const query = { filter: { createdAtLb: today }};
        axiosPrivate.get('/count/request-forms', { params: query }).then((res) => {
            if (res.status == 200) setCountRequestForms(res.data);
        });
    }

    const getIncomeTotals = () => {
        axiosPrivate.get('/reports/income-totals', { params: query }).then((res) => {
            if (res.status == 200) setIncomeTotals(res.data);
        });
    }

    const getExpenseTotals = () => {
        axiosPrivate.get('/reports/expense-totals', { params: query }).then((res) => {
            if (res.status == 200) setExpenseTotals(res.data);
        });
    }

    const getProductClassificationsCount = () => {
        axiosPrivate.get('/reports/product-classifications-count').then((res) => {
            if (res.status == 200) {
                const data = res.data;
                const series = [];
                const labels = [];
                data.map((item) => {
                    series.push(item.total);
                    labels.push(`${item.name} - ${item.total}`)
                });
                
                setProductClassificationsCount({series, labels});
            }
        });
    }

    return (
        <MainWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="main-page flex-column mx-5 px-5 pt-2 pb-5">
                <div className="d-flex flex-row align-items-center justify-content-between mx-3 mt-4">
                    <span className="fs-4 medium fg-dark">{t('DASHBOARD')}</span>
                    {isPreviousMonthRecordExist == false && <div className="d-flex flex-row">
                        <Link to={'/reports/general/close-monthly-report'} className="no-decor badge bg-red-400 bg-gradient font-13 p-2"><FontAwesomeIcon icon={faTriangleExclamation} className="me-1"/>{t('CLOSE_MONTHLY_REPORT')}<FontAwesomeIcon icon={faChevronRight} className="font-12 ms-2"/></Link>
                    </div>}
                </div>
                <div className="row mx-0 mt-3">
                    <CardTotal title={`${t('REMAINDER')} ${t('PRODUCTS')}`} value={countStockProducts} icon={icCube} bg={'bg-blue-200'} link={'/warehouse/stock-products/1'} leftTitle={t('TOTAL')}/>
                    <CardTotal title={t('INCOMES')} value={countIncomes} icon={icFileArrowDownAlt} bg={'bg-green-200'} link={'/warehouse/incomes'} leftTitle={t('THIS_MONTH')}/>
                    <CardTotal title={t('EXPENSES')} value={countExpenses} icon={icFileArrowUpAlt} bg={'bg-red-200'} link={'/warehouse/expenses'} leftTitle={t('THIS_MONTH')}/>
                    <CardTotal title={t('REQUEST_FORMS')} value={countRequestForms} icon={icMemoCheck} bg={'bg-yellow-200'} link={'/warehouse/request-forms'} leftTitle={`${t('TOTAL')} (${t('OPEN')})`}/>
                </div>
                <div className="d-flex flex-column mx-3 mt-5">
                    <span className="fg-dark medium font-18">{t('SHORTCUTS')}</span>
                    <div className="d-flex flex-row mt-2">
                        {isAdmin && <ButtonU onSubmit={() => navigate(`/invoice/${1}/invoices/create`)} className={'bg-orange-400 text-white'} icon={faAdd} text={t('INVOICE')}/>}
                        <ButtonU onSubmit={() => navigate('/warehouse/request-forms/create')} className={'bg-purple-400 text-white'} icon={faAdd} text={t('REQUEST_FORM')}/>
                        {!isAdmin && <ButtonU onSubmit={() => navigate('/warehouse/expenses/create-bulk')} className={'bg-red-700 text-white'} icon={faAdd} text={t('EXPENSE')}/>}
                        {isAdmin && <ButtonU onSubmit={() => navigate(`/product-catalog/${1}/products/create`)} className={'bg-blue-500 text-white'} icon={faAdd} text={t('PRODUCT')}/>}
                        {isAdmin && <ButtonU onSubmit={() => navigate(`/warehouse/warehouses`)} className={'fg-blue-500 border-primary'} icon={faList} text={t('WAREHOUSES')}/>}
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-between font-18 mx-3 mt-5 mb-2">
                    <span className="fg-dark medium">{t('STATISTICS')}</span>
                    <div className="d-flex flex-row">
                        <Link to={'/warehouse/incomes'} className="ms-2">{t('INCOMES')}</Link>
                        <Link to={'/warehouse/expenses'} className="ms-3">{t('EXPENSES')}</Link>
                    </div>
                </div>
                <div className="row mx-0 mt-2">
                    <InExAreaChart seriesTotals={incomeTotals?.totals} seriesTotals2={expenseTotals?.totals} seriesDates={incomeTotals?.dates} className={"col-md-7"}/>
                    <ProductClassificationPieChart
                        title={`${t('PRODUCT_CLASSIFICATIONS')}`}
                        palette={'palette6'}
                        series={productClassificationsCount?.series || []}
                        labels={productClassificationsCount?.labels || []}/>
                </div>
            </div>
        </MainWrapper>
    );
}

export default Dashboard;