import { ListGroup } from "react-bootstrap";
import ModalU from '../../../../../components/ui/ModalU';
import Utils from "../../../../../utils";
import { useTranslation } from "react-i18next";

const Show = ({...props}) => {
    const { t } = useTranslation();
    const item = props.item;
    
    const modelProps = [
        { label: t('COMPANY_NAME'), value: (prop) => { return(<>{prop.companyName}</>)} },
        { label: t('CONTACT_NAME'), value: (prop) => { return(<>{ prop.contactName}</>)} },
        { label: t('PHONE_NUMBER'), value: (prop) => { return(<>{prop.phoneNumber}</>)} },
        { label: `${t('PHONE_NUMBER')} ${t('WORK')}`, value: (prop) => { return(<>{prop.phoneNumberWork}</>)} },
        { label: t('FAX'), value: (prop) => { return(<>{prop.fax}</>)} },
        { label: t('EMAIL'), value: (prop) => { return(<>{prop.email}</>)} },
        { label: t('WEBSITE'), value: (prop) => { return(<>{prop.website}</>)} },
        { label: t('ADDRESS'), value: (prop) => { return(<>{prop.addressLine}</>)} },
        { label: t('REGISTRATION_DATE'), value: (prop) => { return(<>{Utils.getTimestampDate(prop.createdAt)}</>)} },
    ];

    return (
        <ModalU show={props.modalShow} size={''} title={t('SUPPLIER')} onHide={props.setModalShow}>
            <ListGroup className="my-3 small rounded-0">
                { modelProps.map((prop) => {
                    return(
                        <ListGroup.Item key={prop.label} className="d-flex align-items-center justify-content-between py-1">
                            <span className="medium fg-dark">{prop.label}</span>
                            {item.id && prop.value(item)}
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        </ModalU>
    );
}

export default Show