const SelectProductClassification = ({...props}) => {
    const items = props.items;

    const handleChange = (event) => {
        const value = event.target.value;
        props.setQuery(prev => {
            return {...prev, filter: {
                ...prev.filter, productClassificationId: value}
            }
        });
    }

    return (
        <div className="d-flex flex-row align-items-center me-1">
            <div className={`d-flex flex-column`}>
                <select
                    value={props.query.filter.productClassificationId}
                    onChange={handleChange}
                    className={`rounded-2 border border-secondary px-2 py-2 font-14 bg-light`}>
                    { items.map((item) => {
                        return (<option value={item.id} key={item.id}>{item.name}</option>)
                    }) }
                </select>
            </div>
        </div>
    );
}

export default SelectProductClassification;