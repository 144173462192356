import { ListGroup } from "react-bootstrap";
import ModalU from '../../../../../components/ui/ModalU';
import Utils from "../../../../../utils";
import { useTranslation } from "react-i18next";
import Keys from "../../../../../utils/constants";

const ShowInvoiceItem = ({...props}) => {
    const { t } = useTranslation();
    const item = props.item;
    
    const modelProps = [
        { label: t('REGISTRATION_DATE'), value: (prop) => { return(<>{Utils.getTimestampDate(prop.createdAt)}</>)} },
        { label: t('PRODUCT'), value: (prop) => { return(<>{prop.product.name}</>)} },
        { label: t('QUANTITY'), value: (prop) => { return(<>{ prop.quantity}</>)} },
        { label: t('STOCK'), value: (prop) => { return(<>{ prop.stock}</>)} },
        { label: t('UNIT'), value: (prop) => { return(<>{prop.product.unit.name}</>)} },
        { label: t('UNIT_PRICE'), value: (prop) => { return(<>{prop.unitPrice} {Keys.TMT}</>)} },
        { label: `${t('TOTAL')} ${t('UNIT_PRICE')}`, value: (prop) => { return(<>{prop.totalUnitPrice} {Keys.TMT}</>)} },
        { label: t('ISSUE_DATE'), value: (prop) => { return(<>{prop.issueDate ? Utils.getTimestampDate(prop.issueDate) : '-'}</>)} },
        { label: t('EXPIRE_DATE'), value: (prop) => { return(<>{prop.expireDate ? Utils.getTimestampDate(prop.expireDate) : '-'}</>)} },
        { label: t('DEFINITION'), value: (prop) => { return(<>{prop.description ? prop.description : '-'}</>)} },
    ];

    return (
        <ModalU show={props.modalShow} size={''} title={t('PRODUCT')} onHide={props.setModalShow}>
            <ListGroup className="my-3 small rounded-0">
                { modelProps.map((prop) => {
                    return(
                        <ListGroup.Item key={prop.label} className="d-flex align-items-center justify-content-between py-1">
                            <span className="medium fg-dark">{prop.label}</span>
                            {item.id && prop.value(item)}
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        </ModalU>
    );
}

export default ShowInvoiceItem