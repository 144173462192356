import { faCube } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import icColorSwatch from "./../../../../../../../assets/svg/ic_color_swatch.svg";

const CardCount = ({...props}) => {
    const { t } = useTranslation();
    
    const item = props.item;

    return (
        <div className="d-flex flex-row justify-content-between rounded-1 border-bottom px-3 py-1">
            <div className="d-flex flex-row">
                <img src={icColorSwatch} alt={item.name} className="wh-20 me-2"/>
                <span className="fg-dark medium font-16">{item?.productClassification?.name}</span>
            </div>
            <div className="d-flex flex-row align-items-center fg-dark medium">
                <span className="me-2">{item?.count}</span>
                <FontAwesomeIcon icon={faCube} className="font-14 fg-blue-500"/>
            </div>
        </div>
    );
}

export default CardCount;