import { useState, useEffect } from "react";
import * as XLSX from "xlsx/xlsx.mjs";
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import ModalU from '../../../../../../components/ui/ModalU';
import ToastU from "../../../../../../components/ui/ToastU";
import LinearIndeterminant from "../../../../../../components/ui/PbLinearIndeterminant";
import Utils from "../../../../../../utils";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";

const ExportFile = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(false);

    const [requestForm, setRequestForm] = useState({});

    const [query, setQuery] = useState({
        order: [['createdAt', 'DESC']], filter: { requestFormId: ''},
        attributes: {exclude: ['createdAt', 'updatedAt', 'deletedAt', 'version']}
    });

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    useEffect(() => {
        setRequestForm(props.data)
    }, [props.data]);

    useEffect(() => {
        if (requestForm && requestForm.id)
            setQuery({...query, filter: {requestFormId: requestForm.id}});
    }, [requestForm]);

    const modifyForView = () => {
        setLoading(true);
        axiosPrivate.get('/request-form-items', { params: query }).then((res) => {
            if (res.status == 200) {
                const rawData = res.data.data;
                const viewData = [];
                rawData.forEach((e) => {
                    const row = {
                        product: e.product.name,
                        barcode: e.product.barcode,
                        requestFormNumber: e.requestForm.number,
                        requestedQuantity: e.requestedQuantity,
                        quantity: e.quantity,
                        unit: e.product.unit.name,
                        unitPrice: e.unitPrice,
                        total: e.totalUnitPrice,
                        description: e.description
                    }
                    viewData.push(row);
                });

                createForView(viewData);
                props.setToast({ content: t('FILE_EXPORTED'), variant: 'primary', title: t('DONE'), show: true });
                props.setModalShow(false);
            }
        }).finally(() => { setLoading(false) });
    }

    const handleExport = () => {
        modifyForView();
    }

    const createForView = (sheetData) => {
        const Heading = [[t('PRODUCT'), t('BAR_CODE'), t('NUMBER'), t('REQUESTE_QUANTITY'), t('QUANTITY'), t('UNIT'), t('UNIT_PRICE'), t('TOTAL'), t('DEFINITION')]];
        const jsonSheet = JSON.parse(JSON.stringify(sheetData));
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(jsonSheet, { origin: 'A2', skipHeader: true });
        XLSX.utils.sheet_add_aoa(ws, Heading);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        let filename = '';
        if (requestForm && requestForm.id) filename = `request-form_${props.data.number}_view.xlsx`;
        else filename = `request-form-items_${Utils.getTimestampDate(Date.now())}_view.xlsx`
        XLSX.writeFile(wb, filename);
    }

    return (
        <ModalU show={props.modalShow}
            title={<span className="fg-orange-300"><FontAwesomeIcon icon={faFileExport} className="me-2"/>{t('EXPORT')}</span>}
            actionbtntext={t('CONFIRM')}
            actionbtnclassname={"bg-blue-700 font-14 medium text-white"}
            onSubmit={handleExport} onHide={props.setModalShow}>
            <div className="d-flex flex-column p-3">
                {requestForm ? <div className="d-flex flex-row">
                    <span className="medium text-secondary">{t('REQUEST_FORM')} {t('NUMBER')}:</span>
                    <span className="fg-dark ms-2">{requestForm.number}</span>
                </div>
                : <div className="d-flex flex-column">
                    <span className="medium text-secondary">{t('REQUEST_FORM')} {t('ITEMS')}</span>
                </div>}
                <div className="d-flex flex-row mt-2 fg-dark">
                    <span className="me-2">{t('TOTAL')} {t('PRODUCTS')}:</span>
                    <span className="bold">{props.invoiceItemsCount}</span>
                </div>
                {loading && 
                    <div className="mt-2">
                        <LinearIndeterminant color={'secondary'}/>
                    </div>
                }
            </div>
            <ToastU toast={toast} setToast={setToast}/>
        </ModalU>
    );
}

export default ExportFile