import { useNavigate } from 'react-router-dom';
import ModalU from './ModalU';
import { useTranslation } from 'react-i18next';

const Cancel = ({...props}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleSubmit = () => { navigate(-1); }

    return (
        <ModalU show={props.modalShow} size={''} title={t('WARNING')}
            actionbtntext={t('CANCEL')}
            actionbtnclassname={"bg-red-400 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <div className="d-flex flex-column align-items-center p-4">
                <span className="medium fg-red-300">{t('DATA_CANCELLETION_CONFIRMATION')}</span>
                <span className="fg-dark mt-2 text-center font-12 mx-3">{t('DATA_CANCELLETION_CONFIRMATION_DESC')}</span>
            </div>
        </ModalU>
    );
}

export default Cancel