import { useState, useEffect, useRef } from "react";
import useAuth from '../../../../../../hooks/useAuth';
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import { useNavigate, useParams } from "react-router-dom";
import { faCancel, faSave, faAdd, faFileImport } from "@fortawesome/free-solid-svg-icons";
import Utils from "../../../../../../utils";
import InvoiceWrapper from "../../InvoiceWrapper";
import ButtonU from "../../../../../../components/ui/ButtonU";
import InputLabeled from "../../../../../../components/form/InputLabeled";
import InputAreaLabeled from "../../../../../../components/form/InputAreaLabeled";
import SelectLabeled from "../../../../../../components/form/SelectLabeled";
import ImportFile from "../../components/ImportFile";
import ItemCreate from "./InvoiceItem/Create";
import FoundSuppliers from '../../components/FoundSuppliers';
import InvoiceItemsTemp from "./InvoiceItem/InvoiceItemsTemp";
import ToastU from "../../../../../../components/ui/ToastU";
import Cancel from "../../../../../../components/ui/Cancel";
import { useTranslation } from "react-i18next";
import SelectProductClassification from "./../../components/SelectProductClassification";

const Create = () => {
    const { t } = useTranslation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const params = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const productClassifications = JSON.parse(localStorage.getItem('productClassifications'));

    const supplierNameRef = useRef(null);

    const [number, setNumber] = useState(Utils.genIdByDateTime());
    const [supplierId, setSupplierId] = useState('');
    const [warehouseId, setWarehouseId] = useState('');
    const [productClassificationId, setProductClassificationId] = useState('');
    const [paymentTerms, setPaymentTerms] = useState('');
    const [paymentDetail, setPaymentDetail] = useState('');
    const [total, setTotal] = useState();
    const [paid, setPaid] = useState();
    const [closed, setClosed] = useState(false);
    const [note, setNote] = useState();

    const [supplierName, setSupplierName] = useState('');

    const [supplier, setSupplier] = useState({});

    const [importModal, setImportModal] = useState(false);
    const [invoiceItemModal, setInvoiceItemModal] = useState(false);
    const [modalCancel, setModalCancel] = useState(false);

    const [suppliers, setSuppliers] = useState([]);
    const [warehouses, setWarehouses] = useState([]);

    const [querySupplier, setQuerySupplier] = useState({
        limit:0, order: [['createdAt', 'ASC']], filter: { name: '' }
    });

    const [invoiceItemsTemp, setInvoiceItemsTemp] = useState([]);

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('MAIN'), link: '/'},
        { title: t('INVOICE'), link: `/invoice/${params.pcid}/invoices`},
        { title: t('INVOICES'), link: `/invoice/${params.pcid}/invoices` },
        { title: t('NEW'), active: true },
    ]

    const ENUM_CLOSED = [ { id: true, name: t('CLOSED')}, { id: false, name: t('OPEN')} ];

    useEffect(() => {
        if (ready) {
            getWarehouses();
            setProductClassificationId(params.pcid);
        }
    }, [ready, toast]);

    useEffect(() => {
        if (ready) getSuppliers();
    }, [querySupplier]);

    const handleSupplierInputOnFocus = () => {
        setQuerySupplier({...querySupplier, limit: 20});
    };

    const handleSupplierInputOnBlur = () => {
        setSuppliers([]);
    };

    useEffect(() => {
        if (supplierName.length < 1) {
            setSuppliers([]);
            return;
        }
        if (supplierName && supplier.companyName != supplierName)
            setQuerySupplier({...querySupplier, limit: 50, filter: { name: supplierName }});
    }, [supplierName]);

    useEffect(() => {
        if (!supplier.id) return;
        setSupplierName(supplier.companyName);
        setSupplierId(supplier.id);
        setSuppliers([]);
    }, [supplier]);

    // set invoice total payment
    useEffect(() => {
        let n = 0;
        invoiceItemsTemp.map(item => n += (parseFloat(item.quantity) * parseFloat(item.unitPrice)));
        setTotal(parseFloat(n).toFixed(2));
    }, [invoiceItemsTemp]);

    const getSuppliers = () => {
        axiosPrivate.get('/suppliers', { params: querySupplier }).then((res) => {
            if (res.status == 200) setSuppliers(res.data.data);
        });
    }
    
    const getWarehouses = () => {
        const query = {limit: 1, order: [['order', 'ASC']], filter: {name: '1000'}};
        axiosPrivate.get('/warehouses', { params: query }).then((res) => {
            if (res.status == 200) {
                setWarehouses(res.data.data);
                setWarehouseId(res.data.data[0].id);
            }
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const requiredInputs = [
            { key: number, value: t('INVOICE_NUMBER_REQUIRED') },
            { key: supplierId, value: t('SUPPLIER_REQUIRED') },
            { key: warehouseId, value: t('WAREHOUSE_REQUIRED') },
            { key: total, value: t('TOTAL_REQUIRED') },
            { key: !(invoiceItemsTemp.length < 1), value: `${t('DATA_NOT_ENTERED_YET')} (${t('INVOICE')} ${t('ITEMS')})` },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        setLoading(true);
        const data = {
            number, supplierId, warehouseId, productClassificationId, paymentTerms, paymentDetail, 
            total, paid, closed, note, items: invoiceItemsTemp };

        axiosPrivate.post('/invoices/create', data).then((res) => {
            if (res.status == 200) {
                setToast({ content: res.data.msg, variant: 'success', title: t('SUCCESS'), show: true });
                navigate(-1);
            }
        }).catch((err) => {
            if (!err?.response) {
                setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
                return;
            }
            switch(err.response.status) {
                case 406: setToast({ content: t('NOT_ALLOWED'), variant: 'danger', title: t('ERROR'), show: true }); break;
                default : setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true }); break;
            }
        }).finally(() => {setLoading(false)});
    }

    return (
        <InvoiceWrapper loading={loading} breadcrumbs={breadcrumbs}>
             <div className="head-long-page">
                <span className="fs-5 bold fg-dark">{t('NEW')} {t('INVOICE')}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={() => {setImportModal(true)}} icon={faFileImport} text={t('IMPORT')} className={"bg-pink-700 bg-gradient text-white"}/>
                    <ButtonU onSubmit={() => {setModalCancel(true)}} icon={faCancel} text={t('CANCEL')} className={"bg-red-400 bg-gradient text-white"}/>
                    <ButtonU onSubmit={handleSubmit} icon={faSave} text={t('SAVE')} className={"bg-green-600 bg-gradient text-white"}/>
                </div>
            </div>

            <div className="my-2 py-2 px-4 mt-5">
                <div className="row">
                    <SelectProductClassification items={productClassifications} value={productClassificationId} setValue={setProductClassificationId}/>
                    <InputLabeled label={t('NUMBER')} type={'text'} value={number} setValue={setNumber} hint={t('NUMBER')} className={'col-md-3'} required={true}/>
                    <SelectLabeled label={t('STATUS')} items={ENUM_CLOSED} value={closed} setValue={setClosed} className={'col-md-3'} required={true}/>
                    <SelectLabeled label={t('WAREHOUSE')} items={warehouses} value={warehouseId} setValue={setWarehouseId} className={'col-md-3'} required={true} disabled={true}/>
                    <div className="col-md-3">
                        <InputLabeled label={t('SUPPLIER')} type={'text'} value={supplierName} setValue={setSupplierName} hint={`${t('SUPPLIER')} ${t('FIND')}`} innerRef={supplierNameRef} onFocus={handleSupplierInputOnFocus} onBlur={handleSupplierInputOnBlur} required={true}/>
                        <FoundSuppliers items={suppliers} setItems={setSuppliers} setItem={setSupplier}/>
                    </div>
                    <div className="col-md-12">
                        <div className="d-flex flex-column border my-2 px-2 py-1">
                            <div className="d-flex align-items-center justify-content-between mb-1">
                                <span className="font-18 medium fg-dark">{t('PRODUCTS')} ({invoiceItemsTemp.length})</span>
                                <ButtonU onSubmit={() => {setInvoiceItemModal(true)}} icon={faAdd} className={'bg-blue-700 bg-gradient text-white'}/>
                            </div>
                            <InvoiceItemsTemp items={invoiceItemsTemp} setItems={setInvoiceItemsTemp}/>
                        </div>
                    </div>
                    <InputLabeled label={t('TOTAL')} type={'number'} value={total} setValue={setTotal} hint={'1000'} className={'col-md-3'} required={true}/>
                    <InputLabeled label={t('PAID')} type={'number'} value={paid} setValue={setPaid} hint={'500'} className={'col-md-3'}/>
                    <div className="col-md-6"></div>
                    <InputAreaLabeled label={t('PAYMENT_TERMS')} rows={3} value={paymentTerms} setValue={setPaymentTerms} hint={t('HINT_PAYMENT_TERMS')} className={'col-md-6'}/>
                    <InputAreaLabeled label={t('PAYMENT_DETAILS')} rows={3} value={paymentDetail} setValue={setPaymentDetail} hint={t('HINT_PAYMENT_DETAIL')} className={'col-md-6'}/>
                    <InputAreaLabeled label={t('NOTE')} rows={3} value={note} setValue={setNote} hint={t('NOTE')} className={'col-md-6'}/>
                </div>
            </div>
            <ImportFile
                items={invoiceItemsTemp}
                setItems={setInvoiceItemsTemp}
                modalShow={importModal}
                setModalShow={setImportModal}
                toast={toast}
                setToast={setToast}
            />
            <ItemCreate
                items={invoiceItemsTemp}
                setItems={setInvoiceItemsTemp}
                modalShow={invoiceItemModal}
                setModalShow={setInvoiceItemModal}
            />
            <Cancel modalShow={modalCancel} setModalShow={setModalCancel}/>
            <ToastU toast={toast} setToast={setToast}/>
        </InvoiceWrapper>
    );
}

export default Create;