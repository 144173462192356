import { useEffect, useState } from "react";
import useAuth from "../../../../hooks/useAuth";
import MainWrapper from "../../layouts/MainWrapper";
import SideBar from "../../../../layouts/SideBar";
import icCube from './../../../../assets/svg/ic_cube_outline.svg';

const ProductCatalogWrapper = ({...props}) => {
    const { ready } = useAuth();

    const productClassifications = JSON.parse(localStorage.getItem('productClassifications'));
    const [productCatalogLinks, setProductCatalogLinks] = useState([]);

    useEffect(() => {
        if (ready) {
            productClassifications.forEach((item) => {
                item.icon = icCube
                item.links = [];
                item.links.push(`/product-catalog/${item.id}/products`);
                item.links.push(`/product-catalog/${item.id}/products/:id`);
                item.links.push(`/product-catalog/${item.id}/products/create`);
            });

            setProductCatalogLinks(productClassifications);
        }
    }, [ready]);

    return (
        <MainWrapper loading={props.loading} breadcrumbs={props.breadcrumbs}>
            <div className="main-page">
                <SideBar items={productCatalogLinks}/>
                <div className="body-page">
                    { props.children }
                </div>
            </div>
        </MainWrapper>
    );
};

export default ProductCatalogWrapper;