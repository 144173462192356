import { Link, useLocation } from "react-router-dom"
import useAuth from "../../../hooks/useAuth";
import UserAvatar from "../../../components/ui/UserAvatar";
import { useTranslation } from "react-i18next";

const SideBar = ({...props}) => {
    const { auth } = useAuth();
    const location = useLocation();
    const { t } = useTranslation();

    const isActive = (link) => {
        return location.pathname === link;
    }

    return (
        <div className="side-bar side-bar-settings" id="side-bar">
            <div className="d-flex felx-row mb-4">
                <UserAvatar user={auth?.user} style={'bg-blue-700 text-white fs-5 wh-45'}/>
                <div className="side-item-name">
                    <div className="d-flex flex-row ms-3">
                        <span className="fg-dark bold me-2">{auth?.user?.firstName}</span>
                        <span className="medium fg-gray">({auth?.user?.username})</span>
                    </div>
                    <span className="fg-gray font-13 ms-3">{t('MY_PROFILE')}</span>
                </div>
            </div>
            { props.items.map((item) => {
                return(
                    <Link to={item.link} className={"side-item"} key={item.id}>
                        <div className={"side-link " + (!isActive(item.link) && 'collapsed')} data-bs-target={`#${item.id}`} data-bs-toggle="collapse">
                            <img src={item.icon} className="wh-15 me-2"/>
                            <span className="side-item-name">{item.name}</span>
                        </div>
                    </Link>
                )
            }) }
        </div>
    );
}

export default SideBar;