import { useEffect, useState } from "react";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import ModalU from '../../../../../components/ui/ModalU';
import Utils from "../../../../../utils";
import InputLabeled from '../../../../../components/form/InputLabeled';
import { useTranslation } from "react-i18next";

const Update = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    const item = props.item;
    
    const [companyName, setCompanyName] = useState('');
    const [contactName, setContactName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberWork, setPhoneNumberWork] = useState('');
    const [fax, setFax] = useState('');
    const [addressLine, setAddressLine] = useState('');
    const [postalCode, setPostalCode] = useState();
    const [website, setWebsite] = useState('');


    useEffect(() => {
        setCompanyName(item.companyName);
        setContactName(item.contactName);
        setEmail(item.email);
        setPhoneNumber(item.phoneNumber);
        setPhoneNumberWork(item.phoneNumberWork);
        setFax(item.fax);
        setAddressLine(item.addressLine);
        setPostalCode(item.postalCode);
        setWebsite(item.website);
    }, [item])

    const handleSubmit = (event) => {
        event.preventDefault();

        const requiredInputs = [
            { key: companyName, value: t('COMPANY_NAME_REQUIRED') },
            { key: contactName, value: t('CONTACT_NAME_REQUIRED') },
            { key: phoneNumber, value: t('PHONE_NUMBER_REQUIRED') },
            { key: addressLine, value: t('ADDRESS_LINE_REQUIRED') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            props.setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        props.setLoading(true);
        const data = { companyName, contactName, email, phoneNumber, phoneNumberWork, fax, website, addressLine, postalCode };
        axiosPrivate.put('/suppliers/update/'+item.id, data).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {props.setLoading(false)});
    }

    return (
        <ModalU show={props.modalShow} size={'lg'} title={t('SUPPLIER')}
            actionbtntext={t('UPDATE')}
            actionbtnclassname={"bg-green-700 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit} className="d-flex row">
                <InputLabeled label={t('COMPANY_NAME')} type={'text'} value={companyName} setValue={setCompanyName} hint={t('HINT_COMPANY')} className={'col-md-6'} required={true}/>
                <InputLabeled label={t('CONTACT_NAME')} type={'text'} value={contactName} setValue={setContactName} hint={t('FULLNAME')} className={'col-md-6'} required={true}/>
                <InputLabeled label={t('PHONE_NUMBER')} type={'number'} value={phoneNumber} setValue={setPhoneNumber} hint={t('HINT_PHONE_NUMBER')} className={'col-md-4'} required={true}/>
                <InputLabeled label={`${t('PHONE_NUMBER')} ${t('WORK')}`} type={'number'} value={phoneNumberWork} setValue={setPhoneNumberWork} className={'col-md-4'} hint={t('HINT_PHONE_NUMBER_WORK')}/>
                <InputLabeled label={t('FAX')} type={'number'} value={fax} setValue={setFax} hint={t('HINT_PHONE_NUMBER_WORK')} className={'col-md-4'}/>
                <InputLabeled label={t('EMAIL')} type={'text'} value={email} setValue={setEmail} hint={t('HINT_EMAIL')} className={'col-md-6'}/>
                <InputLabeled label={t('WEBSITE')} type={'text'} value={website} setValue={setWebsite} hint={t('HINT_WEBSITE')} className={'col-md-6'}/>
                <InputLabeled label={t('POSTAL_CODE')} type={'number'} value={postalCode} setValue={setPostalCode} hint={t('HINT_POSTAL_CODE')} className={'col-md-3'}/>
                <InputLabeled label={t('ADDRESS')} type={'text'} value={addressLine} setValue={setAddressLine} hint={t('HINT_ADDRESS')} className={'col-md-9'} required={true}/>
            </form>
        </ModalU>
    );
}

export default Update