import DataWrapper from "../DataWrapper";
import Option from "./../../../components/Option";
import icBranch from "../../../../../assets/svg/ic_branch.svg";
import icWarehouse from "../../../../../assets/svg/ic_warehouse.svg";
import { useTranslation } from "react-i18next";

const HR = () => {
    const { t } = useTranslation();

    const breadcrumbs = [
        { title: t('SETTINGS'), link: '/settings/profile'}, { title: t('DATABASE'), link: '/settings/data/general'}, { title: t('WAREHOUSE'), active: true }
    ]

    const options = [
        { id: 'departments', name: t('DEPARTMENTS'), icon: icBranch, link: '/settings/data/warehouse/departments'},
        { id: 'warehouses', name: t('WAREHOUSES'), icon: icWarehouse, link: '/settings/data/warehouse/warehouses'},
    ]

    return (
        <DataWrapper breadcrumbs={breadcrumbs}>
            <div className="row p-3">
                { options.map((option) => {
                    return (<Option key={option.id} item={option}/>)
                })}
            </div>
        </DataWrapper>
    );
}

export default HR;